body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {
    dd {
        margin-right: 0;
        margin-left: inherit;
    }

    blockquote {
        margin: 0 0 1rem;
    }

    figure {
        margin: 0 0 1rem;
    }

    caption {
        text-align: right;
    }

    .list-unstyled,
    .list-inline {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-inline-item:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .no-gutters {
        margin-left: 0;
        margin-right: 0;
    }

    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }

    /* ###### Accordion ###### */

    .accordion > .card:first-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .accordion > .card:last-of-type {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .main-accordion .accordion-body {
        padding: 0 20px 20px;
    }

    /* ###### Accordion ###### */

    /* ###### Alerts ###### */

    .alert .btn-close {
        left: 15px;
        right: inherit !important;
    }

    .alert-dismissible {
        padding-left: 3.8125rem;
        padding-right: inherit;
    }

    .alert-dismissible .btn-close {
        left: 15px;
        right: inherit !important;
    }

    .alert-bdleft-success {
        border-right: 5px solid #19b159;
        border-left: 1px solid rgba(25, 177, 89, 0.1);
    }

    .alert-bdleft-primary {
        border-right: 5px solid $primary;
        border-left: 1px solid $primary-transparent;
    }

    .alert-bdleft-warning {
        border-right: 5px solid #ff9b21;
        border-left: 1px solid rgba(255, 155, 33, 0.1);
    }

    .alert-bdleft-danger {
        border-right: 5px solid #fd6074;
        border-left: 1px solid rgba(253, 96, 116, 0.1);
    }

    .alert-bdleft-info {
        border-right: 5px solid #01b8ff;
        border-left: 1px solid rgba(1, 184, 255, 0.1);
    }

    .alert-bdleft-secondary {
        border-right: 5px solid #f1388b;
        border-left: 1px solid rgba(241, 56, 139, 0.1);
    }

    /* ###### Alerts ###### */

    /* ###### Badge ###### */
    .badge {
        padding: 5px 6px 4px 6px;
    }

    .rounded-pill {
        padding-right: 8px;
        padding-left: 8px;
        padding-left: 0.6em;
        padding-right: 0.6em;
    }

    /* ###### Badge ###### */

    /* ###### Breadcrumb ###### */

    .main-breadcrumbs .breadcrumb-item + .breadcrumb-item {
        padding-right: 8px;
        padding-left: inherit;
    }

    .main-breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
        padding-left: 8px;
        padding-right: inherit;
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-right: 0.5rem;
        padding-left: inherit;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }

    .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
        content: "\f3cf";
    }

    .breadcrumb-5 a {
        float: right;
        padding: 0 39px 0 10px;
    }

    .breadcrumb-5 a:first-child {
        padding-right: 46px;
        padding-left: inherit;
        border-radius: 0 5px 5px 0;
    }

    .breadcrumb-5 a:first-child:before {
        right: 14px;
        left: inherit !important;
    }

    .breadcrumb-5 a:last-child {
        border-radius: 5px 0 0 5px;
    }

    .breadcrumb-5 a:after {
        left: -18px;
        right: inherit !important;
        border-radius: 5px 0 50px 0;
    }

    .breadcrumb-4 ol {
        text-align: left;
    }

    .breadcrumb-4 li {
        text-align: left;
    }

    /* ###### Breadcrumb ###### */

    /* ###### Buttons ###### */

    .btn-group > .btn:not(:first-child),
    .sp-container .btn-group > button:not(:first-child) {
        margin-right: -1px;
        margin-left: initial;
    }

    .btn-group > .btn-group:not(:first-child) {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .btn-group > .btn:first-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }
    .sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn,
    .sp-container .btn-group > .btn-group:not(:last-child) > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-sm + .dropdown-toggle-split,
    .btn-group-sm > .btn + .dropdown-toggle-split,
    .sp-container .btn-group-sm > button + .dropdown-toggle-split {
        padding-left: 0.375rem;
        padding-right: 0.375rem;
    }

    .btn-lg + .dropdown-toggle-split,
    .btn-group-lg > .btn + .dropdown-toggle-split,
    .sp-container .btn-group-lg > button + .dropdown-toggle-split {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .sp-container .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle),
    .btn-group-vertical > .btn-group:not(:last-child) > .btn,
    .sp-container .btn-group-vertical > .btn-group:not(:last-child) > button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-group-vertical > .btn:not(:first-child),
    .sp-container .btn-group-vertical > button:not(:first-child),
    .btn-group-vertical > .btn-group:not(:first-child) > .btn,
    .sp-container .btn-group-vertical > .btn-group:not(:first-child) > button {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .btn-with-icon i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .btn-icon-list .btn + .btn,
    .btn-icon-list .sp-container button + .btn {
        margin-right: 5px;
        margin-left: inherit;
    }

    .sp-container .btn-icon-list button + .btn,
    .btn-icon-list .sp-container .btn + button,
    .sp-container .btn-icon-list .btn + button,
    .btn-icon-list .sp-container button + button,
    .sp-container .btn-icon-list button + button {
        margin-right: 5px;
        margin-left: inherit;
    }

    .btn-list > .btn:not(:last-child),
    .btn-list > .dropdown:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .btn-animation .btn-loaders {
        padding-left: 46px;
        padding-right: 0.95rem;
    }

    .btn-animation .btn-loaders:after {
        border-right-color: transparent !important;
        left: 13px !important;
        right: inherit !important;
    }

    /* ###### Buttons ###### */

    /* ###### Card  ###### */

    .card > hr {
        margin-left: 0;
        margin-right: 0;
    }

    .card > .list-group:first-child .list-group-item:first-child {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .card > .list-group:last-child .list-group-item:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    // .card-link+.card-link {
    //     margin-right: 1.25rem;
    //     margin-left: inherit;
    // }

    .card-header {
        padding: 20px 25px 0 25px;
    }

    .card-header:first-child {
        border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
        border-radius: 11px 11px 0 0;
    }

    .card-footer:last-child {
        border-radius: 0 0 11px 11px;
    }

    .card-header-tabs {
        margin-left: -0.625rem;
        margin-right: -0.625rem;
    }

    .card-header-pills {
        margin-left: -0.625rem;
        margin-right: -0.625rem;
    }

    .card-img-overlay {
        left: 0;
        right: 0;
    }

    .card-img-top {
        border-top-right-radius: 11px;
        border-top-left-radius: 11px;
    }

    .card-img-bottom {
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
    }

    @media (min-width: 576px) {
        .card-deck {
            margin-left: -15px;
            margin-right: -15px;
        }

        .card-deck .card {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    @media (min-width: 576px) {
        .card-group > .card + .card {
            margin-right: 0;
            margin-left: inherit;
            border-right: 0;
            border-left: inherit !important;
        }

        .card-group > .card:not(:last-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
            border-top-left-radius: 0;
        }

        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
            border-bottom-left-radius: 0;
        }

        .card-group > .card:not(:first-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
            border-top-right-radius: 0;
        }

        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
            border-bottom-right-radius: 0;
        }
    }

    .card-header,
    .card-footer {
        padding-right: 25px;
        padding-left: 25px;
    }

    .card-item .card-item-icon {
        left: 20px;
        right: inherit !important;
    }

    .card-dashboard-twentyfour .card-header {
        padding: 20px 20px 0;
    }

    .card-dashboard-twentyfour .card-body {
        padding: 15px 20px 20px;
    }

    .card-dashboard-twentyfour .card-body-top > div + div {
        margin-right: 30px;
        margin-left: inherit;
    }

    .card-dashboard-twentyfour .chart-legend span {
        margin-left: 5px;
        margin-right: inherit;
    }

    .card-dashboard-twentyfour .chart-legend > div + div {
        margin-right: 20px;
        margin-left: inherit;
    }

    .card-dashboard-twentyfour .chart-wrapper {
        margin-left: -10px;
        margin-right: inherit;
    }

    .card-dashboard-twentyfive .col + .col {
        border-right: 1px solid $border;
        border-left: inherit !important;
    }

    .card-dashboard-twentyfive .chart-wrapper {
        margin: 0 -8px -5px;
    }

    .card-dashboard-twentysix .card-header {
        padding: 15px 15px 10px;
    }

    .card-dashboard-twentysix .chart-legend > div + div {
        margin-right: 15px;
        margin-left: inherit;
    }

    .card-dashboard-twentysix .chart-legend span {
        margin-left: 2px;
        margin-right: inherit;
    }

    .card-dashboard-twentysix .chart-wrapper {
        margin: -30px -18px -15px -18px;
    }

    .card-table .badge {
        padding: 9px 13px 9px;
    }

    .card-options {
        margin-right: auto;
        margin-left: -0.5rem;
    }

    .card-options a:not(.btn) {
        margin-right: 0;
        margin-left: inherit;
    }

    .card-fullscreen {
        right: 0;
        left: 0;
    }

    .card-blog-overlay:before {
        left: 0;
        right: inherit !important;
    }

    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        left: 0;
        right: inherit !important;
    }

    @media (max-width: 575px) {
        .card-box img {
            right: -14px !important;
            left: inherit !important;
        }
    }

    /* ###### Card  ###### */

    /* ###### carousel ###### */

    .carousel-item {
        float: right;
        margin-left: -100%;
        margin-right: inherit;
    }

    .carousel-control-prev {
        right: 0;
        left: inherit !important;
    }

    .carousel-control-next {
        left: 0;
        right: inherit !important;
    }

    .carousel-indicators {
        padding-right: 0;
        padding-left: inherit;
        margin-left: 15%;
        margin-right: 15%;
    }

    .carousel-indicators li {
        margin-left: 3px;
        margin-right: 3px;
    }

    .carousel-caption {
        left: 15%;
        right: 15%;
    }

    .carousel-slider #thumbcarousel {
        margin: 10px 0 0 0;
    }

    #thumbcarousel .carousel-control-prev {
        right: 0;
        left: inherit !important;
    }

    #thumbcarousel .carousel-control-next {
        left: 0;
        right: inherit !important;
    }

    .owl-nav .owl-prev {
        right: -25px;
        left: -1.5em;
    }

    .owl-nav .owl-next {
        left: -25px;
        right: inherit !important;
    }

    .owl-nav button:before {
        right: 0;
        left: 0;
    }

    .owl-theme .owl-dots {
        right: 0;
        left: 0;
    }

    /* ###### carousel ###### */

    /* ########## Dropdown ########## */

    .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropdown-menu-left {
        left: auto;
        right: 0;
    }

    .dropdown-menu-right {
        left: 0;
        right: auto;
    }

    @media (min-width: 576px) {
        .dropdown-menu-sm-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-sm-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 768px) {
        .dropdown-menu-md-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-md-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 992px) {
        .dropdown-menu-lg-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-lg-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 1200px) {
        .dropdown-menu-xl-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-xl-right {
            left: 0;
            right: auto;
        }
    }

    .dropup .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
        border-left: 0.3em solid transparent;
        border-right: 0.3em solid transparent;
    }

    .dropup .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropend .dropdown-menu {
        left: auto;
        right: 100%;
        margin-right: 0.125rem;
        margin-left: inherit;
    }

    .dropend .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
        border-left: 0;
        border-right: 0.3em solid;
    }

    .dropend .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropstart .dropdown-menu {
        left: 100%;
        right: auto;
        margin-left: 0.125rem;
        margin-right: inherit;
    }

    .dropstart .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropstart .dropdown-toggle::before {
        margin-left: 0.255em;
        margin-right: inherit;
        border-left: 0.3em solid;
        border-right: inherit !important;
    }

    .dropstart .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropdown-header {
        padding-right: 15px;
        padding-left: 15px;
    }

    .dropdown-menu .main-header-center .select2-container--default .select2-selection--single {
        border-radius: 0 6px 6px 0;
    }

    .dropdown-menu .main-header-center .search-btn {
        border-radius: 6px 0 0 6px;
    }

    .dropdown-menu .main-form-search .btn,
    .dropdown-menu .main-form-search .sp-container button {
        padding: 0.375rem 0.7rem 0.4rem 0.85rem;
        border-radius: 6px 0 0 6px;
    }

    .dropdown-menu .sp-container .main-form-search button {
        padding: 0.375rem 0.7rem 0.4rem 0.85rem;
        border-radius: 6px 0 0 6px;
    }

    .dropdown-toggle-split {
        padding-left: 0.5625rem;
        padding-right: 0.5625rem;
    }

    .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropup .dropdown-toggle-split::after,
    .dropend .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropstart .dropdown-toggle-split::before {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropdown.header-message .nav-link.icon {
        font-size: 18px;
    }

    .dropdown-menu {
        float: right;
        // margin: 0.125rem 0 0 !important;
        text-align: right;
    }

    .dropdown-menu[x-placement^="top"],
    .dropdown-menu[x-placement^="right"],
    .dropdown-menu[x-placement^="bottom"],
    .dropdown-menu[x-placement^="left"] {
        left: auto;
        right: inherit !important;
    }

    /* ########## Dropdown ########## */

    /* ###### Form-elements ###### */

    .form-control-plaintext.form-control-sm,
    .form-control-plaintext.form-control-lg {
        padding-left: 0;
        padding-right: 0;
    }

    .form-row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .form-row > .col,
    .form-row > [class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
    }

    .form-check {
        padding-right: 1.25rem;
        padding-left: inherit;
    }

    .form-check-input {
        margin-right: -1.25rem;
        margin-left: inherit;
    }

    .form-check-inline {
        padding-right: 0;
        padding-left: inherit;
        margin-left: 0.75rem;
        margin-right: inherit;
    }

    .form-check-inline .form-check-input {
        margin-left: 0.3125rem;
        margin-right: 0;
    }

    @media (min-width: 576px) {
        .form-inline .form-check {
            padding-right: 0;
            padding-left: inherit;
        }

        .form-inline .form-check-input {
            margin-left: 0.25rem;
            margin-right: 0;
        }
    }

    .file-browser .btn-default {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid,
    input.form-control.parsley-success,
    textarea.form-control.parsley-success {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: 0.75rem;
        background-position: center left calc(0.375em + 0.1875rem);
    }

    .was-validated textarea.form-control:valid {
        padding-left: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
    }

    textarea.form-control.is-valid,
    textarea.form-control.parsley-success {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: inherit;
    }

    .was-validated .form-select:valid,
    .form-select.is-valid,
    input.form-select.parsley-success,
    textarea.form-select.parsley-success {
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        padding-right: 0.75rem;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    input.form-control.parsley-error,
    textarea.form-control.parsley-error {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: 0.75rem;
        background-position: center left calc(0.375em + 0.1875rem);
    }

    .was-validated textarea.form-control:invalid {
        padding-left: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
    }

    textarea.form-control.is-invalid,
    textarea.form-control.parsley-error {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: inherit;
    }

    .was-validated .form-select:invalid,
    .form-select.is-invalid,
    input.form-select.parsley-error,
    textarea.form-select.parsley-error {
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        padding-right: inherit;
    }

    select.form-control.select-sm {
        padding: 0px 10px 1px 10px !important;
    }

    /* ###### Form-elements ###### */

    /* ###### Grid ###### */
    .row-sm {
        margin-right: -10px;
        margin-left: -10px;
    }

    .row-sm > div {
        padding-right: 10px;
        padding-left: 10px;
    }

    .row-xs {
        margin-right: -5px;
        margin-left: -5px;
    }

    .row-xs > div {
        padding-right: 5px;
        padding-left: 5px;
    }

    @media (min-width: 576px) {
        .row-xs--sm {
            margin-right: -5px;
            margin-left: -5px;
        }

        .row-xs--sm > div {
            padding-right: 5px;
            padding-left: 5px;
        }

        .row-sm--sm {
            margin-right: -10px;
            margin-left: -10px;
        }

        .row-sm--sm > div {
            padding-right: 10px;
            padding-left: 10px;
        }

        .row--sm {
            margin-right: -15px;
            margin-left: -15px;
        }

        .row--sm > div {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 768px) {
        .row-xs--md {
            margin-right: -5px;
            margin-left: -5px;
        }

        .row-xs--md > div {
            padding-right: 5px;
            padding-left: 5px;
        }

        .row-sm--md {
            margin-right: -10px;
            margin-left: -10px;
        }

        .row-sm--md > div {
            padding-right: 10px;
            padding-left: 10px;
        }

        .row--md {
            margin-right: -15px;
            margin-left: -15px;
        }

        .row--md > div {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 992px) {
        .row-xs--lg {
            margin-right: -5px;
            margin-left: -5px;
        }

        .row-xs--lg > div {
            padding-right: 5px;
            padding-left: 5px;
        }

        .row-sm--lg {
            margin-right: -10px;
            margin-left: -10px;
        }

        .row-sm--lg > div {
            padding-right: 10px;
            padding-left: 10px;
        }

        .row--lg {
            margin-right: -15px;
            margin-left: -15px;
        }

        .row--lg > div {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 1200px) {
        .row-xs--xl {
            margin-right: -5px;
            margin-left: -5px;
        }

        .row-xs--xl > div {
            padding-right: 5px;
            padding-left: 5px;
        }

        .row-sm--xl {
            margin-right: -10px;
            margin-left: -10px;
        }

        .row-sm--xl > div {
            padding-right: 10px;
            padding-left: 10px;
        }

        .row--xl {
            margin-right: -15px;
            margin-left: -15px;
        }

        .row--xl > div {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    /* ###### Grid ###### */

    /* ###### Input-group ###### */

    .input-group > .form-control + .form-control,
    .input-group > .form-control + .form-select,
    .input-group > .form-control + .custom-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .form-select,
    .input-group > .form-control-plaintext + .custom-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group > .form-select + .form-control,
    .input-group > .form-select + .form-select,
    .input-group > .form-select + .custom-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .form-select,
    .input-group > .custom-file + .custom-file {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group > .form-control:not(:last-child),
    .input-group > .form-select:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .form-control:not(:first-child),
    .input-group > .form-select:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .input-group > .custom-file:not(:last-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-prepend .btn + .btn,
    .input-group-prepend .sp-container button + .btn {
        margin-right: -1px;
        margin-left: inherit;
    }

    .sp-container .input-group-prepend button + .btn,
    .input-group-prepend .sp-container .btn + button,
    .sp-container .input-group-prepend .btn + button,
    .input-group-prepend .sp-container button + button,
    .sp-container .input-group-prepend button + button {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-prepend .btn + .input-group-text,
    .input-group-prepend .sp-container button + .input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .sp-container .input-group-prepend button + .input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-prepend .input-group-text + .input-group-text,
    .input-group-prepend .input-group-text + .btn {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-prepend .sp-container .input-group-text + button {
        margin-right: -1px;
        margin-left: inherit;
    }

    .sp-container .input-group-prepend .input-group-text + button {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-append .btn + .btn,
    .input-group-append .sp-container button + .btn {
        margin-right: -1px;
        margin-left: inherit;
    }

    .sp-container .input-group-append button + .btn,
    .input-group-append .sp-container .btn + button,
    .sp-container .input-group-append .btn + button,
    .input-group-append .sp-container button + button,
    .sp-container .input-group-append button + button {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-append .btn + .input-group-text,
    .input-group-append .sp-container button + .input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .sp-container .input-group-append button + .input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-append .input-group-text + .input-group-text,
    .input-group-append .input-group-text + .btn {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-append .sp-container .input-group-text + button {
        margin-right: -1px;
        margin-left: inherit;
    }

    .sp-container .input-group-append .input-group-text + button {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-prepend {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-append {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-lg > .form-select,
    .input-group-sm > .form-select {
        padding-left: 1.75rem;
        padding-right: inherit;
    }

    .input-group > .input-group-prepend > .btn,
    .sp-container .input-group > .input-group-prepend > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .sp-container .input-group > .input-group-append:not(:last-child) > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .sp-container .input-group > .input-group-append:last-child > button:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-append > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .sp-container .input-group > .input-group-append > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .sp-container .input-group > .input-group-prepend:not(:first-child) > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .sp-container .input-group > .input-group-prepend:first-child > button:not(:first-child),
    .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group input-group-btn {
        border-radius: 6px 0 0 6px !important;
    }

    .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-right: 0;
        margin-left: inherit;
    }

    /* ###### Input-group ###### */

    /* ###### Modal ###### */
    .modal-open {
        padding-left: 0px !important;
        padding-right: inherit;
    }

    .modal-open .modal {
        padding-left: 0px !important;
        padding-right: inherit;
    }

    .btn-close {
        float: left;
    }

    .modal {
        right: 0;
        left: inherit !important;
    }

    .modal-backdrop {
        right: 0;
        left: inherit !important;
    }

    .modal-header {
        border-top-right-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
    }

    .modal-footer {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .modal-footer > :not(:first-child) {
        margin-right: 0.25rem;
        margin-left: inherit;
    }

    .modal-footer > :not(:last-child) {
        margin-left: 0.25rem;
        margin-right: inherit;
    }

    /* ###### Modal ###### */

    /* ###### Navigation   ###### */

    @media (min-width: 768px) {
        .main-nav .nav-link + .nav-link {
            padding-right: 15px;
            padding-left: inherit;
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    .main-nav-column .nav-link i {
        margin-left: 12px;
        margin-right: inherit;
    }

    .main-nav-column .nav-link span {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-nav-column .nav-link.active::before {
        right: -28px;
        left: inherit !important;
    }

    @media (min-width: 769px) {
        .main-nav-line .nav-link + .nav-link {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (max-width: 768px) {
        .main-nav-line .nav-link + .nav-link {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .main-nav-line .nav-link.active::before {
            right: 0;
            left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-nav-line-chat {
            padding-right: 0;
            padding-left: inherit;
        }

        .nav.main-nav-line-chat {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    .main-mail-search button.btn.btn-primary {
        border-radius: 6px 0px 0px 6px;
    }

    /* ###### Navigation   ###### */

    /* ###### Pagination  ###### */

    .pagination {
        padding-right: 0;
        padding-left: inherit;
    }

    .page-link {
        margin-right: -1px;
        margin-left: inherit;
    }

    .page-item:first-child .page-link {
        border-radius: 0 6px 6px 0;
    }

    .page-item:last-child .page-link {
        border-radius: 6px 0 0 6px;
    }

    .pagination-circled .page-item + .page-item {
        margin-right: 5px;
        margin-left: inherit;
    }

    .pagination-radius li a,
    .pagination-radius li span {
        margin-right: 4px;
        margin-left: inherit;
    }

    /* ###### Pagination  ###### */

    /* ###### Popover  ###### */
    .popover {
        text-align: right;
    }

    .popover-header {
        border-top-right-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
    }

    .popover-head-primary.bs-popover-start .popover-arrow,
    .popover-head-primary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
        left: -7px;
        right: inherit !important;
    }

    .popover-head-primary.bs-popover-end .popover-arrow,
    .popover-head-primary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
        right: -7px;
        left: inherit !important;
    }
    .popover-head-secondary.bs-popover-start .popover-arrow,
    .popover-head-secondary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
        left: -7px;
        right: inherit !important;
    }

    .popover-head-secondary.bs-popover-end .popover-arrow,
    .popover-head-secondary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
        right: -7px;
        left: inherit !important;
    }

    .popover-head-success.bs-popover-start .popover-arrow,
    .popover-head-success.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
        left: -7px;
        right: inherit !important;
    }

    .popover-head-success.bs-popover-end .popover-arrow,
    .popover-head-success.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
        right: -7px;
        left: inherit !important;
    }

    .popover-head-info.bs-popover-start .popover-arrow,
    .popover-head-info.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
        left: -7px;
        right: inherit !important;
    }

    .popover-head-info.bs-popover-end .popover-arrow,
    .popover-head-info.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
        right: -7px;
        left: inherit !important;
    }

    .popover-head-primary .popover-body,
    .popover-head-secondary .popover-body,
    .popover-head-success .popover-body,
    .popover-head-info .popover-body {
        border-bottom-left-radius: 2px;

        .popover-primary .popover-header {
            padding: 0 0 15px;
        }

        .popover-secondary .popover-header {
            padding: 0 0 15px;
        }

        .popover-secondary.bs-popover-start .popover-arrow::after,
        .popover-secondary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: #f1388b;
        }

        .popover-secondary.bs-popover-end .popover-arrow::after,
        .popover-secondary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: #f1388b;
        }

        .popover-success .popover-header {
            padding: 0 0 15px;
        }

        .popover-success.bs-popover-start .popover-arrow::after,
        .popover-success.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: #19b159;
        }

        .popover-success.bs-popover-end .popover-arrow::after,
        .popover-success.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: #19b159;
        }

        .popover-info .popover-header {
            padding: 0 0 15px;
        }

        .popover-info.bs-popover-start .popover-arrow::after,
        .popover-info.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: #01b8ff;
        }

        .popover-info.bs-popover-end .popover-arrow::after,
        .popover-info.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: #01b8ff;
        }

        .popover-static-demo .bs-popover-top .popover-arrow,
        .popover-static-demo .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
        .popover-static-demo .bs-popover-bottom .popover-arrow,
        .popover-static-demo .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
            right: 50%;
            left: inherit !important;
            margin-right: -5px;
            margin-left: inherit;
        }

        .bs-popover-top > .popover-arrow::before,
        .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
            border-width: 0.5rem 0.5rem 0;
        }

        .bs-popover-top > .popover-arrow::after,
        .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
            border-width: 0.5rem 0.5rem 0;
        }

        .bs-popover-end,
        .bs-popover-auto[data-popper-placement^="right"] {
            margin-right: 0.5rem;
            margin-left: inherit;
        }

        .bs-popover-end > .popover-arrow,
        .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
            right: calc((0.5rem + 1px) * -1);
            left: inherit !important;
        }

        .bs-popover-end > .popover-arrow::before,
        .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
            right: 0;
            left: inherit !important;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-right-color: #f7f6f9;
        }

        .bs-popover-end > .popover-arrow::after,
        .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
            right: 1px;
            left: inherit !important;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-right-color: #ffffff;
        }

        .bs-popover-bottom > .popover-arrow::before,
        .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
            border-width: 0 0.5rem 0.5rem 0.5rem;
        }

        .bs-popover-bottom > .popover-arrow::after,
        .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
            border-width: 0 0.5rem 0.5rem 0.5rem;
        }

        .bs-popover-bottom .popover-header::before,
        .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
            right: 50%;
            left: inherit !important;
            margin-right: -0.5rem;
            margin-left: inherit;
        }

        .bs-popover-start,
        .bs-popover-auto[data-popper-placement^="left"] {
            margin-left: 0.5rem;
            margin-right: inherit;
        }

        .bs-popover-start > .popover-arrow,
        .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
            left: calc((0.5rem + 1px) * -1);
            right: inherit !important;
        }

        .bs-popover-start > .popover-arrow::before,
        .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
            left: 0;
            right: inherit !important;
            border-width: 0.5rem 0.5rem 0.5rem 0;
            border-left-color: #f7f6f9;
        }

        .bs-popover-start > .popover-arrow::after,
        .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
            left: 1px;
            right: inherit !important;
            border-width: 0.5rem 0.5rem 0.5rem 0;
            border-left-color: #ffffff;
        }

        /* ###### Popover  ###### */

        /* ###### Datatables ###### */

        .table.dataTable th,
        .table.dataTable td {
            border-right: 1px solid $border;
            border-left: inherit !important;
        }

        div.dataTables_wrapper div.dataTables_length select {
            margin-left: 10px;
            margin-right: inherit;
        }

        /* ######  Datatables ###### */

        /* ###### Toast ###### */

        .toast-header {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        /* ###### Toast ###### */

        /* ###### Tooltip  ###### */
        text-align: right;
        text-align: end;
    }

    .tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
    .tooltip-primary.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        border-left-color: $primary;
    }

    .tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
    .tooltip-primary.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        border-right-color: $primary;
    }

    .tooltip-secondary.bs-tooltip-start .tooltip-arrow::before,
    .tooltip-secondary.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        border-left-color: #f1388b;
    }

    .tooltip-secondary.bs-tooltip-end .tooltip-arrow::before,
    .tooltip-secondary.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        border-right-color: #f1388b;
    }

    .tooltip-info.bs-tooltip-start .tooltip-arrow::before,
    .tooltip-info.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        border-left-color: #01b8ff;
    }

    .tooltip-info.bs-tooltip-end .tooltip-arrow::before,
    .tooltip-info.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        border-right-color: #01b8ff;
    }

    .tooltip-danger.bs-tooltip-start .tooltip-arrow::before,
    .tooltip-danger.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        border-left-color: #fd6074;
    }

    .tooltip-danger.bs-tooltip-end .tooltip-arrow::before,
    .tooltip-danger.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        border-right-color: #fd6074;
    }

    .tooltip-success.bs-tooltip-start .tooltip-arrow::before,
    .tooltip-success.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        border-left-color: #19b159;
    }

    .tooltip-success.bs-tooltip-end .tooltip-arrow::before,
    .tooltip-success.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        border-right-color: #19b159;
    }

    .tooltip-warning.bs-tooltip-start .tooltip-arrow::before,
    .tooltip-warning.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        border-left-color: #ff9b21;
    }

    .tooltip-warning.bs-tooltip-end .tooltip-arrow::before,
    .tooltip-warning.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        border-right-color: #ff9b21;
    }

    .bs-tooltip-top .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
        border-width: 0.4rem 0.4rem 0;
    }

    // .bs-tooltip-end .tooltip-arrow,
    // .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    //     right: 0;
    //     left: inherit !important;
    // }

    // .bs-tooltip-end .tooltip-arrow::before,
    // .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     left: -1px;
    //     right: inherit !important;
    //     border-width: 0.4rem 0 0.4rem 0.4rem;
    //     border-right-color: #000000;
    // }

    .bs-tooltip-bottom .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
        border-width: 0 0.4rem 0.4rem;
    }

    // .bs-tooltip-start .tooltip-arrow,
    // .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    //     left: 0;
    //     right: inherit !important;
    // }

    // .bs-tooltip-start .tooltip-arrow::before,
    // .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     right: -1px;
    //     left: inherit !important;
    //     border-width: 0.4rem 0.4rem 0.4rem 0;
    //     border-left-color: #000000;
    // }

    .tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
    .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
    .tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
    .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
        right: 50%;
        left: inherit !important;
        margin-right: -2px;
        margin-left: inherit;
    }

    /* ###### Tooltip  ###### */

    /* ########## LAYOUTS  ########## */
    /* ###### Main-content  ###### */

    .main-content-dashboard-ten .main-header {
        right: 0;
        left: 0;
    }

    .main-content-dashboard-ten .main-header-center {
        margin-right: 0;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-content-dashboard-ten {
            margin-right: 64px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-dashboard-ten .main-header {
            right: 64px;
            left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .main-content-dashboard-ten .main-header .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .main-content .container,
    .main-content .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }

    @media (max-width: 991.98px) {
        .main-content-left-show .main-content-left {
            right: 0;
            left: inherit !important;
            border-left: 1px solid $border;
            border-right: inherit !important;
        }
    }

    .main-content-right {
        padding-right: 25px;
        padding-left: inherit;
        margin-right: 25px;
        margin-left: inherit;
    }

    .main-content-choose-demo .card figcaption {
        right: 0;
        left: 0;
    }

    .main-sidebar-body .nav-header ul {
        padding-right: 0;
        padding-left: inherit;
    }

    /* ###### Main-content  ###### */

    /* ###### Main-header  ###### */

    @media (max-width: 991.98px) {
        .main-header-menu-show .main-header-menu {
            border-left: 1px solid rgba(28, 39, 60, 0.08);
            border-right: inherit !important;
        }
    }

    .main-header > .container,
    .main-header > .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }

    @media (min-width: 992px) {
        .main-header > .container,
        .main-header > .container-fluid {
            padding-right: 25px;
            padding-left: 15px;
        }
    }

    @media (min-width: 992px) {
        .main-header > .container {
            padding: 0;
        }
    }

    .main-logo {
        align-items: right;
        justify-content: right;
    }

    .main-logo i {
        margin-left: 8px;
        margin-right: inherit;
    }

    .main-header-menu-icon {
        margin-left: 10px;
        margin-right: inherit;
    }

    .main-header-menu-icon span::before,
    .main-header-menu-icon span::after {
        right: 0;
        left: inherit !important;
    }

    .main-header-menu {
        right: 0;
        left: inherit !important;
    }

    .main-header-menu .nav-item::before {
        margin-right: 25px;
        margin-left: inherit;
        border-right: 2px solid rgba(28, 39, 60, 0.12);
        border-left: inherit !important;
    }

    .main-header-menu .nav-item > .nav-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-header-menu .nav-item > .nav-link .typcn {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-header-menu .nav-item.active > .nav-link::before {
        right: -20px;
        left: inherit !important;
    }

    @media (min-width: 992px) {
        .main-header-menu {
            border-left: 0;
            border-right: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item + .nav-item {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item > .nav-link.with-sub::after {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item > .nav-link .typcn {
            margin-left: 7px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item.active > .nav-link::before {
            right: 0;
            left: 0;
        }
    }

    .main-header-center {
        margin: 0 0.8rem 0 0;
    }

    .main-header-center .search-panel .dropdown-toggle {
        border-radius: 0 6px 6px 0;
    }

    .main-header-center .search-btn {
        padding: 0.375rem 0.7rem 0.375rem 0.7rem;
        border-radius: 6px 0 0 6px;
    }

    .main-header-center .select2-container--default .select2-selection--single {
        border-radius: 0 6px 6px 0;
    }

    .main-header-right .btn-social + .btn-social {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-header-right .btn-buy {
        margin-right: 20px;
        margin-left: inherit;
    }

    .main-header-search-link {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-header-message > a.new::before {
        left: 2px;
        right: inherit !important;
    }

    .main-header-notification > a.new::before {
        left: 2px;
        right: inherit !important;
    }

    .main-header-notification .dropdown-menu {
        right: auto;
        left: -10px !important;
    }

    .main-header-message .dropdown-menu {
        right: auto !important;
        left: -10px !important;
    }

    .main-header-sticky {
        right: 0;
        left: 0;
    }

    /* ###### Main-header  ###### */

    /* ###### Main-header-nav  ###### */

    .main-menu-sub {
        margin-right: 32px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-menu-sub {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .main-menu-sub-mega {
        right: 0;
        left: 0;
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .container > div + div {
            border-right: 1px solid rgba(28, 39, 60, 0.05);
            border-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .nav + .nav {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-menu-sub-mega .nav + .nav {
            margin-right: 25px;
            margin-left: inherit;
        }
    }

    .header-navheading {
        padding: 20px 20px 15px;
    }

    .main-header-notification.show .dropdown-menu::before,
    .main-header-message.show .dropdown-menu::before,
    .main-profile-menu.show .dropdown-menu::before {
        left: 21px;
        right: inherit !important;
    }

    .header-search.show .dropdown-menu::before {
        left: 21px;
        right: inherit !important;
        border-right: 1px solid #f0f2f8;
        border-left: inherit !important;
    }

    .header-search .dropdown-menu {
        right: auto;
        left: -10px;
    }

    .main-form-search .btn,
    .main-form-search .sp-container button {
        right: 8px;
        left: inherit !important;
        padding-right: 16px;
        padding-left: inherit;
    }

    .sp-container .main-form-search button {
        right: 8px;
        left: inherit !important;
        padding-right: 16px;
        padding-left: inherit;
    }

    .main-message-list .media {
        padding: 10px 15px 0px 10px;
    }

    .main-header-flags .dropdown-menu {
        left: auto;
        right: -10px;
    }

    .main-header-flags.show .dropdown-menu::before {
        right: 28px;
        left: inherit !important;
        border-right: 1px solid #f0f2f8;
        border-left: inherit !important;
    }

    .main-flags-list .media {
        padding: 10px 15px 0px 10px;
    }

    .hor-header.hor-top-header .nav-link-badge {
        right: 11px;
        left: inherit !important;
    }

    @media (max-width: 991px) {
        .hor-header.hor-top-header .main-logo {
            right: 0px;
            left: inherit !important;
            margin-left: auto;
            margin-right: inherit;
        }
    }

    .main-header-right .nav-link .badge {
        left: 1px;
        right: inherit !important;
    }

    .main-header-center .btn {
        border-radius: 6px 0 0 6px !important;
    }

    .main-content-left-chat .btn {
        border-radius: 6px 0 0 6px !important;
    }

    @media (min-width: 992px) {
        .main-header-center {
            margin: 0 2rem 0 0;
        }
    }

    /* ###### Main-header-nav  ###### */
    @media (min-width: 992px) {
        .main-navbar .nav-sub-link.sub-with-sub::after {
            margin-left: 22px;
            content: "\f3cf";
        }
    }

    .main-navbar .nav-sub-link.sub-with-sub::after {
        margin-right: auto;
    }

    .main-navbar .nav-sub-item > .sub-nav-sub {
        right: 163px;
        left: inherit !important;
        padding: 0 12px 0 0;
    }

    @media (min-width: 992px) {
        .main-navbar .sub-nav-sub {
            right: 0;
            left: inherit !important;
        }
    }

    @media (min-width: 992px) and (min-width: 992px) {
        &.horizontalmenu .hor-menu .nav .nav-item {
            padding-left: 10px;
            padding-right: inherit;
        }
    }

    @media (min-width: 992px) and (min-width: 1200px) {
        &.horizontalmenu .hor-menu .nav .nav-item + .nav-item {
            padding-left: 10px;
            padding-right: inherit;
        }
    }

    @media (min-width: 992px) {
        &.horizontalmenu .hor-menu li.nav-item.active .nav-link {
            margin-right: 0;
            margin-left: inherit;
            border-left: 0px;
            border-right: inherit !important;
            padding-left: 12px;
            padding-right: inherit;
        }

        &.horizontalmenu .hor-menu li.active .hor-icon {
            margin-left: 5px;
            margin-right: 10px;
        }

        &.horizontalmenu .hor-menu .nav-link .hor-icon {
            margin-left: 5px;
            margin-right: 10px;
        }

        &.horizontalmenu .main-navbar .nav-sub-item {
            margin-right: 0;
            margin-left: inherit;
        }

        &.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
            right: 35%;
            left: inherit !important;
            border-left-color: $border;
        }
    }

    @media (max-width: 991px) {
        &.horizontalmenu.main-navbar-show .main-navbar {
            border-left: 1px solid $border;
            border-right: inherit !important;
        }

        &.horizontalmenu.main-navbar-show .main-navbar .nav-label {
            padding: 10px 0px 0 0;
        }

        &.horizontalmenu.main-navbar-show .main-navbar .nav {
            padding: 0 15px 0 15px;
        }

        &.horizontalmenu.main-navbar-show .hor-menu li.nav-item.active .nav-link {
            margin-right: 0;
            margin-left: inherit;
            border-left: 0px;
            border-right: inherit !important;
        }

        &.horizontalmenu.main-navbar-show .hor-menu li.active .hor-icon {
            margin-left: 10px;
            margin-right: 10px;
        }

        &.horizontalmenu.main-navbar-show .hor-menu .nav-link .hor-icon {
            margin-left: 10px;
            margin-right: 10px;
        }

        &.horizontalmenu.main-navbar-show .main-navbar i.angle {
            left: 20px;
            right: inherit !important;
        }

        &.horizontalmenu.main-navbar-show .main-navbar .nav-sub {
            margin-right: 2.5rem;
            margin-left: inherit;
        }
    }

    /***End Horizontal Styles ***/

    /* ###### Main-footer  ###### */

    .main-footer .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }

    @media (min-width: 992px) {
        .main-footer {
            padding: 15px 15rem 15px 15px;
        }
    }

    /* ###### Main-footer  ###### */

    /* ###### Right-sidebar  ###### */

    .sidebar-left {
        right: 0;
        left: inherit !important;
    }

    .sidebar-right,
    .sidebar-profile {
        left: 0;
        right: inherit !important;
        transform: translate(-100%, 0);
    }

    .row .sidebar.sidebar-left.sidebar-xs-show {
        right: 0;
        left: inherit !important;
    }

    .row .sidebar.sidebar-right.sidebar-xs-show,
    .row .sidebar.sidebar-profile.sidebar-xs-show {
        left: 0;
        right: inherit !important;
    }

    @media (min-width: 768px) {
        .row .sidebar.sidebar-left.sidebar-sm-show {
            right: 0;
            left: inherit !important;
        }

        .row .sidebar.sidebar-right.sidebar-sm-show,
        .row .sidebar.sidebar-profile.sidebar-sm-show {
            left: 0;
            right: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .row .sidebar.sidebar-left.sidebar-md-show {
            right: 0;
            left: inherit !important;
        }

        .row .sidebar.sidebar-right.sidebar-md-show,
        .row .sidebar.sidebar-profile.sidebar-md-show {
            left: 0;
            right: inherit !important;
        }
    }

    @media (min-width: 1170px) {
        .row .sidebar.sidebar-left.sidebar-lg-show {
            right: 0;
            left: inherit !important;
        }

        .row .sidebar.sidebar-right.sidebar-lg-show,
        .row .sidebar.sidebar-profile.sidebar-lg-show {
            left: 0;
            right: inherit !important;
        }
    }

    .sidebar-icon {
        left: 8px;
        right: inherit !important;
    }

    /* ###### Right-sidebar  ###### */

    /* ###### Main-profile  ###### */

    .main-profile-menu .dropdown-menu {
        right: auto;
        left: -10px !important;
    }

    .main-profile-menu .dropdown-item i {
        margin-left: 10px;
        margin-right: inherit;
    }

    /* ###### Main-profile  ###### */

    /* ###### Iconbar  ###### */

    .main-iconbar {
        right: 0;
        left: inherit !important;
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-iconbar-logo::after {
        right: 20px;
        left: inherit !important;
    }

    .main-iconbar-aside {
        border-left: 1px solid $border;
        border-right: inherit !important;
        right: 64px;
        left: inherit !important;
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside {
            border-left: 0;
            border-right: inherit !important;
        }
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside.show + .main-content {
            margin-right: 294px;
            margin-left: inherit;
        }
    }

    .main-iconbar-header {
        padding-right: 0;
        padding-left: inherit;
    }

    @media (min-width: 1200px) {
        .main-iconbar-toggle-menu::before {
            right: -1px;
            left: inherit !important;
            border-right: 2px solid #000000;
            border-left: inherit !important;
        }
    }

    .main-iconbar-body .nav-link i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .main-iconbar-body .nav-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-iconbar-body .nav-sub .nav-sub-item {
        margin-right: 15px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-iconbar-body .nav-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    .main-iconbar-aside-primary {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-iconbar-aside-primary .main-iconbar-body .nav {
        padding-right: 0;
        padding-left: inherit;
        border-right-width: 0;
        border-left-width: inherit !important;
    }

    @media (min-width: 992px) {
        .main-iconbar-aside-primary.show + .main-content {
            margin-right: 64px;
            margin-left: inherit;
        }
    }

    .icons-list {
        margin: 0 0 -1px -1px;
    }

    /* ###### Iconbar  ###### */

    /* ############### CUSTOM VENDOR STYLES ############### */

    /* ########## Datepicker ########## */

    .ui-datepicker {
        margin: 3px 0 0 0;
    }

    .ui-datepicker .ui-datepicker-header {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
        left: 17px;
        right: inherit !important;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
        right: 17px;
        left: inherit !important;
    }

    .ui-datepicker .ui-datepicker-calendar {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .ui-datepicker .ui-datepicker-calendar td {
        text-align: left;
    }

    .ui-datepicker .ui-datepicker-calendar td:last-child {
        border-left: 0;
        border-right: inherit !important;
    }

    .ui-datepicker-multi .ui-datepicker-group {
        padding-left: 15px;
        padding-right: inherit;
        float: right;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
        right: 10px;
        left: inherit !important;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
        left: 10px;
        right: inherit !important;
    }

    .ui-datepicker-multi .ui-datepicker-group-last {
        padding-left: 0;
        padding-right: inherit;
    }

    /* ########## Datepicker ########## */

    /* ########## Datetimepicker ########## */
    .datetimepicker {
        padding: 0 10px 10px;
        margin: 3px 0 0 0 !important;
    }

    .datetimepicker table th.prev span,
    .datetimepicker table th.next span {
        left: 0;
        right: 0;
    }

    .datetimepicker table th.prev span::before {
        right: 0;
        left: inherit !important;
    }

    .datetimepicker table th.next span::before {
        left: 0;
        right: inherit !important;
    }

    .main-datetimepicker > .datepicker_header a:nth-child(2)::before {
        right: 0;
        left: inherit !important;
    }

    .main-datetimepicker > .datepicker_header a:last-child::before {
        left: 0;
        right: inherit !important;
    }

    .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist {
        border-right: 1px solid #b4bdce;
        border-left: inherit !important;
    }

    .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item {
        margin: 0 1px 0 0;
    }

    /* ########## Datetimepicker ########## */

    /* ###### Jqvmap  ###### */

    #jqvmap1_ca_pin {
        margin-right: -2%;
        margin-left: inherit;
    }

    #jqvmap1_ct_pin {
        margin-right: -0.25%;
        margin-left: inherit;
    }

    #jqvmap1_fl_pin {
        margin-right: 5%;
        margin-left: inherit;
    }

    #jqvmap1_id_pin {
        margin-right: -1%;
        margin-left: inherit;
    }

    #jqvmap1_ky_pin {
        margin-right: 2%;
        margin-left: inherit;
    }

    #jqvmap1_la_pin {
        margin-right: -2%;
        margin-left: inherit;
    }

    #jqvmap1_mi_pin {
        margin-right: 3%;
        margin-left: inherit;
    }

    #jqvmap1_mn_pin {
        margin-right: -2%;
        margin-left: inherit;
    }

    #jqvmap1_nh_pin {
        margin-right: -0.25%;
        margin-left: inherit;
    }

    #jqvmap1_ok_pin,
    #jqvmap1_va_pin {
        margin-right: 2%;
        margin-left: inherit;
    }

    #jqvmap1_wv_pin {
        margin-right: -1%;
        margin-left: inherit;
    }

    /* ###### Jqvmap  ###### */

    /* ###### Chart  ###### */

    .main-donut-chart .slice {
        right: 0;
        left: inherit !important;
    }

    .morris-wrapper-demo {
        margin-right: -15px;
        margin-left: -15px;
    }

    .chart-donut {
        right: 0;
        left: inherit !important;
    }

    .flot-chart-wrapper {
        margin-right: -16px;
        margin-left: -30px;
    }

    .chart-wrapper {
        left: -8px;
        right: -8px;
        margin-right: -13px;
        margin-left: -13px;
    }

    .chart-legEnd {
        right: 0;
        left: 0;
    }

    .chart-legEnd div + div {
        margin-right: 15px;
        margin-left: inherit;
    }

    .chart-legEnd span {
        margin-left: 5px;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .chart-legEnd {
            right: auto;
            left: 40px;
        }

        .chart-legEnd span {
            margin-left: 8px;
            margin-right: inherit;
        }

        .chart-legEnd div + div {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    /* ###### Chart  ###### */

    /* ###### Mapel map  ###### */

    .mapael .zoomButton {
        right: 10px;
        left: inherit !important;
    }

    /* ###### Mapel map  ###### */

    /* ###### Parsley ###### */

    .parsley-errors-list li {
        margin: 5px 0 0;
    }

    /* ###### Parsley ###### */

    /* ###### Quill ###### */

    @media (min-width: 768px) {
        .ql-scrolling-demo {
            padding-right: 15px;
            padding-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .ql-scrolling-demo .ql-container {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .ql-bubble .ql-toolbar {
        padding: 0 8px 8px;
    }

    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 0 8px;
    }

    .ql-bubble .ql-toolbar .ql-formats:first-child {
        margin-right: 0;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .ql-bubble .ql-toolbar .ql-formats {
            margin: 8px 0 0;
        }

        .ql-bubble .ql-toolbar .ql-formats:first-child {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .ql-snow.ql-container {
        border-radius: 0 0 6px 6px;
    }

    .ql-snow.ql-toolbar {
        padding: 5px 10px 10px;
        border-radius: 6px 6px 0 0;
    }

    .ql-snow.ql-toolbar .ql-formats {
        margin-left: 5px;
        margin-right: inherit;
    }

    .ql-snow .ql-formats button + button {
        margin: 0 2px 0 0;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        left: 3px;
        right: inherit !important;
    }

    .ql-snow .ql-picker-label {
        padding-right: 10px;
        padding-left: inherit;
    }

    /* ###### Quill ###### */

    /* ###### Rangeslider  ###### */

    .irs-bar-edge {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .irs-line-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .irs-line-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .irs-slider:before {
        right: 50%;
        left: inherit !important;
        margin-right: -1px;
        margin-left: inherit;
    }

    .irs-modern .irs-slider::before {
        margin-right: -2px;
        margin-left: inherit;
    }

    .irs-outline .irs-bar-edge {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .irs-outline .irs-slider::before {
        margin-right: -4px;
        margin-left: inherit;
    }

    /* ###### Rangeslider  ###### */

    /* ###### Select2  ###### */

    // .select2-container--default .select2-selection--single .select2-selection__rendered {
    //     padding-right: 0.75rem;
    //     // padding-left: inherit;
    // }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        margin-left: 4px;
        margin-right: inherit;
        padding: 3px 20px 3px 10px !important;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        right: 7px;
        left: inherit !important;
    }

    .select2-container--default .select2-search--inline .select2-search__field {
        padding-right: 7px;
        padding-left: inherit;
        text-align: right;
    }

    .select2-container--default.select2-container--disabled .select2-selection__choice {
        padding-right: 10px;
        padding-left: inherit;
    }

    .select2-container--open .select2-dropdown--above {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .select2-container--open .select2-dropdown--below {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        direction: rtl;
    }

    /* ###### Select2  ###### */

    /* ###### Spectrum ###### */

    .sp-picker-container {
        border-right: 0;
        border-left: inherit !important;
    }

    .sp-dd::before {
        right: 0;
        left: inherit !important;
    }

    /* ###### Spectrum ###### */

    /* ###### Spinner ###### */

    .spinner-border {
        border-right-color: transparent;
    }

    .lds-facebook div {
        right: 6px;
        left: inherit !important;
    }

    .lds-facebook div:nth-child(1) {
        right: 6px;
        left: inherit !important;
    }

    .lds-facebook div:nth-child(2) {
        right: 26px;
        left: inherit !important;
    }

    .lds-facebook div:nth-child(3) {
        right: 45px;
        left: inherit !important;
    }

    .lds-heart div {
        right: 19px;
        left: inherit !important;
    }

    .lds-heart div:before {
        right: -17px;
        left: inherit !important;
    }

    @keyframes lds-ripple {
        0% {
            right: 28px;
            left: inherit !important;
        }

        100% {
            right: -1px;
            left: inherit !important;
        }
    }

    .lds-spinner div:after {
        right: 29px;
        left: inherit !important;
    }

    .pulse {
        left: 17px;
        right: inherit !important;
    }

    .ripple:after {
        right: 0;
        left: inherit !important;
    }

    .spinner-border {
        border-right-color: transparent;
    }

    /* ###### Spinner ###### */

    /* ############### SIDEMENU LAYOUT STYLES ############### */

    /* ###### Default Sidemenu ###### */

    .main-content.side-content {
        transition: margin-right 0.3s ease;
    }

    .sidemenu-label {
        transition: margin-right 0.45s ease;
    }

    .side-menu i.angle {
        left: 20px;
        right: inherit !important;
    }

    .side-menu .nav-item.show i.angle {
        left: 20px;
        right: inherit !important;
    }

    .side-badge,
    &.main-sidebar-open.main-sidebar-hide .side-badge {
        left: 18px;
        right: inherit !important;
    }

    &.main-sidebar-hide .side-badge {
        left: 8px;
        right: inherit !important;
    }

    .sidemenu-logo {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-footer {
        transition: margin-right 0.3s ease;
    }

    /* ###### Sidebar  ###### */
    @media (max-width: 991.98px) {
        &.main-sidebar-show .main-sidebar {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 992px) {
        &.main-sidebar-hide .main-sidebar-sticky + .main-content {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .main-body-sidebar .main-content-body {
        padding: 0 20px 20px;
    }

    @media (min-width: 992px) {
        .main-body-sidebar .main-header .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .main-sidebar {
        border-left: 0;
        border-right: inherit !important;
        transition: right 0.3s ease, width 0.3s ease;
    }

    @media (max-width: 991.98px) {
        .main-sidebar {
            right: 0;
            left: inherit !important;
            transform: translateX(240px);
        }
    }

    @media (min-width: 992px) {
        .main-sidebar {
            border-left-width: 1px;
            border-right-width: inherit !important;
        }
    }

    .main-sidebar-sticky {
        right: 0;
        left: inherit !important;
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-sidebar-body li.nav-item.active .nav-link {
        margin-right: 0;
        margin-left: inherit;
        border-left: 0px;
        border-right: inherit !important;
    }

    @media (min-width: 992px) {
        .main-sidebar-sticky + .main-content {
            margin-right: 220px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-sidebar-sticky + .main-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    .main-sidebar-loggedin .media-body {
        margin-right: 12px;
        margin-left: inherit;
    }

    @media (min-width: 1200px) {
        .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }
    }

    &.main-body .main-sidebar-body .nav {
        padding: 0 15px 0 15px;
    }

    .nav-header {
        margin: 0 15px 0 0;
    }

    .main-sidebar-body .nav-label {
        padding: 10px 0px 0 0;
    }

    .second-sidemenu .nav-label {
        padding: 10px 0px 0 0;
    }

    .main-sidebar-body .sub-txt {
        padding: 0 0px 0 0;
    }

    .second-sidemenu .sub-txt {
        padding: 0 0px 0 0;
    }

    .main-sidebar-body .nav-link .sidemenu-icon {
        margin-left: 10px;
        margin-right: 4px;
    }

    .main-sidebar-body .nav-sub {
        margin-right: 2.5rem;
        margin-left: inherit;
    }

    .main-sidebar-body .nav-sub .nav-sub-link:before {
        margin-left: 22px;
        margin-right: inherit;
    }

    .main-sidebar-body .nav-sub-item .nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-sidebar-body li.active .sidemenu-icon {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: 992px) {
        .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.main-sidebar-hide .main-sidebar .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }

        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
            border-radius: 0 30px 30px 0;
            margin-right: 0;
            margin-left: inherit;
            border-left: 0px;
            border-right: inherit !important;
        }

        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
            margin-left: 10px;
            // margin-right: inherit;
        }

        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
            margin-left: 10px;
            // margin-right: inherit;
        }

        &.main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
            padding: 0 15px 0 15px;
        }

        &.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 14px;
            // margin-left: inherit;
        }

        &.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
            transition: margin-right 0.2s ease;
        }
    }

    /* ###### Sidebar  ###### */

    @media (min-width: 1200px) {
        .side-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .side-header .main-logo {
            margin-left: 0;
            margin-right: inherit;
        }

        &.main-sidebar-hide .side-header,
        &.main-sidebar-hide .main-footer {
            padding-right: 70px;
            padding-left: inherit;
        }

        &.main-body .side-header {
            padding-right: 240px;
            padding-left: inherit;
        }

        &.main-body.main-sidebar-hide .side-header {
            padding-right: 75px;
            padding-left: inherit;
        }

        .main-content.side-content {
            margin-right: 240px;
            margin-left: initial !important;
        }

        .main-footer {
            margin-right: 0;
            margin-left: initial;
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        .main-header-menu-icon {
            margin-right: 7px !important;
            margin-left: inherit !important;
        }
    }

    @media (max-width: 767px) {
        .main-header-menu-icon {
            margin-left: 0;
            margin-right: inherit;
        }

        .side-header .icon-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        /*main-sidebar-hide*/

        &.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.main-sidebar-hide .main-content.side-content {
            margin-right: 80px !important;
            margin-left: initial !important;
        }

        &.main-sidebar-hide .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.main-sidebar-hide.main-sidebar-open .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.main-sidebar-hide .main-footer {
            margin-right: 0;
            margin-left: initial;
        }

        /*main-sidebar-open*/
        &.main-sidebar-open.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.main-sidebar-open.main-sidebar-hide .main-sidebar-body .sub-txt {
            padding: 0 0px 0 0;
        }

        &.main-sidebar-open.main-sidebar-hide .main-sidebar-body .nav-label {
            padding: 10px 0px 0 0;
        }

        &.main-sidebar-open.main-sidebar-hide .main-sidebar-body .side-menu .nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }

        &.main-sidebar-open.main-sidebar-hide .side-menu .nav-item.show i.angle {
            left: 20px;
            right: inherit !important;
        }

        &.main-sidebar-open.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
        padding-right: 17px;
        padding-left: 20px;
    }

    @media (min-width: 768px) {
        &.main-body.leftmenu .sidemenu-logo {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    /* ###### Default Sidemenu ###### */

    /* ###### Closed Sidemenu ###### */

    &.closed-leftmenu .main-content.side-content {
        transition: margin-right 0.3s ease;
    }

    &.closed-leftmenu .sidemenu-label {
        transition: margin-right 0.45s ease;
    }

    &.closed-leftmenu .side-menu i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.closed-leftmenu .side-menu .nav-item.show i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.closed-leftmenu .side-badge,
    &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .side-badge {
        left: 18px;
        right: inherit !important;
    }

    &.closed-leftmenu.main-sidebar-hide .side-badge {
        left: 8px;
        right: inherit !important;
    }

    &.closed-leftmenu .sidemenu-logo {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.closed-leftmenu .main-footer {
        transition: margin-right 0.3s ease;
    }

    @media (max-width: 991.98px) {
        &.closed-leftmenu.main-sidebar-show .main-sidebar {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 992px) {
        &.closed-leftmenu.main-sidebar-hide .main-sidebar-sticky + .main-content {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    &.closed-leftmenu.main-body-sidebar .main-content-body {
        padding: 0 20px 20px;
    }

    @media (min-width: 992px) {
        &.closed-leftmenu .main-body-sidebar .main-header .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &.closed-leftmenu .main-sidebar {
        border-left: 0;
        border-right: inherit !important;
        transition: right 0.2s ease, width 0.2s ease;
    }

    @media (max-width: 991.98px) {
        &.closed-leftmenu .main-sidebar {
            right: 0;
            left: inherit !important;
            transform: translateX(240px);
        }
    }

    @media (min-width: 992px) {
        &.closed-leftmenu .main-sidebar {
            border-left-width: 1px;
            border-right-width: inherit !important;
        }
    }

    &.closed-leftmenu .main-sidebar-sticky {
        right: 0;
        left: inherit !important;
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.closed-leftmenu .main-sidebar-body li.nav-item.active .nav-link {
        margin-right: 0;
        margin-left: inherit;
        border-left: 0px;
        border-right: inherit !important;
    }

    @media (min-width: 992px) {
        &.closed-leftmenu .main-sidebar-sticky + .main-content {
            margin-right: 220px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        &.closed-leftmenu .main-sidebar-sticky + .main-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    &.closed-leftmenu .main-sidebar-loggedin .media-body {
        margin-right: 12px;
        margin-left: inherit;
    }

    @media (min-width: 1200px) {
        &.closed-leftmenu .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }
    }

    &.closed-leftmenu.main-body .main-sidebar-body .nav {
        padding: 0 15px 0 15px;
    }

    &.closed-leftmenu .nav-header {
        margin: 0 15px 0 0;
    }

    &.closed-leftmenu .main-sidebar-body .nav-label {
        padding: 10px 0px 0 0;
    }

    &.closed-leftmenu .second-sidemenu .nav-label {
        padding: 10px 0px 0 0;
    }

    &.closed-leftmenu .main-sidebar-body .sub-txt {
        padding: 0 0px 0 0;
    }

    &.closed-leftmenu .second-sidemenu .sub-txt {
        padding: 0 0px 0 0;
    }

    &.closed-leftmenu .main-sidebar-body .nav-link .sidemenu-icon {
        margin-left: 10px;
        margin-right: 4px;
    }

    &.closed-leftmenu .main-sidebar-body .nav-sub {
        margin-right: 2.5rem;
        margin-left: inherit;
    }

    &.closed-leftmenu .main-sidebar-body .nav-sub .nav-sub-link:before {
        margin-left: 22px;
        margin-right: inherit;
    }

    &.closed-leftmenu .main-sidebar-body .nav-sub-item .nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    &.closed-leftmenu .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    &.closed-leftmenu .main-sidebar-body li.active .sidemenu-icon {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: 992px) {
        &.closed-leftmenu .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.closed-leftmenu.main-sidebar-hide .main-sidebar .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }

        &.closed-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
            border-radius: 0 30px 30px 0;
            margin-right: 0;
            border-left: 0px;
            border-right: inherit !important;
        }

        &.closed-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
            margin-left: 10px;
            // margin-right: inherit;
        }

        &.closed-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
            margin-left: 10px;
            // margin-right: inherit;
        }

        &.closed-leftmenu.main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
            padding: 0 15px 0 15px;
        }

        &.closed-leftmenu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 10px;
            // margin-left: inherit;
        }

        &.closed-leftmenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
            transition: margin-right 0.2s ease;
        }
    }

    @media (min-width: 1200px) {
        &.closed-leftmenu .side-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        &.closed-leftmenu .side-header .main-logo {
            margin-left: 0;
            margin-right: inherit;
        }

        &.closed-leftmenu.main-sidebar-hide .side-header,
        &.closed-leftmenu.main-sidebar-hide .main-footer {
            padding-right: 70px;
            padding-left: inherit;
        }

        &.closed-leftmenu.main-body .side-header {
            padding-right: 240px;
            padding-left: inherit;
        }

        &.closed-leftmenu.main-body.main-sidebar-hide .side-header {
            padding-right: 0px;
            padding-left: inherit;
        }

        &.closed-leftmenu .main-content.side-content {
            margin-right: 240px;
            margin-left: inherit;
        }

        &.closed-leftmenu .main-footer {
            margin-right: 0;
            margin-left: initial;
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        &.closed-leftmenu .main-header-menu-icon {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    @media (max-width: 767px) {
        &.closed-leftmenu .main-header-menu-icon {
            margin-left: 0;
            margin-right: inherit;
        }

        &.closed-leftmenu .side-header .icon-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.closed-leftmenu.main-sidebar-hide .side-menu {
            right: 0 !important;
            left: inherit !important;
            transform: translateX(240px);
        }

        &.closed-leftmenu.main-sidebar-hide .main-content.side-content {
            margin-right: 0 !important;
            margin-left: initial !important;
        }

        &.closed-leftmenu.main-sidebar-hide .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.closed-leftmenu.main-sidebar-hide.main-sidebar-open .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.closed-leftmenu.main-sidebar-hide .iconbtn {
            display: block;
        }

        &.closed-leftmenu.main-sidebar-hide .main-footer {
            margin-right: 0;
            margin-left: initial;
        }

        &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .sub-txt {
            padding: 0 0px 0 0;
        }

        &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .nav-label {
            padding: 10px 0px 0 0;
        }

        &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .side-menu .nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }

        &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .side-menu .nav-item.show i.angle {
            left: 20px;
            right: inherit !important;
        }

        &.closed-leftmenu.main-sidebar-open.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    &.closed-leftmenu .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
        padding-right: 17px;
        padding-left: 20px;
    }

    &.closed-leftmenu .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    /* ###### Closed Sidemenu ###### */

    /* ###### Icon Text Sidemenu ###### */

    &.icontext-menu .main-content.side-content {
        transition: margin-right 0.3s ease;
    }

    &.icontext-menu .sidemenu-label {
        transition: margin-right 0.45s ease;
    }

    &.icontext-menu .side-menu i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.icontext-menu .side-menu .nav-item.show i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.icontext-menu .side-badge,
    &.icontext-menu.main-sidebar-open.main-sidebar-hide .side-badge {
        left: 18px;
        right: inherit !important;
    }

    &.icontext-menu.main-sidebar-hide .side-badge {
        left: 21px;
        right: inherit !important;
    }

    &.icontext-menu .sidemenu-logo {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    @media (max-width: 991px) {
        &.icontext-menu.main-sidebar-show .main-sidebar {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 992px) {
        &.icontext-menu.main-sidebar-hide .main-sidebar-sticky + .main-content {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    &.icontext-menu .main-body-sidebar .main-content-body {
        padding: 0 20px 20px;
    }

    @media (min-width: 992px) {
        &.icontext-menu .main-body-sidebar .main-header .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &.icontext-menu .main-sidebar {
        border-left: 0;
        border-right: inherit !important;
        transition: right 0.2s ease, width 0.2s ease;
    }

    @media (max-width: 991px) {
        &.icontext-menu .main-sidebar {
            right: 0;
            left: inherit !important;
            transform: translateX(240px);
        }
    }

    @media (min-width: 992px) {
        &.icontext-menu .main-sidebar {
            border-left-width: 1px;
            border-right-width: inherit !important;
        }
    }

    &.icontext-menu .main-sidebar-sticky {
        right: 0;
        left: inherit !important;
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.icontext-menu .main-sidebar-body li.nav-item.active .nav-link {
        margin-right: 0;
        margin-left: inherit;
        border-left: 0px;
        border-right: inherit !important;
    }

    @media (min-width: 992px) {
        &.icontext-menu .main-sidebar-sticky + .main-content {
            margin-right: 220px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        &.icontext-menu .main-sidebar-sticky + .main-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    &.icontext-menu .main-sidebar-loggedin .media-body {
        margin-right: 12px;
        margin-left: inherit;
    }

    @media (min-width: 1200px) {
        &.icontext-menu .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }
    }

    &.icontext-menu.main-body .main-sidebar-body .nav {
        padding: 0 15px 0 15px;
    }

    &.icontext-menu .nav-header {
        margin: 0 15px 0 0;
    }

    &.icontext-menu .main-sidebar-body .nav-label {
        padding: 10px 0px 0 0;
    }

    .icontext-menu .second-sidemenu .nav-label {
        padding: 10px 0px 0 0;
    }

    &.icontext-menu .main-sidebar-body .sub-txt {
        padding: 0 0px 0 0;
    }

    &.icontext-menu .second-sidemenu .sub-txt {
        padding: 0 0px 0 0;
    }

    &.icontext-menu .main-sidebar-body .nav-link .sidemenu-icon {
        margin-left: 10px;
        margin-right: 4px;
    }

    &.icontext-menu .main-sidebar-body .nav-sub {
        margin-right: 2.5rem;
        margin-left: inherit;
    }

    .icontext-menu .main-sidebar-body .nav-sub .nav-sub-link:before {
        margin-left: 22px;
        margin-right: inherit;
    }

    &.icontext-menu .main-sidebar-body .nav-sub-item .nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    &.icontext-menu .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    &.icontext-menu .main-sidebar-body li.active .sidemenu-icon {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: 992px) {
        &.icontext-menu .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.icontext-menu.main-sidebar-hide .main-sidebar .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }

        &.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
            border-radius: 30px 0 0 30px;
            margin-right: 0;
            margin-left: inherit;
            border-left: 0px;
            border-right: inherit !important;
        }

        &.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
            margin-left: 10px;
            // margin-right: inherit;
        }

        &.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
            margin-left: 10px;
            // margin-right: inherit;
        }

        &.icontext-menu.main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
            padding: 0 15px 0 15px;
        }

        &.icontext-menu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 10px;
            // margin-left: inherit;
        }

        &.icontext-menu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
            transition: margin-right 0.2s ease;
        }
    }

    @media (min-width: 1200px) {
        &.icontext-menu .side-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        &.icontext-menu .side-header .main-logo {
            margin-left: 0;
            margin-right: inherit;
        }

        &.icontext-menu.main-sidebar-hide .side-header,
        &.icontext-menu.main-sidebar-hide .main-footer {
            padding-right: 70px;
            padding-left: inherit;
        }

        &.icontext-menu.main-body .side-header {
            padding-right: 240px;
            padding-left: inherit;
        }

        &.icontext-menu.main-body.main-sidebar-hide .side-header {
            padding-right: 120px;
            padding-left: inherit;
        }

        &.icontext-menu .main-content.side-content {
            margin-right: 240px;
            margin-left: inherit;
        }

        &.icontext-menu .main-footer {
            margin-right: 0;
            margin-left: initial;
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        &.icontext-menu .main-header-menu-icon {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    @media (max-width: 767px) {
        &.icontext-menu .main-header-menu-icon {
            margin-left: 0;
            margin-right: inherit;
        }

        &.icontext-menu .side-header .icon-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.icontext-menu.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.icontext-menu.main-sidebar-hide .main-content.side-content {
            margin-right: 120px !important;
            margin-left: initial !important;
        }

        &.icontext-menu.main-sidebar-hide .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.icontext-menu.main-sidebar-hide.main-sidebar-open .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.icontext-menu.main-sidebar-hide .main-footer {
            margin-right: 0;
            margin-left: initial;
        }

        &.icontext-menu.main-sidebar-open.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.icontext-menu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .sub-txt {
            padding: 0 0px 0 0;
        }

        &.icontext-menu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .nav-label {
            padding: 10px 0px 0 0;
        }

        &.icontext-menu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .side-menu .nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }

        &.icontext-menu.main-sidebar-open.main-sidebar-hide .side-menu .nav-item.show i.angle {
            left: 20px;
            right: inherit !important;
        }

        &.icontext-menu.main-sidebar-open.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    &.icontext-menu .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
        padding-right: 17px;
        padding-left: 20px;
    }

    @media (min-width: 768px) {
        &.icontext-menu.main-body.leftmenu .sidemenu-logo {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        &.icontext-menu.main-sidebar-hide .main-content.side-content {
            margin-right: 0px !important;
            margin-left: initial !important;
        }

        &.icontext-menu.main-sidebar-hide.main-sidebar-show .main-content.side-content {
            margin-right: 120px !important;
            margin-left: inherit !important;
        }
    }

    &.icontext-menu .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    /* ###### Icon Text Sidemenu ###### */

    /* ###### Hover Submenu ###### */

    &.hover-submenu .main-content.side-content {
        transition: margin-right 0.3s ease;
    }

    &.hover-submenu .sidemenu-label {
        transition: margin-right 0.45s ease;
    }

    &.hover-submenu .side-menu i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.hover-submenu .side-menu .nav-item.show i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.hover-submenu .side-badge {
        left: 18px;
        right: inherit !important;
    }

    &.hover-submenu.main-sidebar-open.main-sidebar-hide .side-badge {
        left: 39px;
        right: inherit !important;
    }

    &.hover-submenu.main-sidebar-hide .side-badge {
        left: 39px;
        right: inherit !important;
    }

    &.hover-submenu .sidemenu-logo {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.hover-submenu .main-footer {
        transition: margin-right 0.3s ease;
    }

    @media (max-width: 991.98px) {
        &.hover-submenu.main-sidebar-show .main-sidebar {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu.main-sidebar-hide .main-sidebar-sticky + .main-content {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    &.hover-submenu .main-body-sidebar .main-content-body {
        padding: 0 20px 20px;
    }

    @media (min-width: 992px) {
        &.hover-submenu .main-body-sidebar .main-header .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &.hover-submenu .main-sidebar {
        border-left: 0;
        border-right: inherit !important;
        transition: right 0.2s ease, width 0.2s ease;
    }

    @media (max-width: 991.98px) {
        &.hover-submenu .main-sidebar {
            right: 0;
            left: inherit !important;
            transform: translateX(240px);
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu .main-sidebar {
            border-left-width: 1px;
            border-right-width: inherit !important;
        }
    }

    &.hover-submenu .main-sidebar-sticky {
        right: 0;
        left: inherit !important;
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.hover-submenu .main-sidebar-body li.nav-item.active .nav-link {
        margin-right: 0;
        margin-left: inherit;
        border-left: 0px;
        border-right: inherit !important;
    }

    @media (min-width: 992px) {
        &.hover-submenu .main-sidebar-sticky + .main-content {
            margin-right: 220px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        &.hover-submenu .main-sidebar-sticky + .main-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    &.hover-submenu .main-sidebar-loggedin .media-body {
        margin-right: 12px;
        margin-left: inherit;
    }

    @media (min-width: 1200px) {
        &.hover-submenu .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }
    }

    &.hover-submenu.main-body .main-sidebar-body .nav {
        padding: 0 15px 0 15px;
    }

    &.hover-submenu .nav-header {
        margin: 0 15px 0 0;
    }

    &.hover-submenu .main-sidebar-body .nav-label {
        padding: 10px 0px 0 0;
    }

    &.hover-submenu .second-sidemenu .nav-label {
        padding: 10px 0px 0 0;
    }

    &.hover-submenu .main-sidebar-body .sub-txt {
        padding: 0 0px 0 0;
    }

    &.hover-submenu .second-sidemenu .sub-txt {
        padding: 0 0px 0 0;
    }

    &.hover-submenu .main-sidebar-body .nav-link .sidemenu-icon {
        margin-left: 10px;
        margin-right: 4px;
    }

    &.hover-submenu .main-sidebar-body .nav-sub {
        margin-right: 2.5rem;
        margin-left: inherit;
    }

    &.hover-submenu .main-sidebar-body .nav-sub .nav-sub-link:before {
        margin-left: 22px;
        margin-right: inherit;
    }

    &.hover-submenu .main-sidebar-body .nav-sub-item .nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    &.hover-submenu .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    &.hover-submenu .main-sidebar-body li.active .sidemenu-icon {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: 992px) {
        &.hover-submenu .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.hover-submenu.main-sidebar-hide .main-sidebar .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
            border-radius: 0 30px 30px 0;
            margin-right: 0;
            margin-left: inherit;
            border-left: 0px;
            border-right: inherit !important;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
            // margin-right: 10px;
            // margin-left: inherit;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 10px;
            // margin-left: inherit;
        }

        &.hover-submenu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 10px;
            // margin-left: inherit;
        }

        &.hover-submenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
            transition: margin-right 0.2s ease;
        }
    }

    @media (min-width: 1200px) {
        &.hover-submenu .side-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu .side-header .main-logo {
            margin-left: 0;
            margin-right: inherit;
        }

        &.hover-submenu.main-sidebar-hide .side-header,
        &.hover-submenu.main-sidebar-hide .main-footer {
            padding-right: 70px;
            padding-left: inherit;
        }

        &.hover-submenu.main-body .side-header {
            padding-right: 240px;
            padding-left: inherit;
        }

        &.hover-submenu.main-body.main-sidebar-hide .side-header {
            padding-right: 120px;
            padding-left: inherit;
        }

        &.hover-submenu .main-content.side-content {
            margin-right: 240px;
            margin-left: inherit;
        }

        &.hover-submenu .main-footer {
            margin-right: 0;
            margin-left: initial;
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        &.hover-submenu .main-header-menu-icon {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    @media (max-width: 767px) {
        &.hover-submenu .main-header-menu-icon {
            margin-left: 0;
            margin-right: inherit;
        }

        &.hover-submenu .side-header .icon-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.hover-submenu.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.hover-submenu.main-sidebar-hide .main-content.side-content {
            margin-right: 120px !important;
            margin-left: initial !important;
        }

        &.hover-submenu.main-sidebar-hide .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.hover-submenu.main-sidebar-hide .main-footer {
            margin-right: 0;
            margin-left: initial;
        }

        &.hover-submenu.main-sidebar-open.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.hover-submenu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .sub-txt {
            padding: 0 0px 0 0;
        }

        &.hover-submenu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .nav-label {
            padding: 10px 0px 0 0;
        }

        &.hover-submenu.main-sidebar-open.main-sidebar-hide .main-sidebar-body .side-menu .nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }

        &.hover-submenu.main-sidebar-open.main-sidebar-hide .side-menu .nav-item.show i.angle {
            left: 20px;
            right: inherit !important;
        }

        &.hover-submenu.main-sidebar-open.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    &.hover-submenu .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
        padding-right: 17px;
        padding-left: 20px;
    }

    @media (min-width: 768px) {
        &.hover-submenu.main-body.leftmenu .sidemenu-logo {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 768px) {
        &.hover-submenu.main-body.leftmenu.main-sidebar-hide .nav-sub {
            right: 79px;
            left: inherit !important;
        }

        &.hover-submenu.main-sidebar-open .nav-sub {
            box-shadow: -8px 8px 17px $black-2;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
            padding: 12px 15px 12px 0px;
            border-radius: 5px 0 0 5px !important;
        }

        &.hover-submenu.main-sidebar-open .nav-sub {
            right: 72px;
            left: inherit !important;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.show > .nav-sub {
            right: 119px;
            left: inherit !important;
        }

        &.hover-submenu.main-body.leftmenu.main-sidebar-hide .nav-item .nav-sub-item.show .nav-sub {
            right: 0;
            left: inherit !important;
        }
    }

    &.hover-submenu .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    /* ###### Hover Submenu ###### */

    /* ###### Hover Submenu Style 1 ###### */

    &.hover-submenu1 .main-content.side-content {
        transition: margin-right 0.3s ease;
    }

    &.hover-submenu1 .sidemenu-label {
        transition: margin-right 0.45s ease;
    }

    &.hover-submenu1 .side-menu i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.hover-submenu1 .side-menu .nav-item.show i.angle {
        left: 20px;
        right: inherit !important;
    }

    &.hover-submenu1 .side-badge {
        left: 18px;
        right: inherit !important;
    }

    &.hover-submenu1.main-sidebar-open.main-sidebar-hide .side-badge {
        left: 20px;
        right: inherit !important;
    }

    &.hover-submenu1.main-sidebar-hide .side-badge {
        left: 20px;
        right: inherit !important;
    }

    &.hover-submenu1 .sidemenu-logo {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.hover-submenu1 .main-footer {
        transition: margin-right 0.3s ease;
    }

    @media (max-width: 991.98px) {
        &.hover-submenu1.main-sidebar-show .main-sidebar {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu1.main-sidebar-hide .main-sidebar-sticky + .main-content {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    &.hover-submenu1 .main-body-sidebar .main-content-body {
        padding: 0 20px 20px;
    }

    @media (min-width: 992px) {
        &.hover-submenu1 .main-body-sidebar .main-header .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &.hover-submenu1 .main-sidebar {
        border-left: 0;
        border-right: inherit !important;
        transition: right 0.2s ease, width 0.2s ease;
    }

    @media (max-width: 991.98px) {
        &.hover-submenu1 .main-sidebar {
            right: 0;
            left: inherit !important;
            transform: translateX(240px);
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu1 .main-sidebar {
            border-left-width: 1px;
            border-right-width: inherit !important;
        }
    }

    &.hover-submenu1 .main-sidebar-sticky {
        right: 0;
        left: inherit !important;
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    &.hover-submenu1 .main-sidebar-body li.nav-item.active .nav-link {
        margin-right: 0;
        margin-left: inherit;
        border-left: 0px;
        border-right: inherit !important;
    }

    @media (min-width: 992px) {
        &.hover-submenu1 .main-sidebar-sticky + .main-content {
            margin-right: 220px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        &.hover-submenu1 .main-sidebar-sticky + .main-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    &.hover-submenu1 .main-sidebar-loggedin .media-body {
        margin-right: 12px;
        margin-left: inherit;
    }

    @media (min-width: 1200px) {
        &.hover-submenu1 .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }
    }

    &.hover-submenu1 .main-body .main-sidebar-body .nav {
        padding: 0 15px 0 15px;
    }

    &.hover-submenu1 .nav-header {
        margin: 0 15px 0 0;
    }

    &.hover-submenu1 .main-sidebar-body .nav-label {
        padding: 10px 0px 0 0;
    }

    &.hover-submenu1 .second-sidemenu .nav-label {
        padding: 10px 0px 0 0;
    }

    &.hover-submenu1 .main-sidebar-body .sub-txt {
        padding: 0 0px 0 0;
    }

    &.hover-submenu1 .second-sidemenu .sub-txt {
        padding: 0 0px 0 0;
    }

    &.hover-submenu1 .main-sidebar-body .nav-link .sidemenu-icon {
        margin-left: 10px;
        margin-right: 4px;
    }

    &.hover-submenu1 .main-sidebar-body .nav-sub {
        margin-right: 2.5rem;
        margin-left: inherit;
    }

    &.hover-submenu1 .main-sidebar-body .nav-sub .nav-sub-link:before {
        margin-left: 22px;
        margin-right: inherit;
    }

    &.hover-submenu1 .main-sidebar-body .nav-sub-item .nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    &.hover-submenu1 .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    &.hover-submenu1 .main-sidebar-body li.active .sidemenu-icon {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: 992px) {
        &.hover-submenu1 .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.hover-submenu1.main-sidebar-hide .main-sidebar .main-sidebar-body {
            padding: 12px 0px 12px 0px;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
            border-radius: 0 30px 30px 0;
            margin-right: 0;
            margin-left: inherit;
            border-left: 0px;
            border-right: inherit !important;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
            // margin-right: 10px;
            // margin-left: inherit;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 10px;
            // margin-left: inherit;
        }

        &.hover-submenu1.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
            margin-right: 10px;
            // margin-left: inherit;
        }

        &.hover-submenu1.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
            transition: margin-right 0.2s ease;
        }
    }

    @media (min-width: 1200px) {
        &.hover-submenu1 .side-content {
            margin-right: 240px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu1 .side-header .main-logo {
            margin-left: 0;
            margin-right: inherit;
        }

        &.hover-submenu1.main-sidebar-hide .side-header,
        &.hover-submenu1.main-sidebar-hide .main-footer {
            padding-right: 70px;
            padding-left: inherit;
        }

        &.hover-submenu1.main-body .side-header {
            padding-right: 240px;
            padding-left: inherit;
        }

        &.hover-submenu1.main-body.main-sidebar-hide .side-header {
            padding-right: 80px;
            padding-left: inherit;
        }

        &.hover-submenu1 .main-content.side-content {
            margin-right: 240px;
            margin-left: inherit;
        }

        &.hover-submenu1 .main-footer {
            margin-right: 0;
            margin-left: initial;
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        &.hover-submenu1 .main-header-menu-icon {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    @media (max-width: 767px) {
        &.hover-submenu1 .main-header-menu-icon {
            margin-left: 0;
            margin-right: inherit;
        }

        &.hover-submenu1 .side-header .icon-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        &.hover-submenu1.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.hover-submenu1.main-sidebar-hide .main-content.side-content {
            margin-right: 80px !important;
            margin-left: initial !important;
        }

        &.hover-submenu1.main-sidebar-hide .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-header {
            margin: 0 15px 0 0 !important;
        }

        &.hover-submenu1.main-sidebar-hide .main-footer {
            margin-right: 0;
            margin-left: initial;
        }

        &.hover-submenu1.main-sidebar-open.main-sidebar-hide .side-menu {
            right: 0;
            left: inherit !important;
        }

        &.hover-submenu1.main-sidebar-open.main-sidebar-hide .main-sidebar-body .sub-txt {
            padding: 0 0px 0 0;
        }

        &.hover-submenu1.main-sidebar-open.main-sidebar-hide .main-sidebar-body .nav-label {
            padding: 10px 0px 0 0;
        }

        &.hover-submenu1.main-sidebar-open.main-sidebar-hide .main-sidebar-body .side-menu .nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }

        &.hover-submenu1.main-sidebar-open.main-sidebar-hide .side-menu .nav-item.show i.angle {
            left: 20px;
            right: inherit !important;
        }

        &.hover-submenu1.main-sidebar-open.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    &.hover-submenu1 .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
        padding-right: 17px;
        padding-left: 20px;
    }

    @media (min-width: 768px) {
        &.hover-submenu1.main-body.leftmenu .sidemenu-logo {
            transition: right 0.3s ease, width 0.3s ease;
        }
    }

    @media (min-width: 768px) {
        &.hover-submenu1.main-body.leftmenu.main-sidebar-hide .nav-sub {
            right: 39px;
            left: inherit !important;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.show > .nav-sub {
            right: 79px;
            left: inherit !important;
        }

        &.hover-submenu1.main-sidebar-open .nav-sub {
            box-shadow: -8px 8px 17px $black-2;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
            border-radius: 5px 0 0 5px !important;
        }

        &.hover-submenu1.main-body.leftmenu.main-sidebar-hide .nav-item .nav-sub-item.show .nav-sub {
            right: 0;
            left: inherit !important;
        }

        &.hover-submenu1.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
            padding: 8px 12px 8px 0 !important;
            text-align: right;
        }

        &.hover-submenu1.main-sidebar-hide.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub-link {
            padding: 8px 12px 8px 0;
        }
    }

    &.hover-submenu1 .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-right-width: 1px;
        border-left-width: inherit !important;
        margin-right: 0;
        margin-left: inherit;
    }

    /* ###### Hover Submenu Style 1 ###### */

    /* ############### TEMPLATE STYLES ############### */

    /* ###### Calendar  ###### */
    .main-content-calendar .container,
    .main-content-calendar .container-fluid {
        padding: 20px 0 0;
    }

    .main-nav-calendar-event .nav-link i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .main-nav-calendar-event .nav-link.exclude::before {
        right: 0;
        left: 0;
    }

    @media (min-width: 992px) {
        .main-content-body-calendar {
            padding-right: 25px;
            padding-left: inherit;
        }
    }

    .main-calendar .fc-view .fc-day-number {
        margin: 2px 0 0 2px;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-scroller,
    .main-calendar .fc-view.fc-listWeek-view .fc-scroller {
        padding: 0 20px 20px;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        border-right: 4px solid transparent;
        border-left: inherit !important;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
        margin-right: 20%;
        margin-left: inherit;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
        padding: 12px 15px 2px;
    }

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
        padding: 0 15px 12px;
    }

    .main-calendar .fc-time-grid-event {
        border-right-width: 0;
        border-left-width: inherit !important;
    }
    @media (max-width: 575px) {
        .main-calendar .fc-header-toolbar button.fc-today-button {
            padding-right: 10px;
            padding-left: 10px;
        }

        .main-calendar .fc-header-toolbar button.fc-month-button::before,
        .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
        .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
        .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
        .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
            right: 10px;
            left: inherit !important;
        }

        .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
        .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
            right: 11px;
            left: inherit !important;
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view > table > thead th:first-child,
        .main-calendar .fc-view > table > thead td:first-child {
            border-right-width: 0;
            border-left-width: inherit !important;
        }

        .main-calendar .fc-view > table > thead th:last-child,
        .main-calendar .fc-view > table > thead td:last-child {
            border-left-width: 0;
            border-right-width: inherit !important;
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view > table > tbody > tr > td:first-child {
            border-right-width: 0;
            border-left-width: inherit !important;
        }

        .main-calendar .fc-view > table > tbody > tr > td:last-child {
            border-left-width: 0;
            border-right-width: inherit !important;
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
        .main-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
            margin-right: 15%;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .main-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
        .main-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
            margin-right: 12%;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
        .main-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
            margin-right: 10%;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
        .main-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
            margin-right: 8%;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-event {
            border-right-width: 2px;
            border-left-width: inherit !important;
        }
    }

    .main-modal-calendar-schedule .modal-content > .btn-close {
        left: 25px;
        right: inherit !important;
    }

    .main-modal-calendar-schedule .form-group-date > i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .main-modal-calendar-event .modal-header {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .main-modal-calendar-event .nav-modal-event .nav-link + .nav-link {
        margin-right: 15px;
        margin-left: inherit;
    }

    /* ###### Calendar  ###### */

    /* ###### Chat  ###### */

    .main-nav-line-chat {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-nav-line-chat .nav-link + .nav-link {
        margin-right: 30px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-chat-contacts-wrapper {
            padding: 10px 0 10px 15px;
        }
    }

    .main-chat-contacts {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-chat-list .media:hover:last-child,
    .main-chat-list .media:focus:last-child {
        border-radius: 0 0 11px 11px;
    }

    .main-chat-list .main-img-user span {
        right: -2px;
        left: inherit !important;
    }

    .main-chat-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-header .nav {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-chat-header .nav-link + .nav-link {
        margin-right: 15px;
        margin-left: initial;
    }

    .main-chat-msg-name {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-body .media.flex-row-reverse .media-body {
        margin-right: 0;
        margin-left: 20px;
    }

    .main-chat-body .media.flex-row-reverse .main-msg-wrapper:before,
    .main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
        left: auto;
        right: 100%;
        border-right-color: transparent;
        border-left-color: #e9e9f9;
    }

    @media (min-width: 576px) {
        .main-chat-body .media.flex-row-reverse .media-body {
            margin-right: 55px;
        }
    }

    .main-msg-wrapper {
        border-radius: 6px 6px 6px 0;
    }

    .main-msg-wrapper-1 {
        border-radius: 0px 6px 6px 6px;
    }

    .main-msg-wrapper-2 {
        border-radius: 6px 6px 0px 6px;
    }

    .main-msg-wrapper-3 {
        border-radius: 6px 0px 6px 6px;
    }

    .main-chat-body .media-body {
        margin-right: 20px;
    }

    @media (min-width: 576px) {
        .main-chat-body .media-body {
            margin-left: 55px;
        }
    }

    .main-chat-footer {
        border-radius: 0 0 11px 11px;
        right: 0;
        left: 0;
    }

    .main-chat-footer .nav-link + .nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-chat-footer .form-control {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media (min-width: 992px) {
        .main-chat-footer {
            right: auto;
            left: auto;
        }
    }

    @media (min-width: 576px) {
        .main-chat-footer .form-control {
            margin-right: 15px;
        }
    }

    @media (min-width: 768px) {
        .main-chat-footer .form-control {
            margin: 0 15px 0 10px;
        }
    }

    .main-msg-emoji {
        right: 31px;
        left: initial !important;
    }

    .main-msg-attach {
        left: 90px;
        right: initial !important;
    }

    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
        padding-right: 20px;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        .main-content-left-contacts .main-content-breadcrumb,
        .main-content-left-contacts .main-content-title {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    /* ###### Chat  ###### */

    /* ########## Contacts ########## */

    .main-contact-label {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-contact-label::after {
        right: 0;
        left: 0;
    }

    .main-contact-item {
        border-left-width: 0;
        border-right-width: inherit !important;
    }

    .main-contact-item.selected {
        border-left-color: $border;
        border-left-width: 0;
    }

    .main-contact-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-contact-info-header {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-contact-info-header .main-img-user a {
        left: 1px;
        right: inherit !important;
    }

    .main-contact-info-header .media-body .nav-link i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .main-contact-info-header .media-body .nav-link + .nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-contact-info-header .media-body {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link i {
            margin-left: 7px;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link + .nav-link {
            margin-right: 25px;
            margin-left: inherit;
        }
    }

    .main-contact-action {
        left: 20px;
        right: inherit !important;
    }

    @media (min-width: 992px) {
        .main-contact-action {
            left: 0;
            right: inherit !important;
        }
    }

    /* ########## Contacts ########## */

    /* ######  Error  ###### */

    .main-column-signup-left .btn,
    .main-column-signup-left .sp-container button {
        padding-right: 25px;
        padding-left: 25px;
    }

    .sp-container .main-column-signup-left button {
        padding-right: 25px;
        padding-left: 25px;
    }

    @media (min-width: 992px) {
        .main-column-signup {
            border-right: 1px solid $border;
            border-left: inherit !important;
        }
    }

    .main-signup-header .row > div .btn i,
    .main-signup-header .row > div .sp-container button i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .sp-container .main-signup-header .row > div button i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .signpages .details {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .construction {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .construction:before {
        right: 0;
        left: inherit !important;
    }

    .signpages .details:before {
        right: 0;
        left: inherit !important;
    }

    .signpages .login_form {
        border-top-left-radius: 11px !important;
        border-bottom-left-radius: 11px !important;
    }

    .signpages .form__input {
        padding: 1em 0.5em 0.5em;
        padding-right: 2em;
        padding-left: inherit;
    }

    @media screen and (max-width: 640px) {
        .signpages .login_form {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    /* ######  Error  ###### */

    /* ###### Invoice  ###### */

    @media (min-width: 992px) {
        .main-content-left-invoice {
            border-left: 1px solid $border;
            border-right: inherit !important;
        }
    }

    .main-invoice-list .media + .media::before {
        right: 55px;
        left: 0;
    }

    .main-invoice-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .card-invoice .card-body {
            padding: 0 50px 50px;
        }
    }

    .table-invoice tbody > tr > th:first-child .invoice-notes,
    .table-invoice tbody > tr > td:first-child .invoice-notes {
        margin-left: 20px;
        margin-right: inherit;
    }

    .main-content-left-invoice .main-content-breadcrumb,
    .main-content-left-invoice .main-content-title {
        padding-right: 20px;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        .main-content-left-invoice .main-content-breadcrumb,
        .main-content-left-invoice .main-content-title {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    /* ###### Invoice  ###### */

    /* ###### Mail  ###### */
    .main-content-mail .container,
    .main-content-mail .container-fluid {
        padding: 20px 0 0;
    }

    @media (min-width: 992px) {
        .main-content-left-mail .btn-compose {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .main-task-menu {
        padding-left: 0;
        padding-right: inherit;
    }

    @media (min-width: 992px) {
        .main-task-menu {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    .main-mail-header > div:last-child > span {
        margin-left: 10px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .main-mail-header {
            padding: 20px 20px 0 20px;
        }
    }

    .main-mail-header .btn-group .btn + .btn,
    .main-mail-header .btn-group .sp-container button + .btn {
        margin-right: -2px;
        margin-left: inherit;
    }

    .sp-container .main-mail-header .btn-group button + .btn,
    .main-mail-header .btn-group .sp-container .btn + button,
    .sp-container .main-mail-header .btn-group .btn + button,
    .main-mail-header .btn-group .sp-container button + button,
    .sp-container .main-mail-header .btn-group button + button {
        margin-right: -2px;
        margin-left: inherit;
    }

    .main-mail-options {
        padding: 5px 20px 5px 10px;
        border-right-width: 0;
        border-left-width: 0;
    }

    .main-mail-item .main-img-user,
    .main-mail-item .main-avatar {
        margin-left: 15px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .main-mail-item {
            padding: 10px 1.5rem 10px 1.5rem;
        }
    }

    .main-mail-checkbox {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-mail-star {
        margin-left: 15px;
        margin-right: inherit;
        left: 0;
        right: inherit !important;
    }

    @media (min-width: 992px) {
        .main-mail-body {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .task-actions {
            float: left;
            margin-right: auto;
            margin-left: inherit;
        }
    }

    @media (max-width: 991px) {
        .mail-container .tab-list-items.task-menu-show,
        .mail-container .tab-list-items.mail-menu-show {
            padding-right: 27px;
            padding-left: inherit;
        }

        .task-actions {
            float: left;
            margin-right: 0;
            margin-left: inherit;
        }

        .task-actions li .task-action-items i {
            margin: 12px 0 13px 0;
        }
    }

    .main-mail-attachment {
        margin-left: 15px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .main-mail-attachment {
            left: 63px;
            right: inherit !important;
        }
    }

    .card-box img {
        right: -3px;
        left: inherit !important;
    }

    .main-mail-date {
        left: 15px;
        right: inherit !important;
        margin-right: auto;
        margin-left: inherit;
    }

    .main-mail-compose {
        right: 0;
        left: inherit !important;
    }

    .main-mail-compose-header {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .main-mail-compose-header .nav-link + .nav-link {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-mail-compose-body {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .main-mail-compose-body .form-group > div {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-mail-compose-body .form-group:last-child .btn,
    .main-mail-compose-body .form-group:last-child .sp-container button {
        padding-right: 20px;
        padding-left: 20px;
    }

    .sp-container .main-mail-compose-body .form-group:last-child button {
        padding-right: 20px;
        padding-left: 20px;
    }

    .main-mail-compose-body .form-group .nav-link + .nav-link {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-mail-compose-compress,
    .main-mail-compose-minimize {
        right: auto;
        left: 30px;
    }

    .main-mail-two .main-header-menu-icon {
        margin-left: 0;
        margin-right: inherit;
    }

    .main-mail-left {
        border-left: 1px solid #b4bdce;
        border-right: inherit !important;
    }

    .main-mail-left .main-task-menu {
        padding-left: 0;
        padding-right: inherit;
    }

    .main-mail-left .main-mail-menu {
        padding-left: 0;
        padding-right: inherit;
    }

    ul.inbox-pagination {
        float: left;
    }

    .inbox-pagination li span {
        margin-left: 5px;
        margin-right: inherit;
    }

    .inbox-pagination a.np-btn {
        margin-right: 5px;
        margin-left: inherit;
    }

    /* ###### Mail  ###### */

    /* ###### Profile  ###### */

    .profile-cover__action > .btn {
        margin-right: 10px;
        margin-left: inherit;
    }

    .profile-cover__img {
        right: 50px;
        left: inherit !important;
    }

    @media (min-width: 601px) {
        .profile-cover__info .nav {
            margin-left: 28px;
            margin-right: inherit;
            padding: 15px 170px 10px 0;
        }
    }

    .profile-cover__info .nav li:not(:first-child) {
        margin-right: 30px;
        margin-left: inherit;
        padding-right: 30px;
        padding-left: inherit;
        border-right: 1px solid $border;
        border-left: inherit !important;
    }

    @media (max-width: 600px) {
        .profile-cover__info .nav {
            padding-right: 0;
            padding-left: 0;
            margin-left: 28px;
            padding: 39px 170px 10px 0;
            margin-left: 0;
            margin-left: 0;
            margin-right: inherit;
            padding: 18px 0 10px 0;
        }

        .profile-cover__info .nav li {
            margin-left: 1rem;
            margin-right: inherit;
        }

        .profile-cover__info .nav li:not(:first-child) {
            margin-right: 0;
            margin-left: inherit;
            padding-right: 0;
            padding-left: inherit;
            border-right-width: 0;
            border-left-width: inherit !important;
        }

        .main-nav-line .nav-link + .nav-link {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .main-content-left-profile {
        padding-right: 0;
        padding-left: 0;
    }

    @media (min-width: 992px) {
        .main-content-left-profile {
            padding-left: 20px;
            padding-right: inherit;
            border-left: 1px solid $border;
            border-right: inherit !important;
        }
    }

    @media (min-width: 1200px) {
        .main-content-left-profile {
            padding-left: 25px;
            padding-right: inherit;
        }
    }

    .main-profile-social-list .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .main-content-body-profile .nav {
            padding-bottom: 10px !important;
        }
    }

    .main-content-body-profile .nav {
        padding: 20px 20px 20px 20px;
    }

    @media (min-width: 992px) {
        .main-content-body-profile .nav {
            padding-right: 20px;
        }
    }

    @media (min-width: 1200px) {
        .main-content-body-profile .nav {
            padding-right: 25px;
        }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        .main-content-body-profile .main-nav-line .nav-link.active::before {
            right: 0;
            left: 0;
        }
    }

    .main-profile-body {
        padding: 15px 0 0;
    }

    @media (min-width: 992px) {
        .main-profile-body {
            padding: 25px 20px 0 0;
        }
    }

    @media (min-width: 1200px) {
        .main-profile-body {
            padding-right: 25px;
            padding-left: inherit;
        }
    }

    .main-profile-view-info {
        right: 0;
        left: inherit !important;
    }

    .main-profile-view-info span {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-profile-work-list .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    .main-profile-contact-list .media-body {
        margin-right: 25px;
        margin-left: inherit;
    }

    .btn-profile {
        left: 50px;
        right: inherit !important;
    }

    @media (max-width: 767px) {
        .profile-cover__info .nav {
            margin-left: 0;
            margin-right: inherit;
            padding: 114px 0 10px;
        }

        .profile-cover__info .nav li {
            margin-left: 1rem;
            margin-right: inherit;
        }

        .profile-cover__info .nav li:not(:first-child) {
            margin-right: 0;
            margin-left: inherit;
            padding-right: 0;
            padding-left: inherit;
            border-right-width: 0;
            border-left-width: inherit !important;
        }

        .btn-profile {
            left: 0;
            right: 0 !important;
        }

        .main-nav-line .nav-link + .nav-link {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .profile-share {
        padding: 10px 22px 10px 22px;
        border-radius: 0 0 11px 11px;
    }

    .profile-edit .form-control {
        border-radius: 11px 11px 0 0;
    }

    .profile-info {
        right: 170px;
        left: inherit !important;
    }

    @media (min-width: 320px) and (max-width: 568px) {
        .profile-cover__img {
            right: 45px;
            left: inherit !important;
        }

        .profile-info {
            right: 130px;
            left: inherit !important;
        }
    }

    .profile-cover__img a {
        left: 1px;
        right: initial;
    }

    /* ###### Profile  ###### */

    /* ###### Cryptocurrencies  ###### */

    .card-bitcoin .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    .crypto-wallet .chart-circle-value {
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
    }

    .bg-background2:before {
        right: 0;
        left: 0;
    }

    .crypto-card .chart-wrapper {
        left: 0;
        right: -23px;
    }

    .exchange-icon {
        right: -51px;
        left: inherit !important;
    }

    .payment-list {
        padding-right: 0;
        padding-left: inherit;
    }

    .payment-list .payment-item {
        float: right;
    }

    .cryptoicon {
        margin-left: 15px;
        margin-right: inherit;
    }

    .payment-cards img {
        right: 0;
        left: 0;
    }

    .form input:checked + label:after,
    form input:checked + label:after {
        border-width: 0 0px 35px 35px;
        left: 0;
        right: inherit !important;
    }

    .form input:checked + label:after {
        border-width: 0 0px 35px 35px;
        left: 0;
        right: inherit !important;
    }

    .form input:checked + label:before {
        left: 4px;
        right: inherit !important;
    }

    form input:checked + label:before,
    .form input:checked + label:before {
        left: 4px;
        right: inherit !important;
    }

    .crypto-buysell-card .nav.panel-tabs li .active:before {
        right: 50%;
        left: inherit !important;
        margin-right: -10px;
        margin-left: inherit;
    }

    .wallet .input-group-addon-right,
    .wallet .input-group-addon {
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .crypto-wallet .clipboard-icon {
        border-radius: 11px 0 0 11px !important;
        left: 0;
        right: inherit !important;
    }

    .crypto-wallet .clipboard-icon:hover,
    .crypto-wallet .clipboard-icon:focus {
        border-radius: 4px 0 0 4px !important;
        left: 0;
        right: inherit !important;
    }

    .wallet .clipboard-icon {
        left: 0;
        right: inherit !important;
        border-radius: 6px 0 0 6px !important;
        border-right: 0;
        border-left: 1px solid $border;
    }

    .wallet .input-group-addon-left {
        border-radius: 0 6px 6px 0 !important;
        border-left: 0;
        border-right: 1px solid $border;
    }

    // .transcation-crypto .table.dataTable th,
    // .transcation-crypto .table.dataTable td {
    //     border-right: 0;
    //     border-left: inherit !important;
    // }

    .transcation-crypto table.dataTable thead .sorting:before,
    .transcation-crypto table.dataTable thead .sorting_asc:before,
    .transcation-crypto table.dataTable thead .sorting_desc:before,
    .transcation-crypto table.dataTable thead .sorting_asc_disabled:before,
    .transcation-crypto table.dataTable thead .sorting_desc_disabled:before {
        left: 1em;
        right: inherit !important;
    }

    .transcation-crypto table.dataTable thead .sorting:after,
    .transcation-crypto table.dataTable thead .sorting_asc:after,
    .transcation-crypto table.dataTable thead .sorting_desc:after {
        left: 0.5em;
        right: inherit !important;
    }

    table.dataTable thead .sorting_asc_disabled:after,
    .transcation-crypto table.dataTable thead .sorting_desc_disabled:after {
        left: 0.5em;
        right: inherit !important;
    }

    /* ###### Cryptocurrencies  ###### */

    /* ########## Ecommerce ########## */
    .ratio {
        left: -10px;
        right: inherit !important;
        border-radius: 0px 15px 15px 0px;
    }

    .product-timeline ul.timeline-1 > li {
        padding-right: 1rem;
        padding-left: inherit;
    }

    .product-timeline ul.timeline-1 > li .product-icon {
        right: 0px;
        left: inherit !important;
    }

    .product-timeline ul.timeline-1:before {
        margin: 20px 22px 0 20px;
        right: -2px;
        left: inherit !important;
        border-right: 2px dotted #c0ccda;
        border-left: inherit !important;
    }

    .countrywise-sale .list-group-item {
        border-right-width: 0;
        border-left-width: 0;
    }

    .countrywise-sale .list-group-item span {
        margin-right: auto;
        margin-left: inherit;
    }

    .countrywise-sale .list-group-item p {
        margin-right: 10px;
        margin-left: inherit;
    }

    .top-selling-products .product-img {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .top-selling-products .product-price {
        margin-right: auto;
        margin-left: inherit;
    }

    .top-selling-products .product-amount {
        float: left;
        margin-right: auto;
        margin-left: inherit;
    }

    .table-filter {
        padding: 5px 0 15px;
    }

    .table-filter select {
        margin-right: 5px;
        margin-left: inherit;
    }

    .table-filter .btn {
        border-radius: 4px 0 0 4px;
        margin-right: -1px;
        margin-left: inherit;
        float: left;
    }

    .table-filter input {
        margin-right: 5px;
        margin-left: inherit;
    }

    .status {
        margin: 2px 0 4px 6px;
    }

    .connecting-line {
        right: 0;
        left: 0;
    }

    #checkoutsteps .item .purchase {
        margin-right: 21px;
        margin-left: inherit;
    }

    #checkoutsteps .checkout span.heading {
        margin-left: 5px;
        margin-right: inherit;
    }

    #checkoutsteps .total {
        float: left;
    }

    .card-pay .tabs-menu.nav li a {
        border-left: 0;
        border-right: inherit !important;
    }

    .item1-links a i {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .track .step::before {
        right: 0;
        left: inherit !important;
    }

    .btn-apply.coupon {
        border-radius: 6px 0 0 6px !important;
    }

    .product-grid .product-image img {
        border-radius: 6px 6px 0 0;
    }

    .product-image .pic-2 {
        right: 0;
        left: inherit !important;
    }

    .product-grid:hover .product-image .pic-2 {
        right: 10px;
        left: inherit !important;
    }

    .product-grid .product-discount-label {
        right: 10px;
        left: inherit !important;
    }

    .product-grid:hover .product-like-icon {
        left: 10px;
        right: inherit !important;
    }

    .product-grid .product-link {
        right: 0;
        left: inherit !important;
    }

    .product-grid .product-link a {
        border-left: 2px solid rgba(215, 215, 215, 0.15);
        border-left: 0;
        border-right: inherit !important;
    }

    .product-grid .product-link a:nth-child(2n) {
        border-left: none;
        border-right: inherit !important;
    }

    .product-content .rating {
        text-align: right;
        padding-right: 0;
        padding-left: inherit;
    }

    .product-grid .title {
        margin: 0 0 5px;
    }

    .product-grid .product-content {
        text-align: right;
    }

    .product-grid .price {
        text-align: right;
    }

    .product-grid .price .old-price {
        margin-left: 5px;
        margin-right: inherit;
    }

    @media screen and (max-width: 990px) {
        .product-grid {
            margin: 0 0 30px;
        }
    }

    .products-badge {
        left: 0;
        right: inherit !important;
    }

    .products-badge .badge {
        float: right;
        right: -71px;
        left: inherit !important;
        transform: rotate(45deg);
    }

    .desc-tabs .nav.panel-tabs li a {
        padding: 10px 18px 10px 18px;
        margin: 0 0 5px 5px;
    }

    /* ########## Ecommerce ########## */

    /* ############### UTILITIES/HELPER CLASSES ############### */
    /* ###### Backgrounds ###### */

    .img-filter-primary:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-secondary:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-success:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-warning:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-danger:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-info:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-teal:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-purple:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-indigo:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-azure:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-dark:before {
        right: 0px;
        left: 0px;
    }

    .img-filter-pink:before {
        right: 0px;
        left: 0px;
    }

    /* ###### Backgrounds ###### */

    /* ######  Border   ###### */

    .border-end {
        border-left: 1px solid $border !important;
        border-right: inherit !important;
    }

    .border-start {
        border-right: 1px solid $border !important;
        border-left: inherit !important;
    }

    .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $border !important;
    }

    .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $border !important;
    }

    .rounded-top {
        border-top-right-radius: 3px !important;
        border-top-left-radius: 3px !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-end {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-bottom {
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-start {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-top-0 {
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    .rounded-end-0 {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .rounded-bottom-0 {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .rounded-start-0 {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .rounded-top-11 {
        border-top-right-radius: 11px !important;
        border-top-left-radius: 11px !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-end-11 {
        border-top-left-radius: 11px !important;
        border-bottom-left-radius: 11px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-bottom-11 {
        border-bottom-left-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .rounded-start-11 {
        border-top-right-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .bd-r {
        border-right: 1px solid $border;
    }

    .bd-l {
        border-left: 1px solid $border;
    }

    .bd-x {
        border-right: 1px solid $border;
        border-left: 1px solid $border;
    }

    .bd-0 {
        border-width: 0;
    }

    .bd-1 {
        border-width: 1px;
    }

    .bd-2 {
        border-width: 2px;
    }

    .bd-3 {
        border-width: 3px;
    }

    .bd-4 {
        border-width: 4px;
    }

    .bd-5 {
        border-width: 5px;
    }

    .bd-r-0 {
        border-right: 0 !important;
    }

    .bd-l-0 {
        border-left: 0 !important;
    }

    .bd-r-0-f {
        border-right: 0 !important;
    }

    .bd-l-0-f {
        border-left: 0 !important;
    }

    .bd-x-0 {
        border-right-width: 0;
        border-left-width: 0;
    }

    .bd-x-1 {
        border-right-width: 1px;
        border-left-width: 1px;
    }

    @media (min-width: 480px) {
        .bd-xs-r {
            border-right: 1px solid $border;
        }

        .bd-xs-l {
            border-left: 1px solid $border;
        }

        .bd-xs-x {
            border-right: 1px solid $border;
            border-left: 1px solid $border;
        }
    }

    @media (min-width: 576px) {
        .bd-sm-r {
            border-right: 1px solid $border;
        }

        .bd-sm-l {
            border-left: 1px solid $border;
        }

        .bd-sm-x {
            border-right: 1px solid $border;
            border-left: 1px solid $border;
        }
    }

    @media (min-width: 768px) {
        .bd-md-r {
            border-right: 1px solid $border;
        }

        .bd-md-l {
            border-left: 1px solid $border;
        }

        .bd-md-x {
            border-right: 1px solid $border;
            border-left: 1px solid $border;
        }
    }

    @media (min-width: 992px) {
        .bd-lg-r {
            border-right: 1px solid $border;
        }

        .bd-lg-l {
            border-left: 1px solid $border;
        }

        .bd-lg-x {
            border-right: 1px solid $border;
            border-left: 1px solid $border;
        }
    }

    @media (min-width: 1200px) {
        .bd-xl-r {
            border-right: 1px solid $border;
        }

        .bd-xl-l {
            border-left: 1px solid $border;
        }

        .bd-xl-x {
            border-right: 1px solid $border;
            border-left: 1px solid $border;
        }
    }

    /* ###### Border ###### */

    /* ###### Margin ###### */

    .mg-x-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .mg-r-0 {
        margin-left: 0px;
        margin-right: inherit;
    }

    .mg-l-0 {
        margin-right: 0px;
        margin-left: inherit;
    }

    .mg-x-1 {
        margin-right: 1px;
        margin-left: 1px;
    }

    .mg-r-1 {
        margin-left: 1px;
        margin-right: inherit;
    }

    .mg-l-1 {
        margin-right: 1px;
        margin-left: inherit;
    }

    .mg-x-2 {
        margin-right: 2px;
        margin-left: 2px;
    }

    .mg-r-2 {
        margin-left: 2px;
        margin-right: inherit;
    }

    .mg-l-2 {
        margin-right: 2px;
        margin-left: inherit;
    }

    .mg-x-3 {
        margin-right: 3px;
        margin-left: 3px;
    }

    .mg-r-3 {
        margin-left: 3px;
        margin-right: inherit;
    }

    .mg-l-3 {
        margin-right: 3px;
        margin-left: inherit;
    }

    .mg-x-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .mg-r-4 {
        margin-left: 4px;
        margin-right: inherit;
    }

    .mg-l-4 {
        margin-right: 4px;
        margin-left: inherit;
    }

    .mg-x-5 {
        margin-right: 5px;
        margin-left: 5px;
    }

    .mg-r-5 {
        margin-left: 5px;
        margin-right: inherit;
    }

    .mg-l-5 {
        margin-right: 5px;
        margin-left: inherit;
    }

    .mg-x-6 {
        margin-right: 6px;
        margin-left: 6px;
    }

    .mg-r-6 {
        margin-left: 6px;
        margin-right: inherit;
    }

    .mg-l-6 {
        margin-right: 6px;
        margin-left: inherit;
    }

    .mg-x-7 {
        margin-right: 7px;
        margin-left: 7px;
    }

    .mg-r-7 {
        margin-left: 7px;
        margin-right: inherit;
    }

    .mg-l-7 {
        margin-right: 7px;
        margin-left: inherit;
    }

    .mg-x-8 {
        margin-right: 8px;
        margin-left: 8px;
    }

    .mg-r-8 {
        margin-left: 8px;
        margin-right: inherit;
    }

    .mg-l-8 {
        margin-right: 8px;
        margin-left: inherit;
    }

    .mg-x-9 {
        margin-right: 9px;
        margin-left: 9px;
    }

    .mg-r-9 {
        margin-left: 9px;
        margin-right: inherit;
    }

    .mg-l-9 {
        margin-right: 9px;
        margin-left: inherit;
    }

    .mg-x-10 {
        margin-right: 10px;
        margin-left: 10px;
    }

    .mg-r-10 {
        margin-left: 10px;
        margin-right: inherit;
    }

    .mg-l-10 {
        margin-right: 10px;
        margin-left: inherit;
    }

    .mg-x-15 {
        margin-right: 15px;
        margin-left: 15px;
    }

    .mg-r-15 {
        margin-left: 15px;
        margin-right: inherit;
    }

    .mg-l-15 {
        margin-right: 15px;
        margin-left: inherit;
    }

    .mg-x-20 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .mg-r-20 {
        margin-left: 20px;
        margin-right: inherit;
    }

    .mg-l-20 {
        margin-right: 20px;
        margin-left: inherit;
    }

    .mg-x-25 {
        margin-right: 25px;
        margin-left: 25px;
    }

    .mg-r-25 {
        margin-left: 25px;
        margin-right: inherit;
    }

    .mg-l-25 {
        margin-right: 25px;
        margin-left: inherit;
    }

    .mg-x-30 {
        margin-right: 30px;
        margin-left: 30px;
    }

    .mg-r-30 {
        margin-left: 30px;
        margin-right: inherit;
    }

    .mg-l-30 {
        margin-right: 30px;
        margin-left: inherit;
    }

    .mg-x-35 {
        margin-right: 35px;
        margin-left: 35px;
    }

    .mg-r-35 {
        margin-left: 35px;
        margin-right: inherit;
    }

    .mg-l-35 {
        margin-right: 35px;
        margin-left: inherit;
    }

    .mg-x-40 {
        margin-right: 40px;
        margin-left: 40px;
    }

    .mg-r-40 {
        margin-left: 40px;
        margin-right: inherit;
    }

    .mg-l-40 {
        margin-right: 40px;
        margin-left: inherit;
    }

    .mg-x-45 {
        margin-right: 45px;
        margin-left: 45px;
    }

    .mg-r-45 {
        margin-left: 45px;
        margin-right: inherit;
    }

    .mg-l-45 {
        margin-right: 45px;
        margin-left: inherit;
    }

    .mg-x-50 {
        margin-right: 50px;
        margin-left: 50px;
    }

    .mg-r-50 {
        margin-left: 50px;
        margin-right: inherit;
    }

    .mg-l-50 {
        margin-right: 50px;
        margin-left: inherit;
    }

    .mg-x-55 {
        margin-right: 55px;
        margin-left: 55px;
    }

    .mg-r-55 {
        margin-left: 55px;
        margin-right: inherit;
    }

    .mg-l-55 {
        margin-right: 55px;
        margin-left: inherit;
    }

    .mg-x-60 {
        margin-right: 60px;
        margin-left: 60px;
    }

    .mg-r-60 {
        margin-left: 60px;
        margin-right: inherit;
    }

    .mg-l-60 {
        margin-right: 60px;
        margin-left: inherit;
    }

    .mg-x-65 {
        margin-right: 65px;
        margin-left: 65px;
    }

    .mg-r-65 {
        margin-left: 65px;
        margin-right: inherit;
    }

    .mg-l-65 {
        margin-right: 65px;
        margin-left: inherit;
    }

    .mg-x-70 {
        margin-right: 70px;
        margin-left: 70px;
    }

    .mg-r-70 {
        margin-left: 70px;
        margin-right: inherit;
    }

    .mg-l-70 {
        margin-right: 70px;
        margin-left: inherit;
    }

    .mg-x-75 {
        margin-right: 75px;
        margin-left: 75px;
    }

    .mg-r-75 {
        margin-left: 75px;
        margin-right: inherit;
    }

    .mg-l-75 {
        margin-right: 75px;
        margin-left: inherit;
    }

    .mg-x-80 {
        margin-right: 80px;
        margin-left: 80px;
    }

    .mg-r-80 {
        margin-left: 80px;
        margin-right: inherit;
    }

    .mg-l-80 {
        margin-right: 80px;
        margin-left: inherit;
    }

    .mg-x-85 {
        margin-right: 85px;
        margin-left: 85px;
    }

    .mg-r-85 {
        margin-left: 85px;
        margin-right: inherit;
    }

    .mg-l-85 {
        margin-right: 85px;
        margin-left: inherit;
    }

    .mg-x-90 {
        margin-right: 90px;
        margin-left: 90px;
    }

    .mg-r-90 {
        margin-left: 90px;
        margin-right: inherit;
    }

    .mg-l-90 {
        margin-right: 90px;
        margin-left: inherit;
    }

    .mg-x-95 {
        margin-right: 95px;
        margin-left: 95px;
    }

    .mg-r-95 {
        margin-left: 95px;
        margin-right: inherit;
    }

    .mg-l-95 {
        margin-right: 95px;
        margin-left: inherit;
    }

    .mg-x-100 {
        margin-right: 100px;
        margin-left: 100px;
    }

    .mg-r-100 {
        margin-left: 100px;
        margin-right: inherit;
    }

    .mg-l-100 {
        margin-right: 100px;
        margin-left: inherit;
    }

    .mg-x-105 {
        margin-right: 105px;
        margin-left: 105px;
    }

    .mg-r-105 {
        margin-left: 105px;
        margin-right: inherit;
    }

    .mg-l-105 {
        margin-right: 105px;
        margin-left: inherit;
    }

    .mg-x-110 {
        margin-right: 110px;
        margin-left: 110px;
    }

    .mg-r-110 {
        margin-left: 110px;
        margin-right: inherit;
    }

    .mg-l-110 {
        margin-right: 110px;
        margin-left: inherit;
    }

    .mg-x-115 {
        margin-right: 115px;
        margin-left: 115px;
    }

    .mg-r-115 {
        margin-left: 115px;
        margin-right: inherit;
    }

    .mg-l-115 {
        margin-right: 115px;
        margin-left: inherit;
    }

    .mg-x-120 {
        margin-right: 120px;
        margin-left: 120px;
    }

    .mg-r-120 {
        margin-left: 120px;
        margin-right: inherit;
    }

    .mg-l-120 {
        margin-right: 120px;
        margin-left: inherit;
    }

    .mg-r-auto {
        margin-left: auto;
        margin-right: inherit;
    }

    .mg-l-auto {
        margin-right: auto;
        margin-left: inherit;
    }

    @media (min-width: 480px) {
        .mg-xs-x-0 {
            margin-right: 0px;
            margin-left: 0px;
        }

        .mg-xs-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-xs-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-xs-x-1 {
            margin-right: 1px;
            margin-left: 1px;
        }

        .mg-xs-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-xs-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-xs-x-2 {
            margin-right: 2px;
            margin-left: 2px;
        }

        .mg-xs-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-xs-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-xs-x-3 {
            margin-right: 3px;
            margin-left: 3px;
        }

        .mg-xs-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-xs-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-xs-x-4 {
            margin-right: 4px;
            margin-left: 4px;
        }

        .mg-xs-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-xs-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-xs-x-5 {
            margin-right: 5px;
            margin-left: 5px;
        }

        .mg-xs-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-xs-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-xs-x-6 {
            margin-right: 6px;
            margin-left: 6px;
        }

        .mg-xs-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-xs-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-xs-x-7 {
            margin-right: 7px;
            margin-left: 7px;
        }

        .mg-xs-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-xs-x-8 {
            margin-right: 8px;
            margin-left: 8px;
        }

        .mg-xs-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-xs-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-xs-x-9 {
            margin-right: 9px;
            margin-left: 9px;
        }

        .mg-xs-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-xs-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-xs-x-10 {
            margin-right: 10px;
            margin-left: 10px;
        }

        .mg-xs-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-xs-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-xs-x-15 {
            margin-right: 15px;
            margin-left: 15px;
        }

        .mg-xs-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-xs-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-xs-x-20 {
            margin-right: 20px;
            margin-left: 20px;
        }

        .mg-xs-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-xs-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-xs-x-25 {
            margin-right: 25px;
            margin-left: 25px;
        }

        .mg-xs-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-xs-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-xs-x-30 {
            margin-right: 30px;
            margin-left: 30px;
        }

        .mg-xs-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-xs-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-xs-x-35 {
            margin-right: 35px;
            margin-left: 35px;
        }

        .mg-xs-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-xs-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-xs-x-40 {
            margin-right: 40px;
            margin-left: 40px;
        }

        .mg-xs-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-xs-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-xs-x-45 {
            margin-right: 45px;
            margin-left: 45px;
        }

        .mg-xs-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-xs-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-xs-x-50 {
            margin-right: 50px;
            margin-left: 50px;
        }

        .mg-xs-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-xs-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-xs-x-55 {
            margin-right: 55px;
            margin-left: 55px;
        }

        .mg-xs-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-xs-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-xs-x-60 {
            margin-right: 60px;
            margin-left: 60px;
        }

        .mg-xs-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-xs-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-xs-x-65 {
            margin-right: 65px;
            margin-left: 65px;
        }

        .mg-xs-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-xs-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-xs-x-70 {
            margin-right: 70px;
            margin-left: 70px;
        }

        .mg-xs-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-xs-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-xs-x-75 {
            margin-right: 75px;
            margin-left: 75px;
        }

        .mg-xs-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-xs-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-xs-x-80 {
            margin-right: 80px;
            margin-left: 80px;
        }

        .mg-xs-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-xs-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-xs-x-85 {
            margin-right: 85px;
            margin-left: 85px;
        }

        .mg-xs-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-xs-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-xs-x-90 {
            margin-right: 90px;
            margin-left: 90px;
        }

        .mg-xs-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-xs-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-xs-x-95 {
            margin-right: 95px;
            margin-left: 95px;
        }

        .mg-xs-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-xs-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-xs-x-100 {
            margin-right: 100px;
            margin-left: 100px;
        }

        .mg-xs-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-xs-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-xs-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-xs-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }

        .mg-xs-x-auto {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media (min-width: 576px) {
        .mg-sm-x-0 {
            margin-right: 0px;
            margin-left: 0px;
        }

        .mg-sm-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-sm-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-sm-x-1 {
            margin-right: 1px;
            margin-left: 1px;
        }

        .mg-sm-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-sm-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-sm-x-2 {
            margin-right: 2px;
            margin-left: 2px;
        }

        .mg-sm-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-sm-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-sm-x-3 {
            margin-right: 3px;
            margin-left: 3px;
        }

        .mg-sm-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-sm-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-sm-x-4 {
            margin-right: 4px;
            margin-left: 4px;
        }

        .mg-sm-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-sm-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-sm-x-5 {
            margin-right: 5px;
            margin-left: 5px;
        }

        .mg-sm-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-sm-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-sm-x-6 {
            margin-right: 6px;
            margin-left: 6px;
        }

        .mg-sm-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-sm-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-sm-x-7 {
            margin-right: 7px;
            margin-left: 7px;
        }

        .mg-sm-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-sm-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-sm-x-8 {
            margin-right: 8px;
            margin-left: 8px;
        }

        .mg-sm-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-sm-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-sm-x-9 {
            margin-right: 9px;
            margin-left: 9px;
        }

        .mg-sm-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-sm-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-sm-x-10 {
            margin-right: 10px;
            margin-left: 10px;
        }

        .mg-sm-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-sm-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-sm-x-15 {
            margin-right: 15px;
            margin-left: 15px;
        }

        .mg-sm-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-sm-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-sm-x-20 {
            margin-right: 20px;
            margin-left: 20px;
        }

        .mg-sm-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-sm-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-sm-x-25 {
            margin-right: 25px;
            margin-left: 25px;
        }

        .mg-sm-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-sm-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-sm-x-30 {
            margin-right: 30px;
            margin-left: 30px;
        }

        .mg-sm-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-sm-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-sm-x-35 {
            margin-right: 35px;
            margin-left: 35px;
        }

        .mg-sm-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-sm-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-sm-x-40 {
            margin-right: 40px;
            margin-left: 40px;
        }

        .mg-sm-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-sm-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-sm-x-45 {
            margin-right: 45px;
            margin-left: 45px;
        }

        .mg-sm-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-sm-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-sm-x-50 {
            margin-right: 50px;
            margin-left: 50px;
        }

        .mg-sm-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-sm-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-sm-x-55 {
            margin-right: 55px;
            margin-left: 55px;
        }

        .mg-sm-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-sm-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-sm-x-60 {
            margin-right: 60px;
            margin-left: 60px;
        }

        .mg-sm-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-sm-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-sm-x-65 {
            margin-right: 65px;
            margin-left: 65px;
        }

        .mg-sm-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-sm-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-sm-x-70 {
            margin-right: 70px;
            margin-left: 70px;
        }

        .mg-sm-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-sm-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-sm-x-75 {
            margin-right: 75px;
            margin-left: 75px;
        }

        .mg-sm-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-sm-x-80 {
            margin-right: 80px;
            margin-left: 80px;
        }

        .mg-sm-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-sm-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-sm-x-85 {
            margin-right: 85px;
            margin-left: 85px;
        }

        .mg-sm-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-sm-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-sm-x-90 {
            margin-right: 90px;
            margin-left: 90px;
        }

        .mg-sm-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-sm-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-sm-x-95 {
            margin-right: 95px;
            margin-left: 95px;
        }

        .mg-sm-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-sm-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-sm-x-100 {
            margin-right: 100px;
            margin-left: 100px;
        }

        .mg-sm-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-sm-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-sm-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-sm-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }

        .mg-sm-x-auto {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media (min-width: 768px) {
        .mg-md-x-0 {
            margin-right: 0px;
            margin-left: 0px;
        }

        .mg-md-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-md-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-md-x-1 {
            margin-right: 1px;
            margin-left: 1px;
        }

        .mg-md-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-md-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-md-x-2 {
            margin-right: 2px;
            margin-left: 2px;
        }

        .mg-md-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-md-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-md-x-3 {
            margin-right: 3px;
            margin-left: 3px;
        }

        .mg-md-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-md-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-md-x-4 {
            margin-right: 4px;
            margin-left: 4px;
        }

        .mg-md-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-md-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-md-x-5 {
            margin-right: 5px;
            margin-left: 5px;
        }

        .mg-md-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-md-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-md-x-6 {
            margin-right: 6px;
            margin-left: 6px;
        }

        .mg-md-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-md-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-md-x-7 {
            margin-right: 7px;
            margin-left: 7px;
        }

        .mg-md-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-md-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-md-x-8 {
            margin-right: 8px;
            margin-left: 8px;
        }

        .mg-md-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-md-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-md-x-9 {
            margin-right: 9px;
            margin-left: 9px;
        }

        .mg-md-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-md-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-md-x-10 {
            margin-right: 10px;
            margin-left: 10px;
        }

        .mg-md-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-md-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-md-x-15 {
            margin-right: 15px;
            margin-left: 15px;
        }

        .mg-md-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-md-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-md-x-20 {
            margin-right: 20px;
            margin-left: 20px;
        }

        .mg-md-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-md-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-md-x-25 {
            margin-right: 25px;
            margin-left: 25px;
        }

        .mg-md-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-md-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-md-x-30 {
            margin-right: 30px;
            margin-left: 30px;
        }

        .mg-md-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-md-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-md-x-35 {
            margin-right: 35px;
            margin-left: 35px;
        }

        .mg-md-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-md-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-md-x-40 {
            margin-right: 40px;
            margin-left: 40px;
        }

        .mg-md-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-md-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-md-x-45 {
            margin-right: 45px;
            margin-left: 45px;
        }

        .mg-md-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-md-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-md-x-50 {
            margin-right: 50px;
            margin-left: 50px;
        }

        .mg-md-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-md-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-md-x-55 {
            margin-right: 55px;
            margin-left: 55px;
        }

        .mg-md-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-md-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-md-x-60 {
            margin-right: 60px;
            margin-left: 60px;
        }

        .mg-md-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-md-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-md-x-65 {
            margin-right: 65px;
            margin-left: 65px;
        }

        .mg-md-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-md-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-md-x-70 {
            margin-right: 70px;
            margin-left: 70px;
        }

        .mg-md-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-md-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-md-x-75 {
            margin-right: 75px;
            margin-left: 75px;
        }

        .mg-md-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-md-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-md-x-80 {
            margin-right: 80px;
            margin-left: 80px;
        }

        .mg-md-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-md-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-md-x-85 {
            margin-right: 85px;
            margin-left: 85px;
        }

        .mg-md-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-md-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-md-x-90 {
            margin-right: 90px;
            margin-left: 90px;
        }

        .mg-md-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-md-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-md-x-95 {
            margin-right: 95px;
            margin-left: 95px;
        }

        .mg-md-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-md-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-md-x-100 {
            margin-right: 100px;
            margin-left: 100px;
        }

        .mg-md-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-md-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-md-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-md-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }

        .mg-md-x-auto {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media (min-width: 992px) {
        .mg-lg-x-0 {
            margin-right: 0px;
            margin-left: 0px;
        }

        .mg-lg-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-lg-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-lg-x-1 {
            margin-right: 1px;
            margin-left: 1px;
        }

        .mg-lg-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-lg-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-lg-x-2 {
            margin-right: 2px;
            margin-left: 2px;
        }

        .mg-lg-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-lg-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-lg-x-3 {
            margin-right: 3px;
            margin-left: 3px;
        }

        .mg-lg-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-lg-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-lg-x-4 {
            margin-right: 4px;
            margin-left: 4px;
        }

        .mg-lg-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-lg-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-lg-x-5 {
            margin-right: 5px;
            margin-left: 5px;
        }

        .mg-lg-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-lg-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-lg-x-6 {
            margin-right: 6px;
            margin-left: 6px;
        }

        .mg-lg-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-lg-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-lg-x-7 {
            margin-right: 7px;
            margin-left: 7px;
        }

        .mg-lg-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-lg-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-lg-x-8 {
            margin-right: 8px;
            margin-left: 8px;
        }

        .mg-lg-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-lg-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-lg-x-9 {
            margin-right: 9px;
            margin-left: 9px;
        }

        .mg-lg-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-lg-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-lg-x-10 {
            margin-right: 10px;
            margin-left: 10px;
        }

        .mg-lg-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-lg-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-lg-x-15 {
            margin-right: 15px;
            margin-left: 15px;
        }

        .mg-lg-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-lg-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-lg-x-20 {
            margin-right: 20px;
            margin-left: 20px;
        }

        .mg-lg-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-lg-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-lg-x-25 {
            margin-right: 25px;
            margin-left: 25px;
        }

        .mg-lg-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-lg-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-lg-x-30 {
            margin-right: 30px;
            margin-left: 30px;
        }

        .mg-lg-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-lg-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-lg-x-35 {
            margin-right: 35px;
            margin-left: 35px;
        }

        .mg-lg-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-lg-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-lg-x-40 {
            margin-right: 40px;
            margin-left: 40px;
        }

        .mg-lg-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-lg-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-lg-x-45 {
            margin-right: 45px;
            margin-left: 45px;
        }

        .mg-lg-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-lg-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-lg-x-50 {
            margin-right: 50px;
            margin-left: 50px;
        }

        .mg-lg-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-lg-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-lg-x-55 {
            margin-right: 55px;
            margin-left: 55px;
        }

        .mg-lg-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-lg-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-lg-x-60 {
            margin-right: 60px;
            margin-left: 60px;
        }

        .mg-lg-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-lg-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-lg-x-65 {
            margin-right: 65px;
            margin-left: 65px;
        }

        .mg-lg-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-lg-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-lg-x-70 {
            margin-right: 70px;
            margin-left: 70px;
        }

        .mg-lg-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-lg-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-lg-x-75 {
            margin-right: 75px;
            margin-left: 75px;
        }

        .mg-lg-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-lg-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-lg-x-80 {
            margin-right: 80px;
            margin-left: 80px;
        }

        .mg-lg-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-lg-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-lg-x-85 {
            margin-right: 85px;
            margin-left: 85px;
        }

        .mg-lg-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-lg-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-lg-x-90 {
            margin-right: 90px;
            margin-left: 90px;
        }

        .mg-lg-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-lg-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-lg-x-95 {
            margin-right: 95px;
            margin-left: 95px;
        }

        .mg-lg-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-lg-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-lg-x-100 {
            margin-right: 100px;
            margin-left: 100px;
        }

        .mg-lg-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-lg-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-lg-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-lg-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }

        .mg-lg-x-auto {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media (min-width: 1200px) {
        .mg-xl-x-0 {
            margin-right: 0px;
            margin-left: 0px;
        }

        .mg-xl-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-xl-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-xl-x-1 {
            margin-right: 1px;
            margin-left: 1px;
        }

        .mg-xl-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-xl-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-xl-x-2 {
            margin-right: 2px;
            margin-left: 2px;
        }

        .mg-xl-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-xl-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-xl-x-3 {
            margin-right: 3px;
            margin-left: 3px;
        }

        .mg-xl-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-xl-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-xl-x-4 {
            margin-right: 4px;
            margin-left: 4px;
        }

        .mg-xl-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-xl-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-xl-x-5 {
            margin-right: 5px;
            margin-left: 5px;
        }

        .mg-xl-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-xl-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-xl-x-6 {
            margin-right: 6px;
            margin-left: 6px;
        }

        .mg-xl-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-xl-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-xl-x-7 {
            margin-right: 7px;
            margin-left: 7px;
        }

        .mg-xl-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-xl-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-xl-x-8 {
            margin-right: 8px;
            margin-left: 8px;
        }

        .mg-xl-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-xl-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-xl-x-9 {
            margin-right: 9px;
            margin-left: 9px;
        }

        .mg-xl-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-xl-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-xl-x-10 {
            margin-right: 10px;
            margin-left: 10px;
        }

        .mg-xl-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-xl-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-xl-x-15 {
            margin-right: 15px;
            margin-left: 15px;
        }

        .mg-xl-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-xl-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-xl-x-20 {
            margin-right: 20px;
            margin-left: 20px;
        }

        .mg-xl-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-xl-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-xl-x-25 {
            margin-right: 25px;
            margin-left: 25px;
        }

        .mg-xl-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-xl-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-xl-x-30 {
            margin-right: 30px;
            margin-left: 30px;
        }

        .mg-xl-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-xl-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-xl-x-35 {
            margin-right: 35px;
            margin-left: 35px;
        }

        .mg-xl-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-xl-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-xl-x-40 {
            margin-right: 40px;
            margin-left: 40px;
        }

        .mg-xl-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-xl-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-xl-x-45 {
            margin-right: 45px;
            margin-left: 45px;
        }

        .mg-xl-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-xl-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-xl-x-50 {
            margin-right: 50px;
            margin-left: 50px;
        }

        .mg-xl-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-xl-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-xl-x-55 {
            margin-right: 55px;
            margin-left: 55px;
        }

        .mg-xl-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-xl-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-xl-x-60 {
            margin-right: 60px;
            margin-left: 60px;
        }

        .mg-xl-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-xl-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-xl-x-65 {
            margin-right: 65px;
            margin-left: 65px;
        }

        .mg-xl-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-xl-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-xl-x-70 {
            margin-right: 70px;
            margin-left: 70px;
        }

        .mg-xl-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-xl-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-xl-x-75 {
            margin-right: 75px;
            margin-left: 75px;
        }

        .mg-xl-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-xl-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-xl-x-80 {
            margin-right: 80px;
            margin-left: 80px;
        }

        .mg-xl-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-xl-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-xl-x-85 {
            margin-right: 85px;
            margin-left: 85px;
        }

        .mg-xl-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-xl-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-xl-x-90 {
            margin-right: 90px;
            margin-left: 90px;
        }

        .mg-xl-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-xl-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-xl-x-95 {
            margin-right: 95px;
            margin-left: 95px;
        }

        .mg-xl-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-xl-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-xl-x-100 {
            margin-right: 100px;
            margin-left: 100px;
        }

        .mg-xl-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-xl-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-xl-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-xl-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }

        .mg-xl-x-auto {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .me-0 {
        margin-left: 0 !important;
        margin-right: inherit !important;
    }

    .ms-0 {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    .mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: inherit !important;
    }

    .ms-1 {
        margin-right: 0.25rem !important;
        margin-left: inherit !important;
    }

    .mx-1 {
        margin-right: 1 !important;
        margin-left: 1 !important;
    }

    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: initial !important;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: initial !important;
    }

    .mx-2 {
        margin-right: 2 !important;
        margin-left: 2 !important;
    }

    .me-3 {
        margin-left: 1rem !important;
        margin-right: initial !important;
    }

    .ms-3 {
        margin-right: 1rem !important;
        margin-left: inherit !important;
    }

    .mx-3 {
        margin-right: 3 !important;
        margin-left: 3 !important;
    }

    .me-4 {
        margin-left: 1.5rem !important;
        margin-right: inherit !important;
    }

    .ms-4 {
        margin-right: 1.5rem !important;
        margin-left: inherit !important;
    }

    .mx-4 {
        margin-right: 4 !important;
        margin-left: 4 !important;
    }

    .me-5 {
        margin-left: 3rem !important;
        margin-right: inherit !important;
    }

    .ms-5 {
        margin-right: 3rem !important;
        margin-left: inherit !important;
    }

    .mx-6 {
        margin-right: 6 !important;
        margin-left: 6 !important;
    }

    .pe-0 {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .ps-0 {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .pe-1 {
        padding-left: 0.25rem !important;
        padding-right: inherit !important;
    }

    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: inherit !important;
    }

    .px-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: inherit !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }

    .px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .pe-3 {
        padding-left: 1rem !important;
        padding-right: inherit !important;
    }

    .ps-3 {
        padding-right: 1rem !important;
        padding-left: inherit !important;
    }

    .px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .pe-4 {
        padding-left: 1.5rem !important;
        padding-right: inherit !important;
    }

    .ps-4 {
        padding-right: 1.5rem !important;
        padding-left: inherit !important;
    }

    .px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .pe-5 {
        padding-left: 3rem !important;
        padding-right: inherit !important;
    }

    .ps-5 {
        padding-right: 3rem !important;
        padding-left: inherit !important;
    }

    .px-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .me-n1 {
        margin-left: -0.25rem !important;
        margin-right: inherit !important;
    }

    .ms-n1 {
        margin-right: -0.25rem !important;
        margin-left: inherit !important;
    }

    .mx-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .me-n2 {
        margin-left: -0.5rem !important;
        margin-right: inherit !important;
    }

    .ms-n2 {
        margin-right: -0.5rem !important;
        margin-left: inherit !important;
    }

    .mx-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .me-n3 {
        margin-left: -1rem !important;
        margin-right: inherit !important;
    }

    .ms-n3 {
        margin-right: -1rem !important;
        margin-left: inherit !important;
    }

    .mx-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .me-n4 {
        margin-left: -1.5rem !important;
        margin-right: inherit !important;
    }

    .ms-n4 {
        margin-right: -1.5rem !important;
        margin-left: inherit !important;
    }

    .mx-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }

    .me-n5 {
        margin-left: -3rem !important;
        margin-right: inherit !important;
    }

    .ms-n5 {
        margin-right: -3rem !important;
        margin-left: inherit !important;
    }

    .mx-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: inherit !important;
    }

    .mx-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .mx-sm-0 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: 0.25rem !important;
        }

        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important;
        }

        .me-sm-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-3 {
            margin-right: 1rem !important;
            margin-left: 1rem !important;
        }

        .me-sm-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-4 {
            margin-right: 1.5rem !important;
            margin-left: 1.5rem !important;
        }

        .me-sm-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-5 {
            margin-right: 3rem !important;
            margin-left: 3rem !important;
        }

        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .px-sm-0 {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .px-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: 0.25rem !important;
        }

        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .px-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }

        .pe-sm-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .px-sm-3 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

        .pe-sm-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .px-sm-4 {
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }

        .pe-sm-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .px-sm-5 {
            padding-right: 3rem !important;
            padding-left: 3rem !important;
        }

        .me-sm-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-n1 {
            margin-right: -0.25rem !important;
            margin-left: -0.25rem !important;
        }

        .me-sm-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-n2 {
            margin-right: -0.5rem !important;
            margin-left: -0.5rem !important;
        }

        .me-sm-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-n3 {
            margin-right: -1rem !important;
            margin-left: -1rem !important;
        }

        .me-sm-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-n4 {
            margin-right: -1.5rem !important;
            margin-left: -1.5rem !important;
        }

        .me-sm-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .mx-sm-n5 {
            margin-right: -3rem !important;
            margin-left: -3rem !important;
        }

        .me-sm-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .mx-sm-auto {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .mx-md-0 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-md-1 {
            margin-right: 0.25rem !important;
            margin-left: 0.25rem !important;
        }

        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-md-2 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important;
        }

        .me-md-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .mx-md-3 {
            margin-right: 1rem !important;
            margin-left: 1rem !important;
        }

        .me-md-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-md-4 {
            margin-right: 1.5rem !important;
            margin-left: 1.5rem !important;
        }

        .me-md-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .mx-md-5 {
            margin-right: 3rem !important;
            margin-left: 3rem !important;
        }

        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .px-md-0 {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .px-md-1 {
            padding-right: 0.25rem !important;
            padding-left: 0.25rem !important;
        }

        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .px-md-2 {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }

        .pe-md-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-md-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .px-md-3 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

        .pe-md-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .px-md-4 {
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }

        .pe-md-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-md-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .px-md-5 {
            padding-right: 3rem !important;
            padding-left: 3rem !important;
        }

        .me-md-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-md-n1 {
            margin-right: -0.25rem !important;
            margin-left: -0.25rem !important;
        }

        .me-md-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-md-n2 {
            margin-right: -0.5rem !important;
            margin-left: -0.5rem !important;
        }

        .me-md-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .mx-md-n3 {
            margin-right: -1rem !important;
            margin-left: -1rem !important;
        }

        .me-md-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-md-n4 {
            margin-right: -1.5rem !important;
            margin-left: -1.5rem !important;
        }

        .me-md-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .mx-md-n5 {
            margin-right: -3rem !important;
            margin-left: -3rem !important;
        }

        .me-md-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-md-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .mx-md-auto {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .mx-lg-0 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .me-lg-1,
        .mx-lg-1 {
            margin-left: 0.25rem !important;
        }

        .ms-lg-1,
        .mx-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: 0.25rem !important;
        }

        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important;
        }

        .me-lg-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-3 {
            margin-right: 1rem !important;
            margin-left: 1rem !important;
        }

        .me-lg-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-4 {
            margin-right: 1.5rem !important;
            margin-left: 1.5rem !important;
        }

        .me-lg-5,
        .mx-lg-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-5,
        .mx-lg-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-5 {
            margin-right: 3rem !important;
            margin-left: 3rem !important;
        }

        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .px-lg-0 {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .px-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: 0.25rem !important;
        }

        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .px-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }

        .pe-lg-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .px-lg-3 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

        .pe-lg-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .px-lg-4 {
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }

        .pe-lg-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .px-lg-5 {
            padding-right: 3rem !important;
            padding-left: 3rem !important;
        }

        .me-lg-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-n1 {
            margin-right: -0.25rem !important;
            margin-left: -0.25rem !important;
        }

        .me-lg-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-n2 {
            margin-right: -0.5rem !important;
            margin-left: -0.5rem !important;
        }

        .me-lg-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-n3 {
            margin-right: -1rem !important;
            margin-left: -1rem !important;
        }

        .me-lg-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-n4 {
            margin-right: -1.5rem !important;
            margin-left: -1.5rem !important;
        }

        .me-lg-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .mx-lg-n5 {
            margin-right: -3rem !important;
            margin-left: -3rem !important;
        }

        .me-lg-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .mx-lg-auto {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    @media (min-width: 1200px) {
        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .mx-xl-0 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: 0.25rem !important;
        }

        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important;
        }

        .me-xl-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-3 {
            margin-right: 1rem !important;
            margin-left: 1rem !important;
        }

        .me-xl-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-4 {
            margin-right: 1.5rem !important;
            margin-left: 1.5rem !important;
        }

        .me-xl-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-5 {
            margin-right: 3rem !important;
            margin-left: 3rem !important;
        }

        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .px-xl-0 {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .px-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: 0.25rem !important;
        }

        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .px-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }

        .pe-xl-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .px-xl-3 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

        .pe-xl-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .px-xl-4 {
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }

        .pe-xl-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .px-xl-5 {
            padding-right: 3rem !important;
            padding-left: 3rem !important;
        }

        .me-xl-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-n1 {
            margin-right: -0.25rem !important;
            margin-left: -0.25rem !important;
        }

        .me-xl-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-n2 {
            margin-right: -0.5rem !important;
            margin-left: -0.5rem !important;
        }

        .me-xl-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-n3 {
            margin-right: -1rem !important;
            margin-left: -1rem !important;
        }

        .me-xl-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-n4 {
            margin-right: -1.5rem !important;
            margin-left: -1.5rem !important;
        }

        .me-xl-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .mx-xl-n5 {
            margin-right: -3rem !important;
            margin-left: -3rem !important;
        }

        .me-xl-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .mx-xl-auto {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    /* ###### Margin ###### */

    /* ###### Padding  ###### */

    .pd-x-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .pd-r-0 {
        padding-left: 0px;
        padding-right: inherit;
    }

    .pd-l-0 {
        padding-right: 0px;
        padding-left: inherit;
    }

    .pd-x-1 {
        padding-right: 1px;
        padding-left: 1px;
    }

    .pd-r-1 {
        padding-left: 1px;
        padding-right: inherit;
    }

    .pd-l-1 {
        padding-right: 1px;
        padding-left: inherit;
    }

    .pd-x-2 {
        padding-right: 2px;
        padding-left: 2px;
    }

    .pd-r-2 {
        padding-left: 2px;
        padding-right: inherit;
    }

    .pd-l-2 {
        padding-right: 2px;
        padding-left: inherit;
    }

    .pd-x-3 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .pd-r-3 {
        padding-left: 3px;
        padding-right: inherit;
    }

    .pd-l-3 {
        padding-right: 3px;
        padding-left: inherit;
    }

    .pd-x-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .pd-r-4 {
        padding-left: 4px;
        padding-right: inherit;
    }

    .pd-l-4 {
        padding-right: 4px;
        padding-left: inherit;
    }

    .pd-x-5 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .pd-r-5 {
        padding-left: 5px;
        padding-right: inherit;
    }

    .pd-l-5 {
        padding-right: 5px;
        padding-left: inherit;
    }

    .pd-x-6 {
        padding-right: 6px;
        padding-left: 6px;
    }

    .pd-r-6 {
        padding-left: 6px;
        padding-right: inherit;
    }

    .pd-l-6 {
        padding-right: 6px;
        padding-left: inherit;
    }

    .pd-x-7 {
        padding-right: 7px;
        padding-left: 7px;
    }

    .pd-r-7 {
        padding-left: 7px;
        padding-right: inherit;
    }

    .pd-l-7 {
        padding-right: 7px;
        padding-left: inherit;
    }

    .pd-x-8 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .pd-r-8 {
        padding-left: 8px;
        padding-right: inherit;
    }

    .pd-l-8 {
        padding-right: 8px;
        padding-left: inherit;
    }

    .pd-x-9 {
        padding-right: 9px;
        padding-left: 9px;
    }

    .pd-r-9 {
        padding-left: 9px;
        padding-right: inherit;
    }

    .pd-l-9 {
        padding-right: 9px;
        padding-left: inherit;
    }

    .pd-x-10 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .pd-r-10 {
        padding-left: 10px;
        padding-right: inherit;
    }

    .pd-l-10 {
        padding-right: 10px;
        padding-left: inherit;
    }

    .pd-x-15 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .pd-r-15 {
        padding-left: 15px;
        padding-right: inherit;
    }

    .pd-l-15 {
        padding-right: 15px;
        padding-left: inherit;
    }

    .pd-x-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .pd-r-20 {
        padding-left: 20px;
        padding-right: inherit;
    }

    .pd-l-20 {
        padding-right: 20px;
        padding-left: inherit;
    }

    .pd-x-25 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .pd-r-25 {
        padding-left: 25px;
        padding-right: inherit;
    }

    .pd-l-25 {
        padding-right: 25px;
        padding-left: inherit;
    }

    .pd-x-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .pd-r-30 {
        padding-left: 30px;
        padding-right: inherit;
    }

    .pd-l-30 {
        padding-right: 30px;
        padding-left: inherit;
    }

    .pd-x-35 {
        padding-right: 35px;
        padding-left: 35px;
    }

    .pd-r-35 {
        padding-left: 35px;
        padding-right: inherit;
    }

    .pd-l-35 {
        padding-right: 35px;
        padding-left: inherit;
    }

    .pd-x-40 {
        padding-right: 40px;
        padding-left: 40px;
    }

    .pd-r-40 {
        padding-left: 40px;
        padding-right: inherit;
    }

    .pd-l-40 {
        padding-right: 40px;
        padding-left: inherit;
    }

    .pd-x-45 {
        padding-right: 45px;
        padding-left: 45px;
    }

    .pd-r-45 {
        padding-left: 45px;
        padding-right: inherit;
    }

    .pd-l-45 {
        padding-right: 45px;
        padding-left: inherit;
    }

    .pd-x-50 {
        padding-right: 50px;
        padding-left: 50px;
    }

    .pd-r-50 {
        padding-left: 50px;
        padding-right: inherit;
    }

    .pd-l-50 {
        padding-right: 50px;
        padding-left: inherit;
    }

    .pd-x-55 {
        padding-right: 55px;
        padding-left: 55px;
    }

    .pd-r-55 {
        padding-left: 55px;
        padding-right: inherit;
    }

    .pd-l-55 {
        padding-right: 55px;
        padding-left: inherit;
    }

    .pd-x-60 {
        padding-right: 60px;
        padding-left: 60px;
    }

    .pd-r-60 {
        padding-left: 60px;
        padding-right: inherit;
    }

    .pd-l-60 {
        padding-right: 60px;
        padding-left: inherit;
    }

    .pd-x-65 {
        padding-right: 65px;
        padding-left: 65px;
    }

    .pd-r-65 {
        padding-left: 65px;
        padding-right: inherit;
    }

    .pd-l-65 {
        padding-right: 65px;
        padding-left: inherit;
    }

    .pd-x-70 {
        padding-right: 70px;
        padding-left: 70px;
    }

    .pd-r-70 {
        padding-left: 70px;
        padding-right: inherit;
    }

    .pd-l-70 {
        padding-right: 70px;
        padding-left: inherit;
    }

    .pd-x-75 {
        padding-right: 75px;
        padding-left: 75px;
    }

    .pd-r-75 {
        padding-left: 75px;
        padding-right: inherit;
    }

    .pd-l-75 {
        padding-right: 75px;
        padding-left: inherit;
    }

    .pd-x-80 {
        padding-right: 80px;
        padding-left: 80px;
    }

    .pd-r-80 {
        padding-left: 80px;
        padding-right: inherit;
    }

    .pd-l-80 {
        padding-right: 80px;
        padding-left: inherit;
    }

    .pd-x-85 {
        padding-right: 85px;
        padding-left: 85px;
    }

    .pd-r-85 {
        padding-left: 85px;
        padding-right: inherit;
    }

    .pd-l-85 {
        padding-right: 85px;
        padding-left: inherit;
    }

    .pd-x-90 {
        padding-right: 90px;
        padding-left: 90px;
    }

    .pd-r-90 {
        padding-left: 90px;
        padding-right: inherit;
    }

    .pd-l-90 {
        padding-right: 90px;
        padding-left: inherit;
    }

    .pd-x-95 {
        padding-right: 95px;
        padding-left: 95px;
    }

    .pd-r-95 {
        padding-left: 95px;
        padding-right: inherit;
    }

    .pd-l-95 {
        padding-right: 95px;
        padding-left: inherit;
    }

    .pd-x-100 {
        padding-right: 100px;
        padding-left: 100px;
    }

    .pd-r-100 {
        padding-left: 100px;
        padding-right: inherit;
    }

    .pd-l-100 {
        padding-right: 100px;
        padding-left: inherit;
    }

    .pd-x-110 {
        padding-right: 110px;
        padding-left: 110px;
    }

    .pd-r-110 {
        padding-left: 110px;
        padding-right: inherit;
    }

    .pd-l-110 {
        padding-right: 110px;
        padding-left: inherit;
    }

    .pd-x-120 {
        padding-right: 120px;
        padding-left: 120px;
    }

    .pd-r-120 {
        padding-left: 120px;
        padding-right: inherit;
    }

    .pd-l-120 {
        padding-right: 120px;
        padding-left: inherit;
    }

    .pd-x-130 {
        padding-right: 130px;
        padding-left: 130px;
    }

    .pd-r-130 {
        padding-left: 130px;
        padding-right: inherit;
    }

    .pd-l-130 {
        padding-right: 130px;
        padding-left: inherit;
    }

    .pd-x-140 {
        padding-right: 140px;
        padding-left: 140px;
    }

    .pd-r-140 {
        padding-left: 140px;
        padding-right: inherit;
    }

    .pd-l-140 {
        padding-right: 140px;
        padding-left: inherit;
    }

    .pd-x-150 {
        padding-right: 150px;
        padding-left: 150px;
    }

    .pd-r-150 {
        padding-left: 150px;
        padding-right: inherit;
    }

    .pd-l-150 {
        padding-right: 150px;
        padding-left: inherit;
    }

    .pd-x-160 {
        padding-right: 160px;
        padding-left: 160px;
    }

    .pd-r-160 {
        padding-left: 160px;
        padding-right: inherit;
    }

    .pd-l-160 {
        padding-right: 160px;
        padding-left: inherit;
    }

    .pd-x-170 {
        padding-right: 170px;
        padding-left: 170px;
    }

    .pd-r-170 {
        padding-left: 170px;
        padding-right: inherit;
    }

    .pd-l-170 {
        padding-right: 170px;
        padding-left: inherit;
    }

    .pd-x-180 {
        padding-right: 180px;
        padding-left: 180px;
    }

    .pd-r-180 {
        padding-left: 180px;
        padding-right: inherit;
    }

    .pd-l-180 {
        padding-right: 180px;
        padding-left: inherit;
    }

    .pd-x-190 {
        padding-right: 190px;
        padding-left: 190px;
    }

    .pd-r-190 {
        padding-left: 190px;
        padding-right: inherit;
    }

    .pd-l-190 {
        padding-right: 190px;
        padding-left: inherit;
    }

    .pd-x-200 {
        padding-right: 200px;
        padding-left: 200px;
    }

    .pd-r-200 {
        padding-left: 200px;
        padding-right: inherit;
    }

    .pd-l-200 {
        padding-right: 200px;
        padding-left: inherit;
    }

    @media (min-width: 480px) {
        .pd-xs-x-0 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .pd-xs-r-0 {
            padding-left: 0px;
            padding-right: inherit;
        }

        .pd-xs-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-xs-x-1 {
            padding-right: 1px;
            padding-left: 1px;
        }

        .pd-xs-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-xs-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-xs-x-2 {
            padding-right: 2px;
            padding-left: 2px;
        }

        .pd-xs-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-xs-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-xs-x-3 {
            padding-right: 3px;
            padding-left: 3px;
        }

        .pd-xs-r-3 {
            padding-left: 3px;
            padding-right: inherit;
        }

        .pd-xs-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-xs-x-4 {
            padding-right: 4px;
            padding-left: 4px;
        }

        .pd-xs-r-4 {
            padding-left: 4px;
            padding-right: inherit;
        }

        .pd-xs-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-xs-x-5 {
            padding-right: 5px;
            padding-left: 5px;
        }

        .pd-xs-r-5 {
            padding-left: 5px;
            padding-right: inherit;
        }

        .pd-xs-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-xs-x-6 {
            padding-right: 6px;
            padding-left: 6px;
        }

        .pd-xs-r-6 {
            padding-left: 6px;
            padding-right: inherit;
        }

        .pd-xs-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-xs-x-7 {
            padding-right: 7px;
            padding-left: 7px;
        }

        .pd-xs-r-7 {
            padding-left: 7px;
            padding-right: inherit;
        }

        .pd-xs-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-xs-x-8 {
            padding-right: 8px;
            padding-left: 8px;
        }

        .pd-xs-r-8 {
            padding-left: 8px;
            padding-right: inherit;
        }

        .pd-xs-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-xs-x-9 {
            padding-right: 9px;
            padding-left: 9px;
        }

        .pd-xs-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-xs-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-xs-x-10 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .pd-xs-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-xs-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-xs-x-15 {
            padding-right: 15px;
            padding-left: 15px;
        }

        .pd-xs-r-15 {
            padding-left: 15px;
            padding-right: inherit;
        }

        .pd-xs-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-xs-x-20 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .pd-xs-r-20 {
            padding-left: 20px;
            padding-right: inherit;
        }

        .pd-xs-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-xs-x-25 {
            padding-right: 25px;
            padding-left: 25px;
        }

        .pd-xs-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-xs-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-xs-x-30 {
            padding-right: 30px;
            padding-left: 30px;
        }

        .pd-xs-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-xs-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-xs-x-35 {
            padding-right: 35px;
            padding-left: 35px;
        }

        .pd-xs-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-xs-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-xs-x-40 {
            padding-right: 40px;
            padding-left: 40px;
        }

        .pd-xs-r-40 {
            padding-left: 40px;
            padding-right: inherit;
        }

        .pd-xs-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-xs-x-45 {
            padding-right: 45px;
            padding-left: 45px;
        }

        .pd-xs-r-45 {
            padding-left: 45px;
            padding-right: inherit;
        }

        .pd-xs-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-xs-x-50 {
            padding-right: 50px;
            padding-left: 50px;
        }

        .pd-xs-r-50 {
            padding-left: 50px;
            padding-right: inherit;
        }

        .pd-xs-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-xs-x-55 {
            padding-right: 55px;
            padding-left: 55px;
        }

        .pd-xs-r-55 {
            padding-left: 55px;
            padding-right: inherit;
        }

        .pd-xs-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-xs-x-60 {
            padding-right: 60px;
            padding-left: 60px;
        }

        .pd-xs-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-xs-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-xs-x-65 {
            padding-right: 65px;
            padding-left: 65px;
        }

        .pd-xs-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-xs-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-xs-x-70 {
            padding-right: 70px;
            padding-left: 70px;
        }

        .pd-xs-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-xs-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-xs-x-75 {
            padding-right: 75px;
            padding-left: 75px;
        }

        .pd-xs-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-xs-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-xs-x-80 {
            padding-right: 80px;
            padding-left: 80px;
        }

        .pd-xs-r-80 {
            padding-left: 80px;
            padding-right: inherit;
        }

        .pd-xs-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-xs-x-85 {
            padding-right: 85px;
            padding-left: 85px;
        }

        .pd-xs-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-xs-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-xs-x-90 {
            padding-right: 90px;
            padding-left: 90px;
        }

        .pd-xs-r-90 {
            padding-left: 90px;
            padding-right: inherit;
        }

        .pd-xs-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-xs-x-95 {
            padding-right: 95px;
            padding-left: 95px;
        }

        .pd-xs-r-95 {
            padding-left: 95px;
            padding-right: inherit;
        }

        .pd-xs-l-95 {
            padding-right: 95px;
            padding-left: inherit;
        }

        .pd-xs-x-100 {
            padding-right: 100px;
            padding-left: 100px;
        }

        .pd-xs-r-100 {
            padding-left: 100px;
            padding-right: inherit;
        }

        .pd-xs-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-xs-x-110 {
            padding-right: 110px;
            padding-left: 110px;
        }

        .pd-xs-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-xs-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-xs-x-120 {
            padding-right: 120px;
            padding-left: 120px;
        }

        .pd-xs-r-120 {
            padding-left: 120px;
            padding-right: inherit;
        }

        .pd-xs-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-xs-x-130 {
            padding-right: 130px;
            padding-left: 130px;
        }

        .pd-xs-r-130 {
            padding-left: 130px;
            padding-right: inherit;
        }

        .pd-xs-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-xs-x-140 {
            padding-right: 140px;
            padding-left: 140px;
        }

        .pd-xs-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-xs-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-xs-x-150 {
            padding-right: 150px;
            padding-left: 150px;
        }

        .pd-xs-r-150 {
            padding-left: 150px;
            padding-right: inherit;
        }

        .pd-xs-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-xs-x-160 {
            padding-right: 160px;
            padding-left: 160px;
        }

        .pd-xs-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-xs-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-xs-x-170 {
            padding-right: 170px;
            padding-left: 170px;
        }

        .pd-xs-r-170 {
            padding-left: 170px;
            padding-right: inherit;
        }

        .pd-xs-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-xs-x-180 {
            padding-right: 180px;
            padding-left: 180px;
        }

        .pd-xs-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-xs-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-xs-x-190 {
            padding-right: 190px;
            padding-left: 190px;
        }

        .pd-xs-r-190 {
            padding-left: 190px;
            padding-right: inherit;
        }

        .pd-xs-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-xs-x-200 {
            padding-right: 200px;
            padding-left: 200px;
        }

        .pd-xs-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-xs-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .pd-sm-x-0 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .pd-sm-r-0 {
            padding-left: 0px;
            padding-right: inherit;
        }

        .pd-sm-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-sm-x-1 {
            padding-right: 1px;
            padding-left: 1px;
        }

        .pd-sm-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-sm-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-sm-x-2 {
            padding-right: 2px;
            padding-left: 2px;
        }

        .pd-sm-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-sm-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-sm-x-3 {
            padding-right: 3px;
            padding-left: 3px;
        }

        .pd-sm-r-3 {
            padding-left: 3px;
            padding-right: inherit;
        }

        .pd-sm-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-sm-x-4 {
            padding-right: 4px;
            padding-left: 4px;
        }

        .pd-sm-r-4 {
            padding-left: 4px;
            padding-right: inherit;
        }

        .pd-sm-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-sm-x-5 {
            padding-right: 5px;
            padding-left: 5px;
        }

        .pd-sm-r-5 {
            padding-left: 5px;
            padding-right: inherit;
        }

        .pd-sm-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-sm-x-6 {
            padding-right: 6px;
            padding-left: 6px;
        }

        .pd-sm-r-6 {
            padding-left: 6px;
            padding-right: inherit;
        }

        .pd-sm-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-sm-x-7 {
            padding-right: 7px;
            padding-left: 7px;
        }

        .pd-sm-r-7 {
            padding-left: 7px;
            padding-right: inherit;
        }

        .pd-sm-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-sm-x-8 {
            padding-right: 8px;
            padding-left: 8px;
        }

        .pd-sm-r-8 {
            padding-left: 8px;
            padding-right: inherit;
        }

        .pd-sm-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-sm-x-9 {
            padding-right: 9px;
            padding-left: 9px;
        }

        .pd-sm-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-sm-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-sm-x-10 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .pd-sm-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-sm-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-sm-x-15 {
            padding-right: 15px;
            padding-left: 15px;
        }

        .pd-sm-r-15 {
            padding-left: 15px;
            padding-right: inherit;
        }

        .pd-sm-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-sm-x-20 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .pd-sm-r-20 {
            padding-left: 20px;
            padding-right: inherit;
        }

        .pd-sm-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-sm-x-25 {
            padding-right: 25px;
            padding-left: 25px;
        }

        .pd-sm-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-sm-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-sm-x-30 {
            padding-right: 30px;
            padding-left: 30px;
        }

        .pd-sm-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-sm-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-sm-x-35 {
            padding-right: 35px;
            padding-left: 35px;
        }

        .pd-sm-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-sm-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-sm-x-40 {
            padding-right: 40px;
            padding-left: 40px;
        }

        .pd-sm-r-40 {
            padding-left: 40px;
            padding-right: inherit;
        }

        .pd-sm-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-sm-x-45 {
            padding-right: 45px;
            padding-left: 45px;
        }

        .pd-sm-r-45 {
            padding-left: 45px;
            padding-right: inherit;
        }

        .pd-sm-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-sm-x-50 {
            padding-right: 50px;
            padding-left: 50px;
        }

        .pd-sm-r-50 {
            padding-left: 50px;
            padding-right: inherit;
        }

        .pd-sm-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-sm-x-55 {
            padding-right: 55px;
            padding-left: 55px;
        }

        .pd-sm-r-55 {
            padding-left: 55px;
            padding-right: inherit;
        }

        .pd-sm-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-sm-x-60 {
            padding-right: 60px;
            padding-left: 60px;
        }

        .pd-sm-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-sm-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-sm-x-65 {
            padding-right: 65px;
            padding-left: 65px;
        }

        .pd-sm-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-sm-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-sm-x-70 {
            padding-right: 70px;
            padding-left: 70px;
        }

        .pd-sm-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-sm-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-sm-x-75 {
            padding-right: 75px;
            padding-left: 75px;
        }

        .pd-sm-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-sm-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-sm-x-80 {
            padding-right: 80px;
            padding-left: 80px;
        }

        .pd-sm-r-80 {
            padding-left: 80px;
            padding-right: inherit;
        }

        .pd-sm-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-sm-x-85 {
            padding-right: 85px;
            padding-left: 85px;
        }

        .pd-sm-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-sm-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-sm-x-90 {
            padding-right: 90px;
            padding-left: 90px;
        }

        .pd-sm-r-90 {
            padding-left: 90px;
            padding-right: inherit;
        }

        .pd-sm-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-sm-x-95 {
            padding-right: 95px;
            padding-left: 95px;
        }

        .pd-sm-r-95 {
            padding-left: 95px;
            padding-right: inherit;
        }

        .pd-sm-l-95 {
            padding-right: 95px;
            padding-left: inherit;
        }

        .pd-sm-x-100 {
            padding-right: 100px;
            padding-left: 100px;
        }

        .pd-sm-r-100 {
            padding-left: 100px;
            padding-right: inherit;
        }

        .pd-sm-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-sm-x-110 {
            padding-right: 110px;
            padding-left: 110px;
        }

        .pd-sm-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-sm-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-sm-x-120 {
            padding-right: 120px;
            padding-left: 120px;
        }

        .pd-sm-r-120 {
            padding-left: 120px;
            padding-right: inherit;
        }

        .pd-sm-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-sm-x-130 {
            padding-right: 130px;
            padding-left: 130px;
        }

        .pd-sm-r-130 {
            padding-left: 130px;
            padding-right: inherit;
        }

        .pd-sm-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-sm-x-140 {
            padding-right: 140px;
            padding-left: 140px;
        }

        .pd-sm-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-sm-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-sm-x-150 {
            padding-right: 150px;
            padding-left: 150px;
        }

        .pd-sm-r-150 {
            padding-left: 150px;
            padding-right: inherit;
        }

        .pd-sm-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-sm-x-160 {
            padding-right: 160px;
            padding-left: 160px;
        }

        .pd-sm-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-sm-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-sm-x-170 {
            padding-right: 170px;
            padding-left: 170px;
        }

        .pd-sm-r-170 {
            padding-left: 170px;
            padding-right: inherit;
        }

        .pd-sm-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-sm-x-180 {
            padding-right: 180px;
            padding-left: 180px;
        }

        .pd-sm-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-sm-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-sm-x-190 {
            padding-right: 190px;
            padding-left: 190px;
        }

        .pd-sm-r-190 {
            padding-left: 190px;
            padding-right: inherit;
        }

        .pd-sm-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-sm-x-200 {
            padding-right: 200px;
            padding-left: 200px;
        }

        .pd-sm-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-sm-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .pd-md-x-0 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .pd-md-r-0 {
            padding-left: 0px;
            padding-right: inherit;
        }

        .pd-md-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-md-x-1 {
            padding-right: 1px;
            padding-left: 1px;
        }

        .pd-md-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-md-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-md-x-2 {
            padding-right: 2px;
            padding-left: 2px;
        }

        .pd-md-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-md-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-md-x-3 {
            padding-right: 3px;
            padding-left: 3px;
        }

        .pd-md-r-3 {
            padding-left: 3px;
            padding-right: inherit;
        }

        .pd-md-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-md-x-4 {
            padding-right: 4px;
            padding-left: 4px;
        }

        .pd-md-r-4 {
            padding-left: 4px;
            padding-right: inherit;
        }

        .pd-md-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-md-x-5 {
            padding-right: 5px;
            padding-left: 5px;
        }

        .pd-md-r-5 {
            padding-left: 5px;
            padding-right: inherit;
        }

        .pd-md-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-md-r-6 {
            padding-left: 6px;
            padding-right: inherit;
        }

        .pd-md-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-md-x-7 {
            padding-right: 7px;
            padding-left: 7px;
        }

        .pd-md-r-7 {
            padding-left: 7px;
            padding-right: inherit;
        }

        .pd-md-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-md-x-8 {
            padding-right: 8px;
            padding-left: 8px;
        }

        .pd-md-r-8 {
            padding-left: 8px;
            padding-right: inherit;
        }

        .pd-md-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-md-x-9 {
            padding-right: 9px;
            padding-left: 9px;
        }

        .pd-md-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-md-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-md-x-10 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .pd-md-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-md-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-md-x-15 {
            padding-right: 15px;
            padding-left: 15px;
        }

        .pd-md-r-15 {
            padding-left: 15px;
            padding-right: inherit;
        }

        .pd-md-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-md-x-20 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .pd-md-r-20 {
            padding-left: 20px;
            padding-right: inherit;
        }

        .pd-md-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-md-x-25 {
            padding-right: 25px;
            padding-left: 25px;
        }

        .pd-md-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-md-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-md-x-30 {
            padding-right: 30px;
            padding-left: 30px;
        }

        .pd-md-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-md-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-md-x-35 {
            padding-right: 35px;
            padding-left: 35px;
        }

        .pd-md-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-md-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-md-x-40 {
            padding-right: 40px;
            padding-left: 40px;
        }

        .pd-md-r-40 {
            padding-left: 40px;
            padding-right: inherit;
        }

        .pd-md-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-md-x-45 {
            padding-right: 45px;
            padding-left: 45px;
        }

        .pd-md-r-45 {
            padding-left: 45px;
            padding-right: inherit;
        }

        .pd-md-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-md-x-50 {
            padding-right: 50px;
            padding-left: 50px;
        }

        .pd-md-r-50 {
            padding-left: 50px;
            padding-right: inherit;
        }

        .pd-md-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-md-x-55 {
            padding-right: 55px;
            padding-left: 55px;
        }

        .pd-md-r-55 {
            padding-left: 55px;
            padding-right: inherit;
        }

        .pd-md-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-md-x-60 {
            padding-right: 60px;
            padding-left: 60px;
        }

        .pd-md-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-md-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-md-x-65 {
            padding-right: 65px;
            padding-left: 65px;
        }

        .pd-md-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-md-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-md-x-70 {
            padding-right: 70px;
            padding-left: 70px;
        }

        .pd-md-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-md-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-md-x-75 {
            padding-right: 75px;
            padding-left: 75px;
        }

        .pd-md-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-md-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-md-x-80 {
            padding-right: 80px;
            padding-left: 80px;
        }

        .pd-md-r-80 {
            padding-left: 80px;
            padding-right: inherit;
        }

        .pd-md-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-md-x-85 {
            padding-right: 85px;
            padding-left: 85px;
        }

        .pd-md-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-md-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-md-x-90 {
            padding-right: 90px;
            padding-left: 90px;
        }

        .pd-md-r-90 {
            padding-left: 90px;
            padding-right: inherit;
        }

        .pd-md-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-md-x-95 {
            padding-right: 95px;
            padding-left: 95px;
        }

        .pd-md-r-95 {
            padding-left: 95px;
            padding-right: inherit;
        }

        .pd-md-l-95 {
            padding-right: 95px;
            padding-left: inherit;
        }

        .pd-md-x-100 {
            padding-right: 100px;
            padding-left: 100px;
        }

        .pd-md-r-100 {
            padding-left: 100px;
            padding-right: inherit;
        }

        .pd-md-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-md-x-110 {
            padding-right: 110px;
            padding-left: 110px;
        }

        .pd-md-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-md-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-md-x-120 {
            padding-right: 120px;
            padding-left: 120px;
        }

        .pd-md-r-120 {
            padding-left: 120px;
            padding-right: inherit;
        }

        .pd-md-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-md-x-130 {
            padding-right: 130px;
            padding-left: 130px;
        }

        .pd-md-r-130 {
            padding-left: 130px;
            padding-right: inherit;
        }

        .pd-md-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-md-x-140 {
            padding-right: 140px;
            padding-left: 140px;
        }

        .pd-md-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-md-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-md-x-150 {
            padding-right: 150px;
            padding-left: 150px;
        }

        .pd-md-r-150 {
            padding-left: 150px;
            padding-right: inherit;
        }

        .pd-md-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-md-x-160 {
            padding-right: 160px;
            padding-left: 160px;
        }

        .pd-md-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-md-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-md-x-170 {
            padding-right: 170px;
            padding-left: 170px;
        }

        .pd-md-r-170 {
            padding-left: 170px;
            padding-right: inherit;
        }

        .pd-md-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-md-x-180 {
            padding-right: 180px;
            padding-left: 180px;
        }

        .pd-md-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-md-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-md-x-190 {
            padding-right: 190px;
            padding-left: 190px;
        }

        .pd-md-r-190 {
            padding-left: 190px;
            padding-right: inherit;
        }

        .pd-md-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-md-x-200 {
            padding-right: 200px;
            padding-left: 200px;
        }

        .pd-md-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-md-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .pd-lg-x-0 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .pd-lg-r-0 {
            padding-left: 0px;
            padding-right: inherit;
        }

        .pd-lg-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-lg-x-1 {
            padding-right: 1px;
            padding-left: 1px;
        }

        .pd-lg-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-lg-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-lg-x-2 {
            padding-right: 2px;
            padding-left: 2px;
        }

        .pd-lg-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-lg-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-lg-x-3 {
            padding-right: 3px;
            padding-left: 3px;
        }

        .pd-lg-r-3 {
            padding-left: 3px;
            padding-right: inherit;
        }

        .pd-lg-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-lg-x-4 {
            padding-right: 4px;
            padding-left: 4px;
        }

        .pd-lg-r-4 {
            padding-left: 4px;
            padding-right: inherit;
        }

        .pd-lg-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-lg-x-5 {
            padding-right: 5px;
            padding-left: 5px;
        }

        .pd-lg-r-5 {
            padding-left: 5px;
            padding-right: inherit;
        }

        .pd-lg-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-lg-x-6 {
            padding-right: 6px;
            padding-left: 6px;
        }

        .pd-lg-r-6 {
            padding-left: 6px;
            padding-right: inherit;
        }

        .pd-lg-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-lg-x-7 {
            padding-right: 7px;
            padding-left: 7px;
        }

        .pd-lg-r-7 {
            padding-left: 7px;
            padding-right: inherit;
        }

        .pd-lg-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-lg-x-8 {
            padding-right: 8px;
            padding-left: 8px;
        }

        .pd-lg-r-8 {
            padding-left: 8px;
            padding-right: inherit;
        }

        .pd-lg-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-lg-x-9 {
            padding-right: 9px;
            padding-left: 9px;
        }

        .pd-lg-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-lg-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-lg-x-10 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .pd-lg-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-lg-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-lg-x-15 {
            padding-right: 15px;
            padding-left: 15px;
        }

        .pd-lg-r-15 {
            padding-left: 15px;
            padding-right: inherit;
        }

        .pd-lg-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-lg-x-20 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .pd-lg-r-20 {
            padding-left: 20px;
            padding-right: inherit;
        }

        .pd-lg-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-lg-x-25 {
            padding-right: 25px;
            padding-left: 25px;
        }

        .pd-lg-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-lg-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-lg-x-30 {
            padding-right: 30px;
            padding-left: 30px;
        }

        .pd-lg-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-lg-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-lg-x-35 {
            padding-right: 35px;
            padding-left: 35px;
        }

        .pd-lg-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-lg-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-lg-x-40 {
            padding-right: 40px;
            padding-left: 40px;
        }

        .pd-lg-r-40 {
            padding-left: 40px;
            padding-right: inherit;
        }

        .pd-lg-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-lg-x-45 {
            padding-right: 45px;
            padding-left: 45px;
        }

        .pd-lg-r-45 {
            padding-left: 45px;
            padding-right: inherit;
        }

        .pd-lg-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-lg-x-50 {
            padding-right: 50px;
            padding-left: 50px;
        }

        .pd-lg-r-50 {
            padding-left: 50px;
            padding-right: inherit;
        }

        .pd-lg-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-lg-x-55 {
            padding-right: 55px;
            padding-left: 55px;
        }

        .pd-lg-r-55 {
            padding-left: 55px;
            padding-right: inherit;
        }

        .pd-lg-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-lg-x-60 {
            padding-right: 60px;
            padding-left: 60px;
        }

        .pd-lg-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-lg-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-lg-x-65 {
            padding-right: 65px;
            padding-left: 65px;
        }

        .pd-lg-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-lg-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-lg-x-70 {
            padding-right: 70px;
            padding-left: 70px;
        }

        .pd-lg-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-lg-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-lg-x-75 {
            padding-right: 75px;
            padding-left: 75px;
        }

        .pd-lg-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-lg-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-lg-x-80 {
            padding-right: 80px;
            padding-left: 80px;
        }

        .pd-lg-r-80 {
            padding-left: 80px;
            padding-right: inherit;
        }

        .pd-lg-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-lg-x-85 {
            padding-right: 85px;
            padding-left: 85px;
        }

        .pd-lg-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-lg-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-lg-x-90 {
            padding-right: 90px;
            padding-left: 90px;
        }

        .pd-lg-r-90 {
            padding-left: 90px;
            padding-right: inherit;
        }

        .pd-lg-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-lg-x-95 {
            padding-right: 95px;
            padding-left: 95px;
        }

        .pd-lg-r-95 {
            padding-left: 95px;
            padding-right: inherit;
        }

        .pd-lg-l-95 {
            padding-right: 95px;
            padding-left: inherit;
        }

        .pd-lg-x-100 {
            padding-right: 100px;
            padding-left: 100px;
        }

        .pd-lg-r-100 {
            padding-left: 100px;
            padding-right: inherit;
        }

        .pd-lg-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-lg-x-110 {
            padding-right: 110px;
            padding-left: 110px;
        }

        .pd-lg-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-lg-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-lg-x-120 {
            padding-right: 120px;
            padding-left: 120px;
        }

        .pd-lg-r-120 {
            padding-left: 120px;
            padding-right: inherit;
        }

        .pd-lg-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-lg-x-130 {
            padding-right: 130px;
            padding-left: 130px;
        }

        .pd-lg-r-130 {
            padding-left: 130px;
            padding-right: inherit;
        }

        .pd-lg-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-lg-x-140 {
            padding-right: 140px;
            padding-left: 140px;
        }

        .pd-lg-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-lg-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-lg-x-150 {
            padding-right: 150px;
            padding-left: 150px;
        }

        .pd-lg-r-150 {
            padding-left: 150px;
            padding-right: inherit;
        }

        .pd-lg-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-lg-x-160 {
            padding-right: 160px;
            padding-left: 160px;
        }

        .pd-lg-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-lg-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-lg-x-170 {
            padding-right: 170px;
            padding-left: 170px;
        }

        .pd-lg-r-170 {
            padding-left: 170px;
            padding-right: inherit;
        }

        .pd-lg-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-lg-x-180 {
            padding-right: 180px;
            padding-left: 180px;
        }

        .pd-lg-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-lg-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-lg-x-190 {
            padding-right: 190px;
            padding-left: 190px;
        }

        .pd-lg-r-190 {
            padding-left: 190px;
            padding-right: inherit;
        }

        .pd-lg-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-lg-x-200 {
            padding-right: 200px;
            padding-left: 200px;
        }

        .pd-lg-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-lg-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .pd-xl-x-0 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .pd-xl-r-0 {
            padding-left: 0px;
            padding-right: inherit;
        }

        .pd-xl-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-xl-x-1 {
            padding-right: 1px;
            padding-left: 1px;
        }

        .pd-xl-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-xl-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-xl-x-2 {
            padding-right: 2px;
            padding-left: 2px;
        }

        .pd-xl-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-xl-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-xl-x-3 {
            padding-right: 3px;
            padding-left: 3px;
        }

        .pd-xl-r-3 {
            padding-left: 3px;
            padding-right: inherit;
        }

        .pd-xl-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-xl-x-4 {
            padding-right: 4px;
            padding-left: 4px;
        }

        .pd-xl-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-xl-x-5 {
            padding-right: 5px;
            padding-left: 5px;
        }

        .pd-xl-r-5 {
            padding-left: 5px;
            padding-right: inherit;
        }

        .pd-xl-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-xl-x-6 {
            padding-right: 6px;
            padding-left: 6px;
        }

        .pd-xl-r-6 {
            padding-left: 6px;
            padding-right: inherit;
        }

        .pd-xl-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-xl-x-7 {
            padding-right: 7px;
            padding-left: 7px;
        }

        .pd-xl-r-7 {
            padding-left: 7px;
            padding-right: inherit;
        }

        .pd-xl-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-xl-x-8 {
            padding-right: 8px;
            padding-left: 8px;
        }

        .pd-xl-r-8 {
            padding-left: 8px;
            padding-right: inherit;
        }

        .pd-xl-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-xl-x-9 {
            padding-right: 9px;
            padding-left: 9px;
        }

        .pd-xl-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-xl-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-xl-x-10 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .pd-xl-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-xl-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-xl-x-15 {
            padding-right: 15px;
            padding-left: 15px;
        }

        .pd-xl-r-15 {
            padding-left: 15px;
            padding-right: inherit;
        }

        .pd-xl-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-xl-x-20 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .pd-xl-r-20 {
            padding-left: 20px;
            padding-right: inherit;
        }

        .pd-xl-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-xl-x-25 {
            padding-right: 25px;
            padding-left: 25px;
        }

        .pd-xl-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-xl-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-xl-x-30 {
            padding-right: 30px;
            padding-left: 30px;
        }

        .pd-xl-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-xl-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-xl-x-35 {
            padding-right: 35px;
            padding-left: 35px;
        }

        .pd-xl-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-xl-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-xl-x-40 {
            padding-right: 40px;
            padding-left: 40px;
        }

        .pd-xl-r-40 {
            padding-left: 40px;
            padding-right: inherit;
        }

        .pd-xl-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-xl-x-45 {
            padding-right: 45px;
            padding-left: 45px;
        }

        .pd-xl-r-45 {
            padding-left: 45px;
            padding-right: inherit;
        }

        .pd-xl-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-xl-x-50 {
            padding-right: 50px;
            padding-left: 50px;
        }

        .pd-xl-r-50 {
            padding-left: 50px;
            padding-right: inherit;
        }

        .pd-xl-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-xl-x-55 {
            padding-right: 55px;
            padding-left: 55px;
        }

        .pd-xl-r-55 {
            padding-left: 55px;
            padding-right: inherit;
        }

        .pd-xl-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-xl-x-60 {
            padding-right: 60px;
            padding-left: 60px;
        }

        .pd-xl-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-xl-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-xl-x-65 {
            padding-right: 65px;
            padding-left: 65px;
        }

        .pd-xl-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-xl-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-xl-x-70 {
            padding-right: 70px;
            padding-left: 70px;
        }

        .pd-xl-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-xl-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-xl-x-75 {
            padding-right: 75px;
            padding-left: 75px;
        }

        .pd-xl-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-xl-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-xl-x-80 {
            padding-right: 80px;
            padding-left: 80px;
        }

        .pd-xl-r-80 {
            padding-left: 80px;
            padding-right: inherit;
        }

        .pd-xl-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-xl-x-85 {
            padding-right: 85px;
            padding-left: 85px;
        }

        .pd-xl-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-xl-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-xl-x-90 {
            padding-right: 90px;
            padding-left: 90px;
        }

        .pd-xl-r-90 {
            padding-left: 90px;
            padding-right: inherit;
        }

        .pd-xl-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-xl-x-95 {
            padding-right: 95px;
            padding-left: 95px;
        }

        .pd-xl-r-95 {
            padding-left: 95px;
            padding-right: inherit;
        }

        .pd-xl-l-95 {
            padding-right: 95px;
            padding-left: inherit;
        }

        .pd-xl-x-100 {
            padding-right: 100px;
            padding-left: 100px;
        }

        .pd-xl-r-100 {
            padding-left: 100px;
            padding-right: inherit;
        }

        .pd-xl-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-xl-x-110 {
            padding-right: 110px;
            padding-left: 110px;
        }

        .pd-xl-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-xl-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-xl-x-120 {
            padding-right: 120px;
            padding-left: 120px;
        }

        .pd-xl-r-120 {
            padding-left: 120px;
            padding-right: inherit;
        }

        .pd-xl-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-xl-x-130 {
            padding-right: 130px;
            padding-left: 130px;
        }

        .pd-xl-r-130 {
            padding-left: 130px;
            padding-right: inherit;
        }

        .pd-xl-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-xl-x-140 {
            padding-right: 140px;
            padding-left: 140px;
        }

        .pd-xl-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-xl-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-xl-x-150 {
            padding-right: 150px;
            padding-left: 150px;
        }

        .pd-xl-r-150 {
            padding-left: 150px;
            padding-right: inherit;
        }

        .pd-xl-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-xl-x-160 {
            padding-right: 160px;
            padding-left: 160px;
        }

        .pd-xl-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-xl-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-xl-x-170 {
            padding-right: 170px;
            padding-left: 170px;
        }

        .pd-xl-r-170 {
            padding-left: 170px;
            padding-right: inherit;
        }

        .pd-xl-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-xl-x-180 {
            padding-right: 180px;
            padding-left: 180px;
        }

        .pd-xl-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-xl-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-xl-x-190 {
            padding-right: 190px;
            padding-left: 190px;
        }

        .pd-xl-r-190 {
            padding-left: 190px;
            padding-right: inherit;
        }

        .pd-xl-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-xl-x-200 {
            padding-right: 200px;
            padding-left: 200px;
        }

        .pd-xl-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-xl-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    .pd-x-12 {
        padding-right: 12px;
        padding-left: 12px;
    }

    .pd-r-12 {
        padding-left: 12px;
        padding-right: inherit;
    }

    .pd-l-12 {
        padding-right: 12px;
        padding-left: inherit;
    }

    .pd-x-13 {
        padding-right: 13px;
        padding-left: 13px;
    }

    .pd-r-13 {
        padding-left: 13px;
        padding-right: inherit;
    }

    .pd-l-13 {
        padding-right: 13px;
        padding-left: inherit;
    }

    /* ###### Padding  ###### */

    /* ###### Position  ###### */

    .r-0 {
        left: 0px;
        right: inherit !important;
    }

    .l-0 {
        right: 0px;
        left: inherit !important;
    }

    .r--0 {
        left: -0px;
        right: inherit !important;
    }

    .l--0 {
        right: -0px;
        left: inherit !important;
    }

    .a-0 {
        left: 0px;
        right: 0px;
    }

    .x-0,
    .y-0 {
        right: 0px;
        left: 0px;
    }

    .r-5 {
        left: 5px;
        right: inherit !important;
    }

    .l-5 {
        right: 5px;
        left: inherit !important;
    }

    .l--5 {
        right: -5px;
        left: inherit !important;
    }

    .a-5 {
        left: 5px;
        right: 5px;
    }

    .x-5,
    .y-5 {
        right: 5px;
        left: 5px;
    }

    .r-10 {
        left: 10px;
        right: inherit !important;
    }

    .l-10 {
        right: 10px;
        left: inherit !important;
    }

    .r--10 {
        left: -10px;
        right: inherit !important;
    }

    .l--10 {
        right: -10px;
        left: inherit !important;
    }

    .a-10 {
        left: 10px;
        right: 10px;
    }

    .x-10,
    .y-10 {
        right: 10px;
        left: 10px;
    }

    .r-15 {
        left: 15px;
        right: inherit !important;
    }

    .l-15 {
        right: 15px;
        left: inherit !important;
    }

    .r--15 {
        left: -15px;
        right: inherit !important;
    }

    .l--15 {
        right: -15px;
        left: inherit !important;
    }

    .a-15 {
        left: 15px;
        right: 15px;
    }

    .x-15,
    .y-15 {
        right: 15px;
        left: 15px;
    }

    .r-20 {
        left: 20px;
        right: inherit !important;
    }

    .l-20 {
        right: 20px;
        left: inherit !important;
    }

    .r--20 {
        left: -20px;
        right: inherit !important;
    }

    .l--20 {
        right: -20px;
        left: inherit !important;
    }

    .a-20 {
        left: 20px;
        right: 20px;
    }

    .x-20,
    .y-20 {
        right: 20px;
        left: 20px;
    }

    .r-25 {
        left: 25px;
        right: inherit !important;
    }

    .l-25 {
        right: 25px;
        left: inherit !important;
    }

    .r--25 {
        left: -25px;
        right: inherit !important;
    }

    .l--25 {
        right: -25px;
        left: inherit !important;
    }

    .a-25 {
        left: 25px;
        right: 25px;
    }

    .x-25,
    .y-25 {
        right: 25px;
        left: 25px;
    }

    .r-30 {
        left: 30px;
        right: inherit !important;
    }

    .l-30 {
        right: 30px;
        left: inherit !important;
    }

    .r--30 {
        left: -30px;
        right: inherit !important;
    }

    .l--30 {
        right: -30px;
        left: inherit !important;
    }

    .a-30 {
        left: 30px;
        right: 30px;
    }

    .x-30,
    .y-30 {
        right: 30px;
        left: 30px;
    }

    .r-35 {
        left: 35px;
        right: inherit !important;
    }

    .l-35 {
        right: 35px;
        left: inherit !important;
    }

    .r--35 {
        left: -35px;
        right: inherit !important;
    }

    .l--35 {
        right: -35px;
        left: inherit !important;
    }

    .a-35 {
        left: 35px;
        right: 35px;
    }

    .x-35,
    .y-35 {
        right: 35px;
        left: 35px;
    }

    .r-40 {
        left: 40px;
        right: inherit !important;
    }

    .l-40 {
        right: 40px;
        left: inherit !important;
    }

    .r--40 {
        left: -40px;
        right: inherit !important;
    }

    .l--40 {
        right: -40px;
        left: inherit !important;
    }

    .a-40 {
        left: 40px;
        right: 40px;
    }

    .x-40,
    .y-40 {
        right: 40px;
        left: 40px;
    }

    .r-45 {
        left: 45px;
        right: inherit !important;
    }

    .l-45 {
        right: 45px;
        left: inherit !important;
    }

    .r--45 {
        left: -45px;
        right: inherit !important;
    }

    .l--45 {
        right: -45px;
        left: inherit !important;
    }

    .a-45 {
        left: 45px;
        right: 45px;
    }

    .x-45,
    .y-45 {
        right: 45px;
        left: 45px;
    }

    .r-50 {
        left: 50px;
        right: inherit !important;
    }

    .l-50 {
        right: 50px;
        left: inherit !important;
    }

    .r--50 {
        left: -50px;
        right: inherit !important;
    }

    .l--50 {
        right: -50px;
        left: inherit !important;
    }

    .a-50 {
        left: 50px;
        right: 50px;
    }

    .x-50,
    .y-50 {
        right: 50px;
        left: 50px;
    }

    .r-55 {
        left: 55px;
        right: inherit !important;
    }

    .l-55 {
        right: 55px;
        left: inherit !important;
    }

    .r--55 {
        left: -55px;
        right: inherit !important;
    }

    .l--55 {
        right: -55px;
        left: inherit !important;
    }

    .a-55 {
        left: 55px;
        right: 55px;
    }

    .x-55,
    .y-55 {
        right: 55px;
        left: 55px;
    }

    .r-60 {
        left: 60px;
        right: inherit !important;
    }

    .l-60 {
        right: 60px;
        left: inherit !important;
    }

    .r--60 {
        left: -60px;
        right: inherit !important;
    }

    .l--60 {
        right: -60px;
        left: inherit !important;
    }

    .a-60 {
        left: 60px;
        right: 60px;
    }

    .x-60,
    .y-60 {
        right: 60px;
        left: 60px;
    }

    .r-65 {
        left: 65px;
        right: inherit !important;
    }

    .l-65 {
        right: 65px;
        left: inherit !important;
    }

    .r--65 {
        left: -65px;
        right: inherit !important;
    }

    .l--65 {
        right: -65px;
        left: inherit !important;
    }

    .a-65 {
        left: 65px;
        right: 65px;
    }

    .x-65,
    .y-65 {
        right: 65px;
        left: 65px;
    }

    .r-70 {
        left: 70px;
        right: inherit !important;
    }

    .l-70 {
        right: 70px;
        left: inherit !important;
    }

    .r--70 {
        left: -70px;
        right: inherit !important;
    }

    .l--70 {
        right: -70px;
        left: inherit !important;
    }

    .a-70 {
        left: 70px;
        right: 70px;
    }

    .x-70,
    .y-70 {
        right: 70px;
        left: 70px;
    }

    .r-75 {
        left: 75px;
        right: inherit !important;
    }

    .l-75 {
        right: 75px;
        left: inherit !important;
    }

    .r--75 {
        left: -75px;
        right: inherit !important;
    }

    .l--75 {
        right: -75px;
        left: inherit !important;
    }

    .a-75 {
        left: 75px;
        right: 75px;
    }

    .x-75,
    .y-75 {
        right: 75px;
        left: 75px;
    }

    .r-80 {
        left: 80px;
        right: inherit !important;
    }

    .l-80 {
        right: 80px;
        left: inherit !important;
    }

    .r--80 {
        left: -80px;
        right: inherit !important;
    }

    .l--80 {
        right: -80px;
        left: inherit !important;
    }

    .a-80 {
        left: 80px;
        right: 80px;
    }

    .x-80,
    .y-80 {
        right: 80px;
        left: 80px;
    }

    .r-85 {
        left: 85px;
        right: inherit !important;
    }

    .l-85 {
        right: 85px;
        left: inherit !important;
    }

    .r--85 {
        left: -85px;
        right: inherit !important;
    }

    .l--85 {
        right: -85px;
        left: inherit !important;
    }

    .a-85 {
        left: 85px;
        right: 85px;
    }

    .x-85,
    .y-85 {
        right: 85px;
        left: 85px;
    }

    .r-90 {
        left: 90px;
        right: inherit !important;
    }

    .l-90 {
        right: 90px;
        left: inherit !important;
    }

    .r--90 {
        left: -90px;
        right: inherit !important;
    }

    .l--90 {
        right: -90px;
        left: inherit !important;
    }

    .a-90 {
        left: 90px;
        right: 90px;
    }

    .x-90,
    .y-90 {
        right: 90px;
        left: 90px;
    }

    .r-95 {
        left: 95px;
        right: inherit !important;
    }

    .l-95 {
        right: 95px;
        left: inherit !important;
    }

    .r--95 {
        left: -95px;
        right: inherit !important;
    }

    .l--95 {
        right: -95px;
        left: inherit !important;
    }

    .a-95 {
        left: 95px;
        right: 95px;
    }

    .x-95,
    .y-95 {
        right: 95px;
        left: 95px;
    }

    .r-100 {
        left: 100px;
        right: inherit !important;
    }

    .l-100 {
        right: 100px;
        left: inherit !important;
    }

    .r--100 {
        left: -100px;
        right: inherit !important;
    }

    .l--100 {
        right: -100px;
        left: inherit !important;
    }

    .a-100 {
        left: 100px;
        right: 100px;
    }

    .x-100,
    .y-100 {
        right: 100px;
        left: 100px;
    }

    .r--5 {
        left: -5px;
        right: inherit !important;
    }

    .fixed-top {
        left: 0;
        right: 0;
    }

    .fixed-bottom {
        left: 0;
        right: 0;
    }

    @media (min-width: 480px) {
        .r-xs-auto {
            left: auto;
            right: inherit !important;
        }

        .l-xs-auto {
            right: auto;
            left: inherit !important;
        }
    }

    @media (min-width: 576px) {
        .r-sm-auto {
            left: auto;
            right: inherit !important;
        }

        .l-sm-auto {
            right: auto;
            left: inherit !important;
        }
    }

    @media (min-width: 768px) {
        .r-md-auto {
            left: auto;
            right: inherit !important;
        }

        .l-md-auto {
            right: auto;
            left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .r-lg-auto {
            left: auto;
            right: inherit !important;
        }

        .l-lg-auto {
            right: auto;
            left: inherit !important;
        }
    }

    @media (min-width: 1200px) {
        .r-xl-auto {
            left: auto;
            right: inherit !important;
        }

        .l-xl-auto {
            right: auto;
            left: inherit !important;
        }
    }

    /* ###### Position  ###### */

    /* ###### Typography  ###### */

    .tx-right {
        text-align: left;
    }

    .tx-left {
        text-align: right;
    }

    .tx-right-f {
        text-align: left !important;
    }

    .tx-left-f {
        text-align: right !important;
    }

    @media (min-width: 480px) {
        .tx-xs-right {
            text-align: left;
        }

        .tx-xs-left {
            text-align: right;
        }

        .tx-xs-right-f {
            text-align: left !important;
        }

        .tx-xs-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 576px) {
        .tx-sm-right {
            text-align: left;
        }

        .tx-sm-left {
            text-align: right;
        }

        .tx-sm-right-f {
            text-align: left !important;
        }

        .tx-sm-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 768px) {
        .tx-md-right {
            text-align: left;
        }

        .tx-md-left {
            text-align: right;
        }

        .tx-md-right-f {
            text-align: left !important;
        }

        .tx-md-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 992px) {
        .tx-lg-right {
            text-align: left;
        }

        .tx-lg-left {
            text-align: right;
        }

        .tx-lg-right-f {
            text-align: left !important;
        }

        .tx-lg-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 1200px) {
        .tx-xl-right {
            text-align: left;
        }

        .tx-xl-left {
            text-align: right;
        }

        .tx-xl-right-f {
            text-align: left !important;
        }

        .tx-xl-left-f {
            text-align: right !important;
        }
    }

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-start {
            text-align: right !important;
        }

        .text-sm-end {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-start {
            text-align: right !important;
        }

        .text-md-end {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-start {
            text-align: right !important;
        }

        .text-lg-end {
            text-align: left !important;
        }
    }

    @media (min-width: 1200px) {
        .text-xl-start {
            text-align: right !important;
        }

        .text-xl-end {
            text-align: left !important;
        }
    }

    /* ###### Typography  ###### */

    /* ############### CUSTOM ELEMENT STYLES ############### */
    /* ###### Component-cols ###### */
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-left: 15px;
        padding-right: 15px;
    }

    .offset-1 {
        margin-right: 8.33333%;
        margin-left: inherit;
    }

    .offset-2 {
        margin-right: 16.66667%;
        margin-left: inherit;
    }

    .offset-3 {
        margin-right: 25%;
        margin-left: inherit;
    }

    .offset-4 {
        margin-right: 33.33333%;
        margin-left: inherit;
    }

    .offset-5 {
        margin-right: 41.66667%;
        margin-left: inherit;
    }

    .offset-6 {
        margin-right: 50%;
        margin-left: inherit;
    }

    .offset-7 {
        margin-right: 58.33333%;
        margin-left: inherit;
    }

    .offset-8 {
        margin-right: 66.66667%;
        margin-left: inherit;
    }

    .offset-9 {
        margin-right: 75%;
        margin-left: inherit;
    }

    .offset-10 {
        margin-right: 83.33333%;
        margin-left: inherit;
    }

    .offset-11 {
        margin-right: 91.66667%;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .offset-sm-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-sm-1 {
            margin-right: 8.33333%;
            margin-left: inherit;
        }

        .offset-sm-2 {
            margin-right: 16.66667%;
            margin-left: inherit;
        }

        .offset-sm-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-sm-4 {
            margin-right: 33.33333%;
            margin-left: inherit;
        }

        .offset-sm-5 {
            margin-right: 41.66667%;
            margin-left: inherit;
        }

        .offset-sm-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-sm-7 {
            margin-right: 58.33333%;
            margin-left: inherit;
        }

        .offset-sm-8 {
            margin-right: 66.66667%;
            margin-left: inherit;
        }

        .offset-sm-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-sm-10 {
            margin-right: 83.33333%;
            margin-left: inherit;
        }

        .offset-sm-11 {
            margin-right: 91.66667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .offset-md-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-md-1 {
            margin-right: 8.33333%;
            margin-left: inherit;
        }

        .offset-md-2 {
            margin-right: 16.66667%;
            margin-left: inherit;
        }

        .offset-md-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-md-4 {
            margin-right: 33.33333%;
            margin-left: inherit;
        }

        .offset-md-5 {
            margin-right: 41.66667%;
            margin-left: inherit;
        }

        .offset-md-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-md-7 {
            margin-right: 58.33333%;
            margin-left: inherit;
        }

        .offset-md-8 {
            margin-right: 66.66667%;
            margin-left: inherit;
        }

        .offset-md-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-md-10 {
            margin-right: 83.33333%;
            margin-left: inherit;
        }

        .offset-md-11 {
            margin-right: 91.66667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .offset-lg-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-lg-1 {
            margin-right: 8.33333%;
            margin-left: inherit;
        }

        .offset-lg-2 {
            margin-right: 16.66667%;
            margin-left: inherit;
        }

        .offset-lg-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-lg-4 {
            margin-right: 33.33333%;
            margin-left: inherit;
        }

        .offset-lg-5 {
            margin-right: 41.66667%;
            margin-left: inherit;
        }

        .offset-lg-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-lg-7 {
            margin-right: 58.33333%;
            margin-left: inherit;
        }

        .offset-lg-8 {
            margin-right: 66.66667%;
            margin-left: inherit;
        }

        .offset-lg-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-lg-10 {
            margin-right: 83.33333%;
            margin-left: inherit;
        }

        .offset-lg-11 {
            margin-right: 91.66667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .offset-xl-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-xl-1 {
            margin-right: 8.33333%;
            margin-left: inherit;
        }

        .offset-xl-2 {
            margin-right: 16.66667%;
            margin-left: inherit;
        }

        .offset-xl-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-xl-4 {
            margin-right: 33.33333%;
            margin-left: inherit;
        }

        .offset-xl-5 {
            margin-right: 41.66667%;
            margin-left: inherit;
        }

        .offset-xl-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-xl-7 {
            margin-right: 58.33333%;
            margin-left: inherit;
        }

        .offset-xl-8 {
            margin-right: 66.66667%;
            margin-left: inherit;
        }

        .offset-xl-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-xl-10 {
            margin-right: 83.33333%;
            margin-left: inherit;
        }

        .offset-xl-11 {
            margin-right: 91.66667%;
            margin-left: inherit;
        }
    }

    @media (min-width: 1600px) {
        .offset-xxl-0 {
            margin-right: 0;
            margin-left: inherit;
        }

        .offset-xxl-1 {
            margin-right: 8.33333%;
            margin-left: inherit;
        }

        .offset-xxl-2 {
            margin-right: 16.66667%;
            margin-left: inherit;
        }

        .offset-xxl-3 {
            margin-right: 25%;
            margin-left: inherit;
        }

        .offset-xxl-4 {
            margin-right: 33.33333%;
            margin-left: inherit;
        }

        .offset-xxl-5 {
            margin-right: 41.66667%;
            margin-left: inherit;
        }

        .offset-xxl-6 {
            margin-right: 50%;
            margin-left: inherit;
        }

        .offset-xxl-7 {
            margin-right: 58.33333%;
            margin-left: inherit;
        }

        .offset-xxl-8 {
            margin-right: 66.66667%;
            margin-left: inherit;
        }

        .offset-xxl-9 {
            margin-right: 75%;
            margin-left: inherit;
        }

        .offset-xxl-10 {
            margin-right: 83.33333%;
            margin-left: inherit;
        }

        .offset-xxl-11 {
            margin-right: 91.66667%;
            margin-left: inherit;
        }
    }

    /* ###### Component-cols ###### */

    /* ###### Tags  ###### */

    .tag-pill {
        padding-left: 0.9em;
        padding-right: 0.9em;
    }

    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;
    }

    .tag-addon:last-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .tag-avatar {
        border-radius: 0 3px 3px 0;
        margin: 0 -0.5rem 0 0.5rem;
    }

    .tags > .tag:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    /* ###### Tags  ###### */

    /* ###### Tabs ###### */

    .nav-tabs .nav-link {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tabs .nav-link + .nav-link {
        margin-right: 3px;
        margin-left: inherit;
    }

    .nav-tabs .dropdown-menu {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .main-nav-tabs {
        padding: 15px 15px 0;
    }

    .main-nav-tabs .lSSlideOuter {
        padding-right: 32px;
        padding-left: 35px;
    }

    .main-nav-tabs .lSAction > a::before {
        right: 0;
        left: 0;
    }

    .main-nav-tabs .lSAction > a.lSPrev {
        right: -32px;
        left: inherit !important;
    }

    .main-nav-tabs .lSAction > a.lSNext {
        left: -35px;
        right: inherit !important;
    }

    /* ###### Tags  ###### */

    /* ###### Navbar  ###### */
    .nav {
        padding-right: 0;
        padding-left: initial;
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub {
            right: 0;
            left: inherit !important;
        }

        .main-navbar .nav-sub-item {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .main-navbar-backdrop {
        right: 0;
        left: 0;
    }

    .main-navbar {
        right: 0;
        left: inherit !important;
    }

    .main-navbar .nav-sub-link:before {
        right: -6px;
        left: inherit !important;
    }

    @media (min-width: 992px) {
        .main-navbar {
            right: auto;
            left: inherit !important;
            border-left: 0;
            border-right: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .main-navbar > .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .main-navbar .nav-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-navbar .nav-link.with-sub::before {
        right: 56%;
        left: inherit !important;
        margin-right: -7px;
        margin-left: inherit;
        border-left-color: rgba(61, 119, 180, 0.2);
    }

    .main-navbar .nav-sub-item > .nav-sub {
        right: 159px;
        left: inherit !important;
    }

    .main-navbar .nav-sub-link.with-sub::after {
        margin-right: auto;
        margin-left: inherit;
    }

    .main-navbar .nav-sub-mega {
        right: 0;
        left: 0;
    }

    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        margin-right: 30px;
        margin-left: inherit;
    }

    .main-navbar .nav-sub-mega .nav-sub-link {
        margin-right: 0;
        margin-left: inherit;
        padding-right: 0;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        .main-navbar .nav-item + .nav-item {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-navbar .nav-item + .nav-item {
            padding-left: 20px;
            padding-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link i {
            margin-left: 8px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link.with-sub::after {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) and (max-width: 1477px) {
        .main-navbar .nav-sub-item > .nav-sub {
            right: -200px !important;
            left: inherit !important;
        }
    }

    @media (max-width: 991.98px) {
        .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item {
            margin-right: 37px;
            margin-left: inherit;
        }

        .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item > .nav-sub-link {
            padding-right: 10px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-link {
            margin-right: 18px;
            margin-left: inherit;
            padding-right: 8px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-link.with-sub::after {
            margin-left: 22px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .container > div + div,
        .main-navbar .nav-sub-mega .container-fluid > div + div {
            border-right: 1px solid $border;
            border-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .nav + .nav {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-navbar .nav-sub-mega .nav + .nav {
            margin-right: 25px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .main-navbar-search .btn,
    .main-navbar-search .sp-container button {
        left: 0;
        right: inherit !important;
        padding-right: 20px;
        padding-left: 20px;
    }

    .sp-container .main-navbar-search button {
        left: 0;
        right: inherit !important;
        padding-right: 20px;
        padding-left: 20px;
    }

    .main-navbar-two .nav-sub-item > .nav-sub {
        right: 178px;
        left: inherit !important;
    }

    .main-navbar-three .nav-item + .nav-item {
        border-right-width: 0;
        border-left-width: inherit !important;
        padding-right: 0;
        padding-left: inherit;
    }

    .main-navbar-three .nav-sub-item .nav-sub {
        right: 182px;
        left: inherit !important;
    }

    @media (min-width: 992px) {
        .main-navbar-three .nav-item + .nav-item {
            margin-right: 25px;
            margin-left: inherit;
        }
    }

    .navbar.navbar-static .navbar-header {
        float: right;
    }

    .navbar .navbar-toggle.toggle-left {
        float: right;
        margin-right: 15px;
        margin-left: inherit;
    }

    .navbar .navbar-toggle.toggle-right {
        float: left;
        margin-left: 15px;
        margin-right: inherit;
    }

    /* ###### Navbar  ###### */

    /* ###### Wizard ###### */

    .wizard > .steps > ul li .title {
        margin-right: 5px;
        margin-left: inherit;
    }

    .wizard > .steps > ul li + li {
        margin-right: 5px;
        margin-left: inherit;
    }

    .wizard.vertical > .steps ul li + li {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li .title {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li + li {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .wizard > .steps > ul li + li {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps ul li + li {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .content {
            float: left;
            border-right: 1px solid $border;
            border-left: initial;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .actions {
            float: left;
            border-right: 1px solid $border;
            border-left: inherit !important;
        }
    }

    .wizard-style-1 > .steps > ul a .title,
    .wizard-style-1 > .steps > ul a:hover .title,
    .wizard-style-1 > .steps > ul a:active .title {
        margin-left: 20px;
        margin-right: 20px;
    }

    .wizard-style-2 > .steps > ul a .title,
    .wizard-style-2 > .steps > ul a:hover .title,
    .wizard-style-2 > .steps > ul a:active .title {
        margin-left: 20px;
        margin-right: inherit;
    }

    /* ###### Wizard ###### */

    /* ###### Perfect-scroll  ###### */

    .ps__rail-y {
        left: 0;
        right: inherit !important;
    }

    .ps__thumb-y {
        left: 0px;
        right: inherit !important;
    }

    /* ###### Perfect-scroll  ###### */

    /* ###### Radio ###### */

    .rdiobox span {
        padding-right: 13px;
        padding-left: inherit;
    }

    .rdiobox span:before {
        right: 0;
        left: inherit !important;
    }

    .rdiobox span:after {
        right: 5px;
        left: inherit !important;
    }

    .rdiobox span:empty {
        padding-right: 0;
        padding-left: inherit;
    }

    .form-group-rdiobox .rdiobox + .rdiobox {
        margin-right: 30px;
        margin-left: inherit;
    }

    .form-group-rdiobox .rdiobox span {
        padding-right: 0;
        padding-left: inherit;
    }

    /* ###### Radio ###### */

    /* ###### Custom-select ###### */
    .form-select {
        padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    }

    .form-select[multiple],
    .form-select[size]:not([size="1"]) {
        padding-left: 0.75rem;
        padding-right: inherit;
    }

    .form-select-sm {
        padding-right: 0.5rem;
        padding-left: inherit;
    }

    .form-select-lg {
        padding-right: 1rem;
        padding-left: inherit;
    }

    .custom-file-label {
        left: 0;
        right: 0;
    }

    .custom-file-label::after {
        left: 0;
        right: inherit !important;
        border-radius: 3px 0 0 3px;
    }

    /* ###### Custom-select ###### */

    /* ###### Custom-Range ###### */

    .custom-range::-ms-thumb {
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

    .custom-range::-ms-fill-upper {
        margin-left: 15px;
        margin-right: inherit;
    }

    /* ###### Custom-Range ###### */

    /* ###### Custom-Switch ###### */

    .custom-switch-indicator:before {
        right: 1px;
        left: inherit !important;
        transition: 0.3s left;
    }

    .custom-switch-input:checked ~ .custom-switch-indicator:before {
        right: calc(1rem + 1px);
        left: inherit !important;
    }

    .custom-switch-description {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .custom-switch {
        padding-right: 2.25rem;
        padding-right: 0;
        padding-left: initial;
    }

    .custom-switch .custom-control-label::before {
        right: -2.25rem;
        left: inherit !important;
    }

    .custom-switch .custom-control-label::after {
        right: calc(-2.25rem + 2px);
        left: inherit !important;
    }

    /* ###### Custom-Switch ###### */

    /* ###### Timeline  ###### */

    .latest-timeline1-icon {
        right: 0;
        right: -38px;
    }

    .latest-timeline ul.timeline:before {
        right: 15px;
        left: inherit !important;
    }

    .latest-timeline ul.timeline > li {
        padding-right: 1rem;
        padding-left: inherit;
    }

    .vtimeline:before {
        right: 50%;
        left: inherit !important;
        margin-right: -1.5px;
        margin-left: inherit;
    }

    .vtimeline .timeline-wrapper {
        padding-left: 90px;
        padding-right: inherit;
    }

    .vtimeline .timeline-wrapper .timeline-panel {
        border-radius: 0 0 11px 11px;
        margin-right: 10%;
        margin-left: inherit;
    }

    .vtimeline .timeline-wrapper .timeline-panel:before {
        right: 0;
        left: 0;
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
        left: -14px;
        right: inherit !important;
        border-right: 14px solid #f7f6f9;
        border-left: 0 solid #f7f6f9;
    }

    .vtimeline .timeline-wrapper .timeline-panel .timeline-title {
        margin: 0 0 0.625rem;
    }

    .vtimeline .timeline-wrapper .timeline-badge {
        right: calc(50% - 9px);
        left: inherit !important;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }

    .vtimeline .timeline-wrapper.timeline-inverted {
        padding-left: 0;
        padding-right: 90px;
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
        margin-right: auto;
        margin-left: 10%;
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
        border-right-width: 0;
        border-left-width: 14px;
        right: -14px !important;
        left: auto;
    }

    /* ###### Timeline  ###### */

    /* ###### Image ###### */

    .img-sm {
        margin-left: 20px;
        margin-right: inherit;
    }

    /* ###### Image ###### */

    /* ###### Avatar ###### */

    .avatar-list {
        margin: 0 0 -0.5rem;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .main-avatar::after {
        left: 1px;
        right: inherit !important;
    }

    .avatar-md::after {
        left: 2px;
        right: inherit !important;
    }

    .avatar-lg::after {
        left: 4px;
        right: inherit !important;
    }

    .avatar-xl::after {
        left: 5px;
        right: inherit !important;
    }

    .avatar-xxl::after {
        left: 7px;
        right: inherit !important;
    }

    .demo-avatar-group .main-img-user + .main-img-user,
    .demo-avatar-group .main-img-user + .main-avatar {
        margin-right: 5px;
        margin-left: inherit;
    }

    .demo-avatar-group .main-avatar + .main-img-user,
    .demo-avatar-group .main-avatar + .main-avatar {
        margin-right: -5px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .demo-avatar-group .main-img-user + .main-img-user,
        .demo-avatar-group .main-img-user + .main-avatar {
            margin-right: -9px;
            margin-left: inherit;
        }

        .demo-avatar-group .main-avatar + .main-img-user,
        .demo-avatar-group .main-avatar + .main-avatar {
            margin-right: -9px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        #shapes .demo-avatar-group .main-img-user + .main-img-user,
        #shapes .demo-avatar-group .main-img-user + .main-avatar {
            margin-right: 9px;
            margin-left: inherit;
        }
    }

    /* ###### Avatars ###### */

    /* ###### List ###### */

    .main-list-item > div:last-child {
        text-align: left;
    }

    /* ###### List ###### */

    /* ###### Checkbox ###### */

    .ckbox span {
        padding-right: 16px;
        padding-left: inherit;
    }

    .ckbox span:empty {
        float: right;
        padding-right: 0;
        padding-left: inherit;
    }

    .ckbox span:before {
        right: 0;
        left: inherit !important;
    }

    .ckbox span:after {
        right: 0;
        left: inherit !important;
    }

    .custom-control-label-md {
        padding-right: 15px;
        padding-left: inherit;
    }

    .custom-control-label-lg {
        padding-right: 15px;
        padding-left: inherit;
    }

    .custom-switch-input:checked ~ .custom-switch-indicator-lg:before {
        right: calc(1.46rem + 1px);
        left: inherit !important;
    }

    .custom-switch-indicator-lg:before {
        right: 1px;
        left: inherit !important;
        transition: 0.3s left;
    }

    .custom-switch-indicator-xl:before {
        right: 1px;
        left: inherit !important;
        transition: 0.3s left;
    }

    .custom-switch-input:checked ~ .custom-switch-indicator-xl:before {
        right: calc(1.96rem + 1px) !important;
        left: inherit !important;
    }

    /* ###### Checkbox ###### */

    /* ###### Toggle ###### */

    .main-toggle span {
        right: 3px;
        left: inherit !important;
    }

    .main-toggle span::before {
        right: -25px;
        left: inherit !important;
    }

    .main-toggle span::after {
        left: -29px;
        right: auto;
    }

    .main-toggle.on span {
        right: 37px;
        left: inherit !important;
    }

    /* ###### Toggle ###### */

    /* ###### List-group ###### */
    .list-group {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-group-horizontal .list-group-item {
        margin-left: -1px;
        margin-right: inherit;
    }

    .list-group-horizontal .list-group-item:first-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
    }

    .list-group-horizontal .list-group-item:last-child {
        margin-left: 0;
        margin-right: inherit;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }

    .listgroup-example2 ul ul {
        margin: 10px 0 0 0;
    }

    .listorder {
        margin-left: 0;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .list-group-horizontal-sm .list-group-item {
            margin-left: -1px;
            margin-right: inherit;
        }

        .list-group-horizontal-sm .list-group-item:first-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
        }

        .list-group-horizontal-sm .list-group-item:last-child {
            margin-left: 0;
            margin-right: inherit;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 0;
        }
    }

    @media (min-width: 768px) {
        .list-group-horizontal-md .list-group-item {
            margin-left: -1px;
            margin-right: inherit;
        }

        .list-group-horizontal-md .list-group-item:first-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
        }

        .list-group-horizontal-md .list-group-item:last-child {
            margin-left: 0;
            margin-right: inherit;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 0;
        }
    }

    @media (min-width: 992px) {
        .list-group-horizontal-lg .list-group-item {
            margin-left: -1px;
            margin-right: inherit;
        }

        .list-group-horizontal-lg .list-group-item:first-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
        }

        .list-group-horizontal-lg .list-group-item:last-child {
            margin-left: 0;
            margin-right: inherit;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 0;
        }
    }

    @media (min-width: 1200px) {
        .list-group-horizontal-xl .list-group-item {
            margin-left: -1px;
            margin-right: inherit;
        }

        .list-group-horizontal-xl .list-group-item:first-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
        }

        .list-group-horizontal-xl .list-group-item:last-child {
            margin-left: 0;
            margin-right: inherit;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 0;
        }
    }

    .list-group-flush .list-group-item {
        border-left: 0;
        border-right: 0;
    }

    /* ###### List-group ###### */

    /* ###### Custom-control ###### */
    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .custom-control-inline {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .custom-control-label::before {
        right: -1.5rem;
        left: inherit !important;
    }

    .custom-control-label::after {
        right: -1.5rem;
        left: inherit !important;
    }

    /* ###### Custom-control ###### */

    /* ###### Custom-styles ###### */

    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
        right: 0;
        left: inherit !important;
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }

    @media (min-width: 1200px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }

    .overflow-auto {
        overflow: auto !important;
    }

    .stretched-link::after {
        left: 0;
        right: 0;
    }

    &.main-body::before {
        right: 70%;
        left: inherit !important;
    }

    .main-notification-list .media:hover::before,
    .main-notification-list .media:focus::before,
    .main-message-list .media:hover::before,
    .main-message-list .media:focus::before {
        right: 0px;
        left: 0px;
    }

    .main-notification-list .media-body,
    .main-message-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-home-slider .chart-wrapper {
        right: 0;
        left: 0;
    }

    .main-home-slider .card-columns {
        right: 24%;
        left: inherit !important;
    }

    @media (min-width: 576px) {
        .main-home-slider .card-columns {
            right: 16%;
            left: inherit !important;
        }
    }

    @media (min-width: 768px) {
        .main-home-slider .card-columns {
            right: auto;
            left: -45%;
        }
    }

    @media (min-width: 992px) {
        .main-home-slider .card-columns {
            left: -30%;
            right: inherit !important;
        }
    }

    @media (min-width: 1200px) {
        .main-home-slider .card-columns {
            left: -15%;
            right: inherit !important;
        }
    }

    @media (min-width: 1300px) {
        .main-home-slider .card-columns {
            left: -5%;
            right: inherit !important;
        }
    }

    .main-home-content {
        right: 20px;
        left: 20px;
    }

    .main-home-content .logo-group i + i {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .main-home-content {
            right: 40px;
            left: auto;
        }
    }

    .main-home-content p:last-of-type .btn + .btn,
    .main-home-content p:last-of-type .sp-container button + .btn {
        margin-right: 5px;
        margin-left: inherit;
    }

    .sp-container .main-home-content p:last-of-type button + .btn,
    .main-home-content p:last-of-type .sp-container .btn + button,
    .sp-container .main-home-content p:last-of-type .btn + button,
    .main-home-content p:last-of-type .sp-container button + button,
    .sp-container .main-home-content p:last-of-type button + button {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-home-content nav:last-child a + a {
        margin-right: 8px;
        margin-left: inherit;
    }

    .main-toggle-group-demo .main-toggle + .main-toggle {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-content-left-components {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .main-page-header {
        padding: 30px 0 40px;
    }

    .main-page-content-list li i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .main-syntax-wrapper .badge {
        left: 5px;
        right: inherit !important;
    }

    .main-media-list-reviews .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-star-group span:last-child {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-star-item + .main-star-item {
        margin-right: 3px;
        margin-left: inherit;
    }

    .main-media-list-activity .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .hor-top-header .header-brand-img.desktop-logo {
            right: 0;
            left: 0;
        }
    }

    .legend {
        margin-left: 10px;
        margin-right: inherit;
    }

    .best-emp {
        left: 0;
        right: inherit !important;
    }

    .userlist-table .dot-label {
        margin-left: 10px !important;
        margin-right: inherit;
    }

    @media (max-width: 768px) {
        .nav.main-nav-line.main-nav-line-chat.card-body .nav-link + .nav-link {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (max-width: 576px) {
        .project-work.list-unstyled li {
            margin-left: 1rem;
            margin-right: inherit;
        }
    }

    .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
        border-radius: 0 3px 0 3px;
        border-left: 0;
        border-right: inherit !important;
    }

    @media (max-width: 767px) {
        .responsive-navbar.navbar .navbar-collapse {
            padding-right: 1rem;
            padding-left: 0.3rem;
            right: 0;
            left: 0;
        }
    }

    @media (max-width: 991px) {
        .header-search .select2-container--default .select2-selection--single {
            border-radius: 0 3px 3px 0;
            border-left: 0;
            border-right: inherit !important;
        }
    }

    @media (max-width: 767px) {
        .responsive-navbar .dropdown-menu {
            right: 10px !important;
            left: 10px !important;
        }

        .responsive-navbar .nav-link .badge {
            left: 1px;
            right: inherit !important;
        }
    }

    .page-header {
        margin: 1.5rem 0 1.5rem;
        margin: 1.8rem 0 1.5rem 0;
    }

    .page-header .breadcrumb {
        padding-right: 0 !important;
        padding-left: inherit;
    }

    .page-header .breadcrumb-item a {
        padding-right: 5px;
        padding-left: inherit;
    }

    @media (max-width: 991px) {
        .header-search .select2-container--default .select2-selection--single .select2-selection__arrow b {
            right: 93% !important;
            left: inherit !important;
        }

        .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
            margin-right: 35px;
            margin-left: inherit;
        }

        .main-sidebar-hide .side-header {
            right: 0px;
            left: 0px;
        }

        .main-content .page-header .btn.btn-list {
            padding-right: 0;
            padding-left: inherit;
            text-align: right;
        }
    }

    @media (max-width: 767px) {
        div.dataTables_wrapper div.dataTables_filter input {
            margin-right: 0 !important;
            margin-left: inherit;
        }

        div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
            padding-left: 0;
            padding-right: inherit;
        }

        .vtimeline:before {
            right: 6px !important;
            left: inherit !important;
        }

        .vtimeline .timeline-wrapper {
            padding-left: 0 !important;
            padding-right: inherit;
        }

        .vtimeline .timeline-wrapper .timeline-badge {
            right: -2px !important;
            left: inherit !important;
        }

        .vtimeline .timeline-wrapper .timeline-panel {
            margin-right: 12% !important;
            margin-left: initial !important;
        }

        .vtimeline .timeline-wrapper .timeline-panel:after {
            border-left: 14px solid #f7f6f9 !important;
            border-right: 0 solid #f7f6f9 !important;
            right: -14px !important;
            left: auto !important;
        }

        .vtimeline .timeline-wrapper.timeline-inverted {
            padding-right: 0 !important;
            padding-left: inherit;
        }

        .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
            margin-left: 0 !important;
            margin-right: 12% !important;
        }
    }

    @media (max-width: 575.98px) {
        .modal.pos-static .modal-footer > :not(:first-child) {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar-hide .main-content.side-content {
            margin-right: 0;
            margin-left: inherit;
        }

        &.horizontalmenu .main-footer {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    #back-to-top {
        left: 20px;
        right: initial !important;
    }

    .custom-leftnav .main-content-left-components {
        border-left: 0;
        border-right: inherit !important;
    }

    .component-item a:before {
        margin-left: 8px;
        margin-right: inherit;
    }

    .skill-tags a {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .reviewnavs {
        padding-right: 0;
        padding-left: inherit;
    }

    .reviewnavs li {
        margin-left: 5px;
        margin-right: inherit;
    }

    .dash-icon {
        left: 10px;
        right: inherit !important;
    }

    .pricing-tabs .nav-price li a {
        padding: 10px 20px 10px 20px;
    }

    .price-value1 span {
        margin-right: 17px;
        margin-left: inherit;
    }

    .pricingContent2 ul li {
        padding: 25px 0 0 0;
    }

    .pricingContent1 ul li {
        padding: 18px 0 0 0;
    }

    .pricingtabs-badge {
        left: 0;
        right: inherit !important;
    }

    .pricingtabs-badge .badge {
        float: left;
        left: -71px;
        right: inherit !important;
        transform: rotate(-45deg);
    }

    .highlight {
        margin: 1rem 0 2rem;
    }

    .clipboard-icon {
        left: 10px;
        right: inherit !important;
    }

    .our-team .picture::after {
        right: 0;
        left: inherit !important;
    }

    .task-list {
        padding: 30px 0 0;
    }

    .task-list:after {
        right: 30px;
        left: inherit !important;
    }

    .task-list li {
        padding-right: 55px;
        padding-left: inherit;
    }

    .task-list li .task-icon {
        right: 22px;
        left: inherit !important;
    }

    .item-card .product-info {
        right: 0;
        left: 0;
    }

    .dot-label {
        margin-left: 5px;
        margin-right: initial;
    }

    .activity-block .task-list li {
        padding-right: 3rem;
    }

    .activity-block .task-list:after {
        right: 18px;
        left: inherit !important;
    }

    .activity-block .task-list li .task-icon {
        right: 1px;
        left: inherit !important;
    }

    #global-loader {
        right: 0;
        left: 0;
    }

    .loader-img {
        left: 0;
        right: 0;
    }

    .user-info {
        padding-right: 0px;
        padding-left: inherit;
    }

    .page-header .page-title i {
        margin-left: 8px !important;
        margin-right: inherit;
    }

    .feature .feature-icon {
        right: 0;
        left: 0;
    }

    .search-imgs li {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .search-imgs li img {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .handle-counter .counter-minus,
    .handle-counter .counter-plus {
        float: right;
    }

    .handle-counter input {
        float: right;
    }

    .counter-minus {
        border-radius: 0 3px 3px 0;
    }

    .counter-plus {
        border-radius: 3px 0 0 3px;
    }

    .itemside .info {
        padding-right: 15px;
        padding-left: 7px;
    }

    .file-browser .btn-primary {
        border-radius: 6px 0 0 6px !important;
    }

    .project-status:before {
        margin-left: 12px;
        margin-right: inherit;
    }

    .project-status.success:before {
        border-right: 3px solid #19b159;
        border-left: inherit !important;
    }

    .project-status.danger:before {
        border-right: 3px solid #fd6074;
        border-left: inherit !important;
    }

    .project-status.warning:before {
        border-right: 3px solid #ecb529;
        border-left: inherit !important;
    }

    .forth.circle .chart-circle-value.circle-style {
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
    }

    @media (max-width: 699px) {
        &.horizontalmenu .page-header {
            display: block;
        }

        .breadcrumb-5 a:first-child {
            padding-right: 16px !important;
            padding-left: inherit;
        }
    }

    .user-lock .option-dots:hover {
        background: transparent;
    }

    @media (min-width: 991px) {
        .hor-header.top-header .header-brand2 .top-header-logo1 {
            right: 0;
            left: 0;
        }

        .dark-theme .hor-header.top-header .header-brand2 .top-header-logo2 {
            right: 0;
            left: 0;
        }

        .header-dark .hor-header.top-header .header-brand2 .top-header-logo2 {
            right: 0;
            left: 0;
        }
    }

    @media (max-width: 480px) {
        #group .pd-x-25 {
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    @media (min-width: 991px) {
        .color-header .hor-header.top-header .header-brand2 .top-header-logo2 {
            right: 0;
            left: 0;
        }
    }

    /* ###### Custom-styles ###### */

    /* ###### Select Group ###### */

    .selectgroup-item + .selectgroup-item {
        margin-right: -1px;
        margin-left: inherit;
    }

    .selectgroup-item:not(:first-child) .selectgroup-button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .selectgroup-item:not(:last-child) .selectgroup-button {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .selectgroup-input {
        right: 0;
        left: inherit !important;
    }

    .selectgroup-button-icon {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .selectgroup-pills .selectgroup-item {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    /* ###### Select Group ###### */

    /* ###### Colorinput ###### */
    .colorinput {
        margin-left: 5px;
        margin-right: inherit;
    }

    .colorinput-color:before {
        right: 0.25rem;
        left: inherit !important;
    }

    /* ###### Colorinput ###### */

    /* ###### ie css ###### */

    *::-ms-backdrop,
    .bg-img:before {
        right: 0;
        left: inherit !important;
    }

    /* ###### ie css ###### */
    @media (max-width: 1440px) {
        .main-form-search .form-control {
            border-radius: 0px 10px 10px 0px !important;
        }
    }

    .main-header-center .search-btn {
        border-radius: 10px 0 0 10px !important;
    }

    .dropdown-menu .main-form-search .btn {
        border-radius: 10px 0 0 10px !important;
    }

    .main-header-menu-icon {
        margin-left: 0px;
        margin-right: inherit;
    }

    .btn-icon-text i {
        margin-left: 0.6rem;
        margin-right: inherit;
    }
    .btn-icon-text span {
        float: left;
    }

    .card-item .card-item-icon {
        left: 22px;
        right: inherit !important;
    }

    .transaction-blog:before {
        right: 18px;
        left: inherit !important;
        border-right: 2px dotted #e4e3e8;
        border-left: inherit !important;
    }

    .transaction-details {
        margin-right: 15px;
        margin-left: inherit;
    }

    .product-timeline ul.timeline-1:before {
        border-right: none !important;
        border-left: inherit !important;
    }

    .main-content-title {
        right: 4px;
        left: inherit !important;
    }

    // .table.dataTable td:first-child {
    //     border-right: none ;
    //     border-left: initial ;
    // }

    .header-navheading {
        padding: 15px 15px 15px;
        border-radius: 11px 11px 0 0;
    }

    .main-message-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .side-header {
        transition: padding-right 0.3s ease;
    }

    .main-header-left {
        transition: padding-right 0.3s ease;
    }

    .main-header-center {
        transition: padding-right 0.3s ease;
    }

    .sidebar-icon {
        left: 13px;
        right: inherit !important;
    }

    .responsive-navbar.navbar .navbar-collapse {
        padding-right: 0 !important;
        padding-left: inherit;
    }

    .crypto-buysell-card .select2-container--default .select2-selection--single {
        border-radius: 6px 0 0 6px !important;
    }

    .crypto-wallet .clipboard-icon {
        border-radius: 6px 0 0 6px !important;
    }

    .bg-alert:before {
        right: 0;
        left: inherit !important;
    }

    .input-group .input-group-btn .btn.ripple.btn-primary {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .tabs-style-1 .main-nav-line .nav-link.active {
        border-color: #e0e6ed #e0e6ed #ffffff;
        border-radius: 6px 6px 0px 0px;
    }

    .tabs-style-1 .main-nav-line .nav-link {
        padding: 10px 18px 10px 18px;
    }

    .tabs-style-2 .main-nav-line .nav-link {
        padding: 10px 18px 10px 18px;
        margin: 0 0 0px 5px;
        border-radius: 6px 6px 0 0;
    }

    .tabs-style-3 .nav.panel-tabs li a {
        padding: 10px 18px 10px 18px;
        margin: 0 0 5px 5px;
    }

    .tabs-style-4 .nav.panel-tabs li a {
        padding: 10px 18px 10px 18px;
        margin: 0 0 5px 0;
    }

    .main-mail-compose-compress,
    .main-mail-compose-minimize {
        right: auto;
        left: 4px;
    }

    @media (max-width: 767px) {
        .main-header-notification.show .dropdown-menu::before {
            right: 110px;
            left: inherit !important;
        }

        .main-header-message.show .dropdown-menu::before {
            right: 158px;
            left: inherit !important;
        }
    }

    @media (max-width: 767px) {
        .hor-menu .nav-link .hor-icon {
            margin-left: 9px;
            margin-right: inherit;
        }

        .hor-menu li.active .hor-icon {
            margin-left: 9px;
            margin-right: inherit;
        }
    }

    @media (max-width: 640px) {
        .tabs-style-1 .main-nav-line .nav-link,
        .tabs-style-2 .main-nav-line .nav-link {
            padding: 10px 12px 10px 12px;
        }
    }

    @media (max-width: 480px) {
        .tabs-style-1 .main-nav-line .nav-link,
        .tabs-style-2 .main-nav-line .nav-link {
            padding: 10px 8px 10px 8px;
        }
    }

    .accordion-item:first-of-type {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .accordion-item:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .panel-heading1 a.collapsed:before {
        left: 15px;
        right: inherit !important;
    }

    .panel-heading1 a:before {
        left: 15px;
        right: inherit !important;
    }

    .panel-heading1 a.active {
        border-radius: 6px 6px 0 0;
    }

    .morris-legends {
        padding-right: 0;
        padding-left: inherit;
    }

    .morris-legends li {
        float: right;
    }

    @media (max-width: 992px) and (min-width: 767px) {
        &.horizontalmenu .main-header-menu-icon {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    .intl-tel-input .flag-dropdown .selected-flag {
        border-bottom-right-radius: 6px !important;
        border-top-right-radius: 6px !important;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
        margin: 1px 1px 1px 2px;
        padding: 11px 6px 10px 16px;
    }

    .intl-tel-input .flag-dropdown .selected-flag .down-arrow {
        right: 20px;
        left: initial;
    }

    .intl-tel-input .flag-dropdown .country-list .flag,
    .intl-tel-input .flag-dropdown .country-list .country-name {
        margin-left: 6px;
    }

    .intl-tel-input input {
        border-bottom-right-radius: 6px !important;
        border-top-right-radius: 6px !important;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    #basic-addon2.input-group-text {
        border-left: 1px solid $border !important;
        border-right: inherit !important;
    }

    .signin-or-title::after {
        right: 0;
        left: 0;
    }

    .bootstrap-tagsinput .badge {
        padding: 5px 6px 5px 6px !important;
    }

    @media (min-width: 1200px) {
        .dt-buttons.btn-group {
            right: 172px;
            left: inherit !important;
        }
    }

    .datadelete-table-btn {
        right: 240px;
        left: inherit !important;
    }

    @media screen and (max-width: 992px) {
        .datadelete-table-btn {
            left: 0;
            right: 0;
        }

        .dt-buttons.btn-group {
            left: 0;
            right: 0;
        }
    }

    .richText .richText-toolbar ul li a {
        border-left: 1px solid $border;
        border-right: inherit !important;
    }

    .richText .richText-toolbar ul {
        padding-right: 0;
        padding-left: inherit;
    }

    .mobilelogo img {
        margin-right: -11px;
        margin-left: inherit;
    }

    .product-grid .wishlist-icon {
        left: 10px;
        right: inherit !important;
    }

    #recentorders th:last-child,
    #recentorders td:last-child {
        border-left-width: 0px;
        border-right-width: inherit !important;
    }

    .open-file {
        left: 0;
        right: 0;
    }

    /* ###### file manager ###### */

    .file-image .icons {
        left: 22px;
        right: inherit;
        text-align: left;
    }
    .file-image .icons li a:after,
    .file-image .icons li a:before {
        right: 50%;
        left: 0;
    }
    .file-name {
        left: 0;
        right: 0;
    }
    .file-image-sm .icons {
        left: 7px;
        right: inherit;
        text-align: left;
    }
    .file-image-sm .file-name-sm {
        right: 0;
        left: 0;
    }
    .file-image-md .icons {
        left: 7px;
        right: inherit;
        text-align: left;
    }
    .file-image-md .file-name-md {
        right: 0;
        left: 0;
    }
    .file-image-lg .icons {
        left: 7px;
        right: inherit;
        text-align: left;
    }
    .file-image-lg .file-name-lg {
        right: 0;
        left: 0;
    }

    /* ###### file manager ###### */

    /* ###### Notification List ###### */
    .notify-time {
        right: auto;
        left: 25px;
    }

    .notification:before {
        right: 20%;
        left: inherit !important;
        margin-right: -1px;
        margin-left: inherit;
    }

    .notification .notification-time {
        right: 0;
        left: inherit !important;
        text-align: left;
    }

    .notification .notification-icon {
        right: 15%;
        left: inherit !important;
    }

    .notification .notification-body {
        margin-right: 24%;
        margin-left: 18%;
    }

    .notification .notification-body:before {
        border-left-color: #ffffff;
        border-right-color: transparent;
        right: -20px;
        left: inherit !important;
    }

    @media (max-width: 767px) {
        .notification .notification-body {
            margin-right: 0;
            margin-left: 0;
        }
    }

    /* ###### Notification List ###### */

    .main-signin-wrapper .header-setting-icon {
        right: auto;
        left: 50px;
    }

    @media (max-width: 767px) {
        .main-signin-wrapper .header-setting-icon {
            right: auto;
            left: 20px;
        }
    }

    .main-signin-wrapper.construction .header-setting-icon {
        right: auto;
        left: 50px;
    }

    //*******colors******//

    .main-header-center {
        .form-control {
            border-radius: 0px 10px 10px 0px !important;
        }

        .select2-container--default .select2-selection--single {
            border-left: 0;
            border-right: inherit !important;
        }
    }

    .resp-tabs-list li {
        &.resp-tab-item.active::after,
        &.resp-tab-active::after,
        &.resp-tab-item.active::before,
        &.resp-tab-active::before {
            border-left: 20px solid #25233c;
            border-right: inherit !important;
        }
    }

    .project-status.primary:before {
        border-right: 3px solid $primary;
        border-left: inherit !important;
    }

    //*******colors******//

    .fe-chevron-right:before {
        content: "\e92e";
    }

    .side-menu .nav-item.show i.angle {
        transform: rotate(270deg);
    }

    .side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub-link.sub-with-sub i.angle {
        transform: rotate(270deg);
    }

    .side-menu
        .nav-item.show
        .nav-sub
        .nav-sub-item.show
        .sub-nav-sub
        .nav-sub-item.show
        .nav-sub-link.sub-with-sub
        i.angle {
        transform: rotate(270deg);
    }

    .demo_changer.active {
        transition: all 0.3s ease;
        right: auto !important;
        left: 0;
    }
    .demo_changer {
        right: auto !important;
        left: -270px;
        transition: all 0.3s ease;
    }
}
.rtl div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
}
.rtl div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-left: 0;
}
.rtl div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5em;
    margin-left: inherit;
}
.rtl #marketingvalues th:first-child {
    border-right-width: 1px;
    border-left-width: 0;
}
.rtl table.dataTable > thead .sorting:before,
.rtl table.dataTable > thead .sorting_asc:before,
.rtl table.dataTable > thead .sorting_desc:before,
.rtl table.dataTable > thead .sorting_asc_disabled:before,
.rtl table.dataTable > thead .sorting_desc_disabled:before {
    left: 1em;
    right: inherit;
}
.rtl table.dataTable > thead .sorting:after,
.rtl table.dataTable > thead .sorting_asc:after,
.rtl table.dataTable > thead .sorting_desc:after,
.rtl table.dataTable > thead .sorting_asc_disabled:after,
.rtl table.dataTable > thead .sorting_desc_disabled:after {
    left: 0.5em;
    right: inherit;
}
.rtl #recentorders th:first-child,
.rtl #recentorders td:first-child {
    border-right-width: 0;
    border-left-width: 0;
}

.rtl .select2-container--default .select2-selection--single .select2-selection__arrow b {
    right: 50%;
    margin-right: -4px;
}

.rtl .select2-container--open .select2-dropdown--above {
    direction: rtl;
}

.rtl .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.rtl .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 6px;
}

.rtl .active.carousel-item-start,
.rtl .carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(100%) !important;
}
.rtl .active.carousel-item-end {
    transform: translateX(-100%) !important;
}
.rtl .carousel-item-next:not(.carousel-item-start) {
    transform: translateX(-100%) !important;
    transform: translateX(-100%) !important;
}

.rtl [type="email"],
.rtl [type="number"],
.rtl [type="tel"],
.rtl [type="url"] {
    direction: rtl;
}

.rtl {
    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
        margin-left: 0.5em;
    }
}

.rtl .mdi-arrow-left:before {
    content: "\F054";
}

.rtl .mdi-arrow-right:before {
    content: "\F04D";
}

.rtl .mail-option .chk-all,
.rtl .mail-option .btn-group a.btn {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.rtl {
    .btn-list.radiobtns .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .btn-list.radiobtns .btn-group > .btn:nth-child(n + 3) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.rtl {
    .btn-list.checkboxbtns .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .btn-list.checkboxbtns .btn-group > .btn:nth-child(n + 3) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.rtl .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 9px;
}

.rtl .datepicker-container {
    right: 735px;
    direction: rtl;
}

.rtl .datepicker-panel > ul > li {
    float: right;
}

.rtl .datepicker-top-left::before,
.rtl .datepicker-top-left::after,
.rtl .datepicker-top-right::before,
.rtl .datepicker-top-right::after {
    right: 10px;
    left: initial;
}

.rtl .ion-ios-arrow-back:before {
    content: "\f3d1";
}

.rtl .ion-ios-arrow-forward:before {
    content: "\f3cf";
}

.rtl .pagination-circled .page-item:first-child .page-link,
.rtl .pagination-circled .page-item:last-child .page-link {
    border-radius: 100%;
}
.rtl {
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
    .bs-popover-end > .popover-arrow {
        left: calc(-0.5rem - 1px);
        right: auto !important;
    }
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
    .bs-popover-end > .popover-arrow::before {
        right: -1px;
    }
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
    .bs-popover-end > .popover-arrow::after {
        right: -1px;
    }
    .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
    .bs-tooltip-end .tooltip-arrow {
        left: 0;
        right: auto;
    }

    .toast-header .btn-close {
        margin-left: 3px;
        margin-right: 0.75rem;
    }
    .bootstrap-tagsinput .badge [data-role="remove"] {
        margin-right: 8px;
        margin-left: 0;
    }
    .fa-angle-right:before {
        content: "\f104";
    }
    .fa-angle-left:before {
        content: "\f105";
    }

    #growls-default {
        left: 10px;
        right: inherit;
    }
    #ui_notifIt {
        left: 10px;
        right: initial;
    }
    .notifit_confirm,
    .notifit_prompt {
        right: auto !important;
        left: 20px !important;
    }
    .tree li i {
        margin-left: 10px;
        margin-right: initial;
        float: right;
        margin-top: 7px;
    }
    .sweet-alert .sa-icon.sa-info::after {
        margin-right: -3px;
        margin-left: initial;
    }
    .sweet-alert .sa-icon.sa-info::before {
        right: 50%;
        left: initial;
        margin-right: -2px;
        margin-left: initial;
    }
    div.dataTables_wrapper div.dataTables_length label {
        text-align: right;
    }
    .input-group-text {
        border-left: none;
        border-right: 1px solid $border;
    }
    .datetimepicker table th.prev span::before {
        content: "\f3d1";
    }
    .datetimepicker table th.next span::before {
        content: "\f3cf";
    }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
        content: "\f3cf";
    }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
        content: "\f3d1";
    }
    .datepicker {
        direction: rtl;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
        float: right;
    }
    @media (min-width: 564px) {
        .daterangepicker.ltr {
            direction: rtl;
            text-align: right;
        }
        .daterangepicker.ltr .ranges,
        .daterangepicker.ltr .calendar {
            float: right;
        }
    }
    .fa-chevron-right:before {
        content: "\f053";
    }
    .fa-chevron-left:before {
        content: "\f054";
    }
    .ql-editor {
        text-align: right;
    }
    .richText .richText-toolbar ul li {
        float: right;
    }
    .richText .richText-editor:focus {
        border-right: #4456c3 solid 2px;
        border-left: 0px;
    }
    .btn-group.file-attach {
        .rounded-pill {
          border-radius: 0 50px 50px 0 !important;
        }
        .btn-close {
          &.rounded-pill {
            border-radius: 50px 0 0 50px !important;
          }
        }
      }
}
.rtl .btn-group > .btn-group:not(:first-child) > .btn,
.rtl .btn-group > .btn:nth-child(n + 3),
.rtl .btn-group > :not(.btn-check) + .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.rtl {
    @media (min-width: 1300px) {
        .signpages {
            margin: 5em auto;
        }
    }
}
.rtl .dropdown-menu-end {
    --bs-position: start;
}
.rtl .dropdown-menu-start {
    --bs-position: end;
}
.rtl .revenuechart-container {
    .chart2 {
        margin-right: -14px;
        margin-left: initial;
    }
}
.rtl .apexcharts-legend-series:first-child {
    transform: translate(50px, 0);
}
.rtl .apexcharts-legend-series {
    transform: translate(30px, 0);
}

.rtl #slide-right {
    right: initial;
    left: 20px;
    transform: rotate(180deg);
}
.rtl #slide-left {
    left: initial;
    right: 20px;
    transform: rotate(180deg);
}
.rtl .alert-dismissible i {
    float: right;
    margin-top: 5px;
}
.rtl.transparent-theme .tree ul:before {
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    border-left: 0px;
}
.rtl .tree ul:before {
    right: -10px;
    left: inherit;
    border-right: 2px solid #d8e0f7;
    border-left: inherit;
}
.rtl .tree ul {
    margin-right: 1em;
    margin-left: inherit;
}
.rtl .tree ul li:before {
    right: -10px;
    left: inherit;
}
.rtl .intl-tel-input input {
    padding-right: 47px;
    padding-left: inherit;
}
.rtl.dark-theme .intl-tel-input .flag-dropdown .selected-flag,
.rtl.transparent-theme .intl-tel-input .flag-dropdown .selected-flag {
    border-right: inherit;
    border-left: 1px solid $white-1;
}
@media (min-width: 992px) {
    .rtl.horizontalmenu {
        .hor-menu .menu-nav .nav-sub {
            right: auto;
            left: inherit;
        }
    }
}

.rtl .form-select.is-invalid:not([multiple]):not([size]),
.rtl .form-select.is-invalid:not([multiple])[size="1"],
.rtl .was-validated .form-select:invalid:not([multiple]):not([size]),
.rtl .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-left: 4.125rem;
    padding-right: 0.75rem;
}
.rtl .smartphoto-dismiss {
    left: 22px !important;
    right: inherit !important;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .rtl .search-page .main-nav-line .nav-link + .nav-link {
        margin-right: 30px;
        margin-left: inherit;
    }
}
// @media screen and (max-width: 767px) {
//     .rtl table.table-bordered th:last-child, .rtl table.table-bordered td:last-child {
//         border-left-width: inherit;
//     }
// }
.rtl.transparent-theme {
    #recentorders th:last-child,
    #recentorders td:last-child {
        border-left-width: 0px !important;
        border-right-width: inherit !important;
    }
}

@media (max-width: 763px) {
    .rtl {
        .table-shopping-cart th:last-child,
        .table-shopping-cart td:last-child {
            border-left-width: 2px !important;
        }
    }
}

@media (max-width: 693px) {
    .rtl {
        .ecommerce-orders th:last-child,
        .ecommerce-orders td:last-child {
            border-left-width: 2px !important;
        }
    }
}

@media (max-width: 1318px) {
    .rtl {
        .transcation-crypto table th:last-child,
        .transcation-crypto table td:last-child {
            border-left: 1px solid $border !important;
        }
    }
}
.rtl {
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child,
    table.table-bordered.dataTable td:first-child {
        border-right-width: 1px;
    }
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child,
    table.table-bordered.dataTable td:last-child {
        border-left-width: 0 !important;
    }
}

@media (max-width: 730px) {
    .rtl {
        table.table-bordered.dataTable th:last-child,
        table.table-bordered.dataTable td:last-child {
             border-left-width :1px !important;
        }
    }
}
@media (max-width: 604px) {
    .rtl #form-input-datatable {
        th:last-child,
        td:last-child {
            border-left-width :1px !important;
        }
    }
}
@media screen and (min-width: 605px) and (max-width: 730px) {
    .rtl #form-input-datatable {
        th:last-child,
        td:last-child {
            border-left-width :0px !important;
        }
    }
}

@media screen and (min-width: 731px) and (max-width: 1475px) {
    .rtl #delete-datatable {
        th:last-child,
        td:last-child {
            border-left-width :1px !important;
        }
    }
}
