/* ###### Hover Submenu Style 1 ###### */
.hover-submenu1 {
    .main-content.side-content {
        transition: margin-left 0.3s ease;
    }
      
    .sidemenu-label {
      transition: margin-left 0.45s ease;
    }
      
    .side-menu {
      .nav-link:hover {
        color: $primary;
      }
    
      .nav-item {
        transition-duration: 0.25s;
        margin: 6px 0;
      }
    
      
    
    //   .nav-sub {
    //     display: none;
    //   }
    
      .nav-item.show > .nav-sub {
        display: block;
      }
    
      .nav-sub-link {
        transition: all .25s;
      }
    }
      
    .iconbtn {
      display: none;
    }
      
    .side-menu {
      i.angle {
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 12px;
      }
    
      .nav-item.show i.angle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 12px;
      }
    }
      
    .side-badge{
      position: absolute;
      top: 10px;
      right: 18px;
      border-radius: 50px;
    }
    &.main-sidebar-open.main-sidebar-hide .side-badge{
      position: absolute;
      top: 0px;
      right: 20px;
      border-radius: 50px;
    }
      
    &.main-sidebar-hide .side-badge {
        position: absolute;
        top: 0px;
        right: 20px;
        border-radius: 50px;
        z-index: 4;
    }
      
    .sidemenu-logo {
      padding: 13.4px 21px;
      border-bottom: 1px solid $border;
      justify-content: center;
      border-right: 1px solid $border;
    }
      
    .side-menu .header-brand-img {
      margin-top: 0 !important;
      outline: 0;
    }
      
    .main-footer {
      transition: margin-left 0.3s ease;
    }
      
      
    /* ###### Sidebar  ###### */
      
    @media (max-width: 991.98px) {
      &.main-sidebar-show {
        overflow: inherit;
    
        .main-sidebar {
          display: flex;
          transform: translateX(0);
          visibility: visible;
          transition: left 0.3s ease, width 0.3s ease;
        }
    
        .main-navbar-backdrop {
          opacity: 1;
        }
      }
    }
      
    @media (min-width: 992px) {
      &.main-sidebar-hide {
        .main-sidebar {
          display: none;
        }
    
        .main-sidebar-sticky + .main-content {
          margin-left: 0;
        }
      }
    }
      
    .main-body-sidebar {
      display: block;
    
      .main-content {
        flex: 1;
        padding: 0;
        display: flex;
        flex-direction: column;
      }
    
      .main-content-header {
        height: auto;
        padding: 20px;
        margin-bottom: 0;
      }
    
      .main-content-body {
        padding: 0 20px 20px;
      }
    
      .main-footer {
        margin-top: auto;
      }
    }
      
    @media (min-width: 992px) {
      .main-body-sidebar {
        display: flex;
        flex-direction: row;
      }
    }
      
    @media (min-width: 992px) {
      .main-body-sidebar .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
      
    .main-sidebar {
      width: 240px;
      flex-direction: column;
      flex-shrink: 0;
      border-right: 0;
      transform: translateX(-240px);
      visibility: hidden;
      transition: all 0.4s;
      transition:left 0.3s ease, width 0.3s ease;
    }
      
    @media (prefers-reduced-motion: reduce) {
      .main-sidebar {
        transition: none;
      }
    }
      
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .main-sidebar {
        width: 220px;
      }
    }
      
    @media (max-width: 991.98px) {
      .main-sidebar {
        position: fixed;
        top: 0px !important;
        left: 0;
        bottom: 0;
        z-index: 20;
        background-color: $white;
      }
    }
      
    @media (min-width: 992px) {
      .main-sidebar {
        position: relative;
        display: flex;
        transform: none;
        z-index: 999;
        border-right-width: 1px;
        visibility: visible;
      }
    }
      
    .main-sidebar-sticky {
      position: fixed;
      top: 0px;
      left: 0;
      bottom: 0;
      box-shadow: none;
      background: $white;
      border-right: 1px solid $border;
    }
      
    .option-dots {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: transparent;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
      display: block;
    }
      
    .main-sidebar-body {
      li.nav-item.active .nav-link {
        background-color: $primary;
        position: relative;
        z-index: 1;
        margin: 10px auto;
        text-align: center;
        border-radius: 7px;
        margin-left: 0;
        border: 0px solid transparent;
        border-right: 0px;
        width: inherit;
        padding: 9px 0;
      }
    
    }
    
    @media (min-width: 992px) {
      .main-sidebar-sticky + .main-content {
        margin-left: 220px;
      }
    }
    
    @media (min-width: 1200px) {
      .main-sidebar-sticky + .main-content {
        margin-left: 240px;
      }
    }
    
    .main-sidebar-header {
      flex-shrink: 0;
      height: 64px;
      
      align-items: center;
      
    }
    
    .main-sidebar-loggedin {
      padding: 10px 20px;
      display: flex;
      flex-shrink: 0;
    
      .main-img-user {
        width: 36px;
        height: 36px;
      }
    
      .media-body {
        margin-left: 12px;
    
        h6 {
          font-weight: 700;
          font-size: 14px;
          color: #8f9cc0;
          margin-bottom: 2px;
        }
    
        span {
          display: block;
          color: #a8afc7;
        }
      }
    }
    
    .main-sidebar-body {
      padding: 0;
    }
    
    @media (min-width: 1200px) {
      .main-sidebar-body {
        padding: 12px 0px 12px 0px;
      }
    }
    
    .main-body .main-sidebar-body .nav {
      flex-direction: column;
      padding: 0 15px 0 15px;
      margin: 0 auto;
      justify-content: center;
      margin-top: 0;
    }
    
    .nav-header {
      margin: 0 0 0 15px;
      display: flex;
      position: relative;
    }
      
    .main-sidebar-body .nav-label {
      text-transform: uppercase;
      font-size: 11.49px;
      font-weight: 400;
      letter-spacing: .5px;
      color: #959596;
      padding: 10px 0px 0 0;
    }
    
    .second-sidemenu {
      .nav-label {
        text-transform: uppercase;
        font-size: 11.49px;
        font-weight: 500;
        letter-spacing: .5px;
        color: #77778c;
        padding: 10px 0px 0 0;
      }
    
      .nav-header li {
        list-style-type: none;
      }
    }
    
    .main-sidebar-body .sub-txt {
      font-size: 10.9px;
      padding: 0 0px 0 0;
      color: #9b9ca3;
      margin-bottom: 0;
      position: absolute;
      margin-top: -1px;
    }
    
    .second-sidemenu .sub-txt {
      font-size: 10.9px;
      padding: 0 0px 0 0;
      color: #8d95b1;
      margin-bottom: 10px;
    }
    
    .main-sidebar-body {
      .nav-item {
        position: relative;
        display: block;
    
        + .nav-item {
          margin-top: -1px;
        }
      }
    
      .nav-link {
        display: flex;
        align-items: center;
        padding:4px;
        font-weight: 500;
        font-size: 14px;
        color: #616366;
    
        .sidemenu-icon {
          font-size: 17px;
          line-height: 0;
          margin-right: 10px;
          width: 20px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          border-radius: 50%;
          color: $primary;
          fill:$primary;
          margin-left: 4px;
        }
    
        i.typcn {
          line-height: .9;
    
          &::before {
            width: auto;
          }
        }
    
        &.with-sub {
          position: relative;
        }
      }
    
      .nav-sub {
        list-style: none;
        padding: 0;
        margin-left: 2.5rem;
    
        .nav-sub-link:before {
          font-family: 'typicons';
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          top: 8px;
          font-size: 12px;
          opacity: 0.4;
          margin-right: 22px;
          color: #808191;
        }
      }
    
      .nav-sub-item {
        .nav-sub {
          padding: 0 0 0 12px;
          border-left-width: 1px;
          margin-left: 0;
    
          .nav-sub-link {
            height: auto;
            padding: 7px 0;
          }
        }
    
        // &.active > .nav-sub-link {
        //   color: #c6c6e0;
        // }
    
        &.show .nav-sub {
          display: block;
        }
      }
    
      .nav-sub-link {
        display: flex;
        align-items: center;
        color: #808191;
        font-size: 13px;
        padding:8px 0;
    
        &.with-sub {
          justify-content:flex-start;
    
          &::after {
            content: '\f3d0';
            font-family: 'Ionicons';
            font-size: 12px;
            font-weight: 400;
            display: inline-block;
            position: relative;
            margin-left: auto;
            color: $background;
          }
        }
    
        + .nav-sub-link {
          border-top: 1px dotted $background;
        }
      }
    
      li.active {
    
        .sidemenu-icon {
          font-size: 18px;
          line-height: 0;
          margin-right: 10px;
          width: 20px;
          height: 20px;
          line-height: 35px;
          text-align: center;
          border-radius: 50%;
          color: $white;
          fill:$white;
          margin-left: 10px;
        }
      }
    }
    
    @media (min-width: 992px) {
      .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px;
      }
    }
    
    @media (min-width: 768px) {
      &.main-sidebar-hide {
        .main-sidebar-body li.active:after {
          top: 43px;
        }
        .main-sidebar-header .sidemenu-logo{
          .desktop-logo{
            display: none;
          }
          .icon-logo{
            display: block;
          }
        }
        .main-sidebar .main-sidebar-body {
          padding: 12px 0px 12px 0px;
        }
    
        .main-sidebar-body .nav-link .sidemenu-icon {
          width: 20px;
          height: 25px;
          line-height: 35px;
          text-align: center;
          border-radius: 50%;
          z-index: 1;
          box-shadow: none;
          margin: 0 9px;
        }
    
        &.main-sidebar-open .main-sidebar-body {
          li.active {
            position: relative;
            z-index: 1;
            margin: 0 auto;
            text-align: center;
            border-radius: 0;
            margin-left: 0;
            border: 0px solid transparent;
            border-right: 0px;
            transition: none;
          }
    
          .nav-link .sidemenu-icon {
            font-size: 18px;
            line-height: 0;
            margin-left: 9px;
            width: 20px;
            height: 25px;
            line-height: 35px;
            text-align: center;
            border-radius: 50%;
            box-shadow: none;
          }
    
          li.active .sidemenu-icon {
            font-size: 18px;
            line-height: 0;
            margin-left: 10px;
            width: 20px;
            height: 25px;
            line-height: 35px;
            background: transparent;
            text-align: center;
            border-radius: 50%;
            box-shadow: none;
            color: $white;
          }
        }
      }
    
      &.main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        flex-direction: column;
        padding: 0;
        text-align: inherit;
        margin: 0;
        justify-content: inherit;
      }
    
      &.main-sidebar-hide {
        .main-sidebar-body li {
          &.active {
            .sidemenu-icon {
              background: transparent;
              // margin-left: 10px;
            }
    
            margin: 0;
          }
    
          &.nav-item.active .nav-link {
            width: inherit;
            transition: margin-left 0.2s ease;
            border-radius: 0;
            margin: 0px;
          }
        }
      }
    }
      
      
    /* ###### Sidebar  ###### */
    @media (min-width: 1200px) {
      .side-content {
        margin-left: 240px;
      }
    }
    
    @media (min-width: 992px) {
      .side-header .main-logo {
        color: inherit;
        margin-right: 0;
        font-size: 1.25rem;
        white-space: nowrap;
        font-weight: 600;
        padding: 0;
        transition: .3s opacity;
        line-height: 2.9rem;
        text-align: center;
        text-align: center;
      }
    
      &.main-sidebar-hide {
        .side-header, .main-footer {
          padding-left: 70px;
        }
      }
    
      &.main-body {
        .side-header {
          position: fixed;
          width: 100%;
          z-index: 99 !important;
          border-bottom: 1px solid $border;
          box-shadow:-4px 4px 20px 0px rgba(188, 188, 201, 0.09);
          // left: 0;
          padding-left: 240px;
        }
    
        &.main-sidebar-hide .side-header {
          padding-left: 80px;
        }
      }
    
      &.main-sidebar-hide {
        .sidemenu-logo {
          padding: 12px;
          border-bottom: 1px solid $border;
          justify-content: center;
          height: 65px;
        }
    
        &.main-sidebar-open .sidemenu-logo {
          padding: 12px;
          border-bottom: 1px solid $border;
          justify-content: center;
        }
      }
    
      .main-content.side-content {
        margin-left: 240px;
      }
    
      .main-sidebar.side-menu {
        width: 240px;
      }
    
      .main-footer {
        margin-left: 0;
      }
    }
    
    @media (max-width: 992px) and (min-width: 767px) {
      .main-header-menu-icon {
        margin-left: 7px;
      }
    }
      
    @media (max-width: 767px) {
      .main-header-menu-icon {
        margin-right: 0;
      }
    
      .side-header .icon-logo {
        margin-left: 0;
      }
    
      &.main-sidebar-show .side-menu {
        top: 59px !important;
      }
    }
    
    @media (min-width: 768px) {
      /*main-sidebar-hide*/
      
        &.main-body.main-sidebar-hide.main-sidebar-open .side-menu .nav-link{
            padding: 9px 0px  !important;
          }
    
    
      &.main-sidebar-hide {
        .side-menu {
          left: 0;
          width: 80px;
          display: block;
          visibility: visible;
            transform: none
        }
    
        .main-content.side-content {
          margin-left: 80px !important;
        }
    
        .nav-label, .main-sidebar-body .sub-txt {
          display: none;
        }
      }
    
      .main-sidebar-body .nav-label-1 {
        height: 28px;
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .5px;
        color: $white;
        padding: 0;
        background: rgba(7, 167, 187, 24%);
        width: 28px;
        border-radius: 50%;
        text-align: center;
        margin: 3px 11px;
        line-height: 2.1;
      }
    
      &.main-sidebar-hide {
        .nav-header {
          margin: 0 0 0 15px !important; 
        }
    
        &.main-sidebar-open .nav-header {
          margin: 0 0 0 15px !important;
        }
    
        .sidemenu-label {
          display: none !important;
        font-size: 13px;
        padding-top: 0px;
        margin-top: 0;
        }
    
        .nav-item {
          width: 100%;
        }
    
        .text-icon {
          display: none;
        }
    
        .iconbtn {
          display: block;
        }
    
        .main-sidebar-body i.angle {
          display: none;
        }
    
        .side-menu .nav-link {
          margin: 0 auto;
          padding: 9px 0;
          text-align: center;
          margin-bottom: 0;
          display: block;
        }
    
        .main-sidebar-body .nav-item {
          + .nav-item {
            // margin-top: 5px;
            // margin-bottom: 5px;
            text-align: center;
          }
    
          margin-top: -1px;
          text-align: center;
          margin: 0;
        }
    
        .side-menu {
          .header-brand-img {
            margin-top: 0 !important;
          }
        }
    
        .main-sidebar-body .nav-item.show > .nav-sub {
          display: none !important;
        }
    
        .main-footer {
          margin-left: 0;
        }
    
        .side-menu {
          .main-sidebar-body .nav-link i {
            font-size: 20px;
          }
    
          i.angle {
            font-size: 12px !important;
          }
        }
      }
    
    
      /*main-sidebar-open*/
    
      &.main-sidebar-open.main-sidebar-hide  {
        .side-menu {
          left: 0;
          width: 80px;
          // top: 25px;
        }
        .main-sidebar-header .sidemenu-logo{
          .icon-logo{
            display: block;
          }
          .desktop-logo{
            display: none;
          }
        }
        .nav-label {
          display: none;
        }
    
        .main-sidebar-body {
          .sub-txt {
            display: block;
            font-size: 10.9px;
            padding: 0 0px 0 0;
            color: $white-6;
            margin-bottom: 0;
          }
    
          .nav-label {
            text-transform: uppercase;
            font-size: 11.49px;
            font-weight: 500;
            letter-spacing: .5px;
            color: #959596;
            padding: 10px 0px 0 0;
          }
        }
        .sidemenu-label, .text-icon {
          display: block;
        }
    
        .iconbtn {
          display: none;
        }
    
        .main-sidebar-body i.angle {
          display: none;
        }
    
        .side-menu .nav-link {
          display: block;
          margin-bottom: 0px;
        }
    
        .main-sidebar-body .side-menu .nav-link {
          margin-left: 10px;
        }
    
        .sidemenu-label {
          font-size: 13px;
        }
    
        .main-sidebar-body .nav-item.show > .nav-sub {
          display: block !important;
        }
    
        .side-menu {
          .nav-item.show i.angle {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            position: absolute;
            top: 16px;
            right: 20px;
          }
    
          .main-sidebar-body .nav-link i {
            margin-right: 5px;
            font-size: 18px;
          }
    
          i.angle {
            font-size: 12px;
          }
        }
      }
    }
    
    
    .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
      display: block;
      padding-left: 17px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
      
    // .main-sidebar .nav-sub-item.active .nav-sub-link {
    //   color: $primary !important;
    // }
      
    @media (min-width: 768px) {
      &.main-body.leftmenu .sidemenu-logo{
            position:fixed;
              z-index: 9999;
              width: 240px ;
              background: $white;
            transition: left 0.3s ease, width 0.3s ease;
            top: 0;
        }
        &.main-body.leftmenu  .main-sidebar-body{
          margin-top:65px !important;
          }
          &.main-sidebar-hide .sidemenu-logo{
            position:fixed;
            z-index: 999;
            width: 80px !important;
            background:#25233c;
        }
        &.main-sidebar-hide.main-sidebar-open .sidemenu-logo{
          width: 80px !important;
        }
        &.main-sidebar-hide .main-sidebar-body{
            margin-top:55px !important;
        }
        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body{
            margin-top:65px !important;
        }
    }
      
    .side-menu .nav-sub-item.show > .nav-sub {
      max-height: 100vh;
    }
    
    .side-menu .nav-sub-item > .nav-sub {
      max-height: auto;
    }
    
    .main-sidebar-body .nav-sub-link.with-sub {
      position: relative;
      line-height: 2;
    }
    .main-sidebar-body .nav-sub-item .nav-sub-link.with-sub::after {
      display: none;
    }
    .side-menu .nav-item.show .nav-sub {
      .nav-sub-link.with-sub i.angle {
        transform: rotate(0deg);
      }
    
      .nav-sub-item.show {
        .nav-sub-link.with-sub i.angle {
          transform: rotate(90deg);
        }
    
        .nav-sub {
          .nav-sub-link.with-sub i.angle {
            transform: rotate(0deg);
          }
    
          .nav-sub-item.show .nav-sub-link.with-sub i.angle {
            transform: rotate(90deg);
          }
        }
      }
    }
      
    .main-sidebar-header{
        .icon-logo, .icon-logo-dark, .desktop-logo-dark{
          display: none;
        }
    }
      
      
      
      
    @media (min-width: 768px) {
      &.main-body.leftmenu.main-sidebar-hide .nav-sub {
          position: absolute;
          left: 39px;
          min-width: 180px;
          opacity: 1;
          z-index: 9999;
          visibility: hidden;
          // margin-top: -60px;
          top: 0;
      }
      &.main-sidebar-hide .main-sidebar {
          overflow: visible;
          position: absolute !important;
      }
      &.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
          max-height: inherit !important;
          visibility: visible;
          border-radius: 0 5px 5px 0 !important;
          transition: none !important;
          // padding: 12px 0px 12px 15px;
          opacity: inherit;
          display: block !important;
      }
      &.main-sidebar-open .nav-sub {
          position: absolute;
          background: $white;
          max-height: inherit;
          opacity: 1;
          visibility: visible;
          z-index: 999;
          left: 72px;
          border-radius: 1px !important;
          box-shadow: 8px 8px 17px rgba(0, 0, 0, 0.2);
          transition: none !important;
      }
  
      &.main-body.main-sidebar-hide .main-sidebar-body .nav {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          text-align: inherit;
          margin: 0;
          -webkit-box-pack: inherit;
          -ms-flex-pack: inherit;
          justify-content: inherit;
          padding: 0;
          }
  
      &.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.show > .nav-sub {
          display: block !important;
          top: 0px;
          left: 79px;
      } 
  
      &.main-sidebar-hide.main-sidebar-open .nav-item.show:hover .nav-sub {
          max-height: 100vh !important;
          margin: 0px 0;
          visibility: visible !important;
      }
      &.main-sidebar-hide .main-sidebar-body .nav-item.show > .nav-sub {
          display: none;
      }
      &.main-sidebar-hide .side-menu .nav-sub-link {
          transition: none !important;
      }
      &.main-sidebar-hide .side-menu.ps .ps__rail-y {
          display: none;
      }
      &.main-body.leftmenu.main-sidebar-hide .nav-item .nav-sub .nav-sub-item i.angle {
          display: block;
      }
  
      &.main-body.leftmenu.main-sidebar-hide .nav-item .nav-sub-item.show .nav-sub {
          margin-top: 0;
          left: 0;
          top:auto;
      }
      &.main-sidebar-hide .side-menu .nav-item.show > .nav-sub {
          -webkit-transition: none !important;
          transition: none !important;
      }
      
      &.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
          display: block;
          font-size: 15px;
          font-weight: 500;
          border-bottom: 1px solid $border;
          padding: 8px 0 8px 12px !important;
          text-align: left;
      }
      &.main-sidebar-hide.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub-link {
          padding: 8px 0 8px 12px;
      }
      &.main-sidebar-hide.main-sidebar-open .submenu-style {
        .nav-sub-link.sub-with-sub .sidemenu-label {
          display: block !important;
        }
      }

    }
    
    .side-menu .nav-sub {
      display: none;
      overflow: hidden;
    }
    .side-menu .nav-item.show > .nav-sub {
      display: block;
      transition: all 1.5s;
    }
    
    
    &.main-sidebar-hide .nav-item.submenu-style:hover .nav-sub {
      overflow: inherit !important;
    }
    &.main-sidebar-open .main-sidebar-body .nav-item .nav-sub-item .nav-sub {
      display: none !important;
    }
    &.main-sidebar-open .main-sidebar-body .nav-item .nav-sub-item.show .nav-sub {
      display: block !important;
    }
    &.main-sidebar-open .main-sidebar-body .nav-item .nav-sub-item .nav-sub .nav-sub-item .nav-sub {
      display: none !important;
    }
    &.main-sidebar-open .main-sidebar-body .nav-item .nav-sub-item.show .nav-sub .nav-sub-item.show .nav-sub {
      display: block !important;
    }
    .nav-sub .side-menu-label1 {
      display: none;
    }
    &.main-body.leftmenu.main-sidebar-hide .nav-sub {
      max-height: inherit !important;
      padding-bottom:0 !important;
      margin-top:0 !important;
      margin-bottom: 0 !important;
      padding-top:0 !important;
      height: auto !important;
      overflow: hidden !important;
    }

    .hor-logo {
      display: none;
    }

    //submenu styles
    .main-sidebar-body .nav-sub-link.sub-with-sub {
      position: relative;
      line-height: 2;
      justify-content: flex-start;
    }
    .side-menu .sub-nav-sub {
      display: none;
    }
    .side-menu .nav-item.show .nav-sub .nav-sub-link.sub-with-sub i.angle {
      transform: rotate(0deg);
    }
    .side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub-link.sub-with-sub i.angle {
      transform: rotate(90deg);
    }
    
    .side-menu .nav-item.show .nav-sub .nav-sub-item.show .sub-nav-sub .nav-sub-link.sub-with-sub i.angle {
      transform: rotate(0deg);
    }
    .side-menu
      .nav-item.show
      .nav-sub
      .nav-sub-item.show
      .sub-nav-sub
      .nav-sub-item.show
      .nav-sub-link.sub-with-sub
      i.angle {
      transform: rotate(90deg);
    }
    .main-sidebar-body .nav-sub-item .sub-nav-sub {
      padding: 0 0 0 12px;
      border-left-width: 1px;
      margin-left: 0;
    }

    .main-sidebar-body li.active .nav-sub-link.sub-with-sub .sidemenu-label, .main-sidebar-body li.active .nav-sub-link i {
      color: #808191 !important;
    }
    
    .main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link.active{
      color: $primary !important;
    }
    .main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link{
      color: #808191 !important;
    }
    .main-sidebar-body .nav-sub-item.active .nav-sub-link.active {
      color: var(--primary-bg-color) !important;
    }

}